<template>
  <div class="organizations-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <span>Organizations</span>
    </nav>

    <h2 class="title">Organizations</h2>

    <div class="controls">
      <input v-model="filters.name" placeholder="Filter by name" />
      <input v-model="filters.districtName" placeholder="Filter by district" />
      <input v-model="filters.address" placeholder="Filter by address" />
      <input v-model="filters.city" placeholder="Filter by city" />
      <select v-model="itemsPerPage">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="100">100</option>
        <option value="all">All</option>
      </select>
      <button @click="exportToCSV">Export CSV</button>
      <button @click="exportToExcel">Export Excel</button>
      <button @click="addOrganization" class="create-button">
        Add Organization
      </button>
    </div>

    <table class="organizations-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>District</th>
          <th>Address</th>
          <th>City</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="organization in filteredOrganizations"
          :key="organization.id"
        >
          <td>{{ organization.name }}</td>
          <td>{{ organization.districtName || "" }}</td>
          <td>{{ organization.address }}</td>
          <td>{{ organization.city }}</td>
          <td>
            <button
              @click="viewOrganization(organization.id)"
              class="view-button"
            >
              View
            </button>
            <button
              @click="editOrganization(organization.id)"
              class="edit-button"
            >
              Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config"; // Import the API URL from the config file

// Define the types for the data
interface Organization {
  id: number;
  name: string;
  address: string;
  city: string;
  districtName?: string;
}

export default defineComponent({
  name: "OrganizationsView",
  setup() {
    const organizations = ref<Organization[]>([]);
    const itemsPerPage = ref<string | number>(10);
    const filters = ref({
      name: "",
      districtName: "",
      address: "",
      city: "",
    });
    const router = useRouter();

    const fetchOrganizations = async () => {
      try {
        const response = await fetch(`${API_URL}/organizations`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          organizations.value = await response.json();
        } else {
          console.error("Failed to fetch organizations:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    onMounted(() => {
      fetchOrganizations();
    });

    const filteredOrganizations = computed(() => {
      let filtered = organizations.value;
      if (filters.value.name) {
        filtered = filtered.filter((organization) =>
          organization.name
            .toLowerCase()
            .includes(filters.value.name.toLowerCase())
        );
      }
      if (filters.value.districtName) {
        filtered = filtered.filter((organization) =>
          organization.districtName
            ?.toLowerCase()
            .includes(filters.value.districtName.toLowerCase())
        );
      }
      if (filters.value.address) {
        filtered = filtered.filter((organization) =>
          organization.address
            .toLowerCase()
            .includes(filters.value.address.toLowerCase())
        );
      }
      if (filters.value.city) {
        filtered = filtered.filter((organization) =>
          organization.city
            .toLowerCase()
            .includes(filters.value.city.toLowerCase())
        );
      }
      if (itemsPerPage.value !== "all") {
        const limit = Number(itemsPerPage.value);
        filtered = filtered.slice(0, limit);
      }
      return filtered;
    });

    const exportToCSV = () => {
      // Implement CSV export functionality
      console.log("Exporting to CSV");
    };

    const exportToExcel = () => {
      // Implement Excel export functionality
      console.log("Exporting to Excel");
    };

    const addOrganization = () => {
      router.push({ name: "AddOrganization" });
    };

    const editOrganization = (id: number) => {
      router.push({ name: "EditOrganization", params: { id } });
    };

    const viewOrganization = (id: number) => {
      router.push({ name: "ViewOrganization", params: { id } });
    };

    return {
      organizations,
      itemsPerPage,
      filters,
      filteredOrganizations,
      exportToCSV,
      exportToExcel,
      addOrganization,
      editOrganization,
      viewOrganization,
    };
  },
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.organizations-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.controls input,
.controls select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  flex: 1 1 200px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.create-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
}

.create-button:hover {
  background-color: #218838;
}

.view-button {
  background-color: #17a2b8;
  color: #fff;
}

.view-button:hover {
  background-color: #138496;
}

.edit-button {
  background-color: #ffc107;
  color: #fff;
}

.edit-button:hover {
  background-color: #e0a800;
}
</style>
