<template>
  <div class="edit-student-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/students">Students</router-link> /
      <span>Edit Student</span>
    </nav>

    <h2 class="title">Edit Student</h2>

    <!-- Progress Indicator -->
    <div class="steps-indicator">
      <div
        v-for="step in steps"
        :key="step.number"
        class="step"
        :class="{
          active: currentStep === step.number,
          completed: currentStep > step.number,
        }"
        @click="goToStep(step.number)"
      >
        <div class="step-number">{{ step.number }}</div>
        <div class="step-label">{{ step.label }}</div>
      </div>
    </div>

    <form @submit.prevent="submitForm" class="edit-form">
      <!-- Step 1: Personal Information -->
      <div v-show="currentStep === 1" class="form-step">
        <div class="form-section">
          <h3>Personal Information</h3>
          <div class="form-grid">
            <div class="form-group">
              <label for="firstName">First Name</label>
              <input
                v-model="student.firstName"
                id="firstName"
                :class="{ error: errors.firstName }"
                @input="validateField('firstName')"
              />
              <span class="error-message" v-if="errors.firstName">{{
                errors.firstName
              }}</span>
            </div>
            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input
                v-model="student.lastName"
                id="lastName"
                :class="{ error: errors.lastName }"
                @input="validateField('lastName')"
              />
              <span class="error-message" v-if="errors.lastName">{{
                errors.lastName
              }}</span>
            </div>
            <div class="form-group">
              <label for="birthday">Birthday</label>
              <input v-model="student.birthday" id="birthday" type="date" />
            </div>
            <div class="form-group">
              <label for="gender">Gender</label>
              <select v-model="student.gender" id="gender">
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div class="form-group">
              <label for="weight">Weight (lbs)</label>
              <input
                v-model.number="student.weight"
                id="weight"
                type="number"
                min="0"
              />
            </div>
            <div class="form-group">
              <label for="ethnicity">Ethnicity</label>
              <input v-model="student.ethnicity" id="ethnicity" />
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <input v-model="student.phone" id="phone" type="tel" />
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>Address Information</h3>
          <div class="form-grid">
            <div class="form-group full-width">
              <label for="addressLine1">Street Address</label>
              <input v-model="student.addressLine1" id="addressLine1" />
            </div>
            <div class="form-group full-width">
              <label for="addressLine2">Apt/Suite/Unit</label>
              <input v-model="student.addressLine2" id="addressLine2" />
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input v-model="student.city" id="city" />
            </div>
            <div class="form-group">
              <label for="state">State</label>
              <select v-model="student.state" id="state">
                <option value="">Select State</option>
                <option v-for="state in states" :key="state" :value="state">
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="zip">ZIP Code</label>
              <input
                v-model="student.zip"
                id="zip"
                pattern="[0-9]*"
                maxlength="5"
              />
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>Pickup Location</h3>
          <div class="form-grid">
            <div class="form-group full-width">
              <label for="pickupAddress">Pickup Address</label>
              <input
                v-model="student.pickupAddress"
                id="pickupAddress"
                placeholder="Enter pickup address or use map below"
              />
            </div>
            <div class="form-group">
              <label for="pickupLat">Pickup Latitude</label>
              <input
                v-model="student.pickupLat"
                id="pickupLat"
                type="number"
                step="0.0000001"
                placeholder="Click map or enter latitude"
              />
            </div>
            <div class="form-group">
              <label for="pickupLng">Pickup Longitude</label>
              <input
                v-model="student.pickupLng"
                id="pickupLng"
                type="number"
                step="0.0000001"
                placeholder="Click map or enter longitude"
              />
            </div>
            <div class="form-group full-width">
              <label for="pickupNotes">Pickup Notes</label>
              <textarea
                v-model="student.pickupNotes"
                id="pickupNotes"
                placeholder="Add any notes about the pickup location"
              ></textarea>
            </div>
          </div>
          <div class="map-container">
            <l-map
              ref="map"
              v-model:zoom="mapZoom"
              :center="mapCenter"
              :use-global-leaflet="false"
              @click="handleMapClick"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                layer-type="base"
                name="OpenStreetMap"
              ></l-tile-layer>
              <l-marker
                v-if="hasPickupLocation"
                :lat-lng="[
                  student.pickupLat || mapCenter[0],
                  student.pickupLng || mapCenter[1],
                ]"
              >
                <l-popup>
                  <div class="marker-popup">
                    <h4>Pickup Location</h4>
                    <p>{{ student.pickupAddress || "No address set" }}</p>
                  </div>
                </l-popup>
              </l-marker>
            </l-map>
            <div class="map-controls">
              <button
                type="button"
                class="secondary-button"
                @click="useHomeAddress"
              >
                Use Home Address
              </button>
              <button
                type="button"
                class="secondary-button"
                @click="clearPickupLocation"
              >
                Clear Location
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Step 2: School & District Information -->
      <div v-show="currentStep === 2" class="form-step">
        <div class="form-section">
          <h3>District Selection</h3>
          <div class="district-search">
            <input
              v-model="districtSearch"
              placeholder="Search districts..."
              class="search-input"
            />
            <div class="districts-list" v-if="filteredDistricts.length">
              <div
                v-for="district in filteredDistricts"
                :key="district.id"
                class="district-item"
                :class="{ selected: district.id === student.district?.id }"
                @click="selectDistrict(district)"
              >
                <div class="district-info">
                  <h4>{{ district.name }}</h4>
                  <p>{{ district.address }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>School Selection</h3>
          <div class="school-search">
            <input
              v-model="schoolSearch"
              placeholder="Search schools by name or address..."
              class="search-input"
            />
            <div class="schools-list" v-if="filteredSchools.length">
              <div
                v-for="school in filteredSchools"
                :key="school.id"
                class="school-item"
                :class="{ selected: school.id === student.primarySchool?.id }"
                @click="selectSchool(school)"
              >
                <div class="school-info">
                  <h4>{{ school.name }}</h4>
                  <p>{{ school.address }}</p>
                </div>
                <div
                  class="school-select"
                  v-if="school.id === student.primarySchool?.id"
                >
                  <span class="checkmark">✓</span>
                </div>
              </div>
            </div>
            <p v-else class="no-results">
              No schools found matching your search
            </p>
          </div>
        </div>

        <div class="form-section">
          <h3>Academic Information</h3>
          <div class="form-grid">
            <div class="form-group">
              <label for="grade">Grade</label>
              <input v-model="student.grade" id="grade" />
            </div>
            <div class="form-group">
              <label for="level">Level</label>
              <input v-model="student.level" id="level" />
            </div>
            <div class="form-group">
              <label for="studentType">Student Type</label>
              <input v-model="student.studentType" id="studentType" />
            </div>
          </div>
        </div>
      </div>

      <!-- Step 3: Needs & Comments -->
      <div v-show="currentStep === 3" class="form-step">
        <div class="form-section">
          <h3>Student Needs</h3>
          <div class="needs-container">
            <div class="needs-input">
              <input
                v-model="newNeed"
                @keydown.enter.prevent="addNeed"
                placeholder="Type a need and press Enter"
                class="need-input"
              />
              <button
                type="button"
                @click="addNeed"
                class="add-need-btn"
                :disabled="!newNeed.trim()"
              >
                Add
              </button>
            </div>
            <div class="common-needs">
              <h4>Common Needs:</h4>
              <div class="common-needs-list">
                <button
                  v-for="need in commonNeeds"
                  :key="need"
                  type="button"
                  class="common-need-btn"
                  :class="{ selected: needsArray.includes(need) }"
                  @click="toggleCommonNeed(need)"
                >
                  {{ need }}
                </button>
              </div>
            </div>
            <div class="selected-needs" v-if="needsArray.length">
              <h4>Selected Needs:</h4>
              <div class="needs-tags">
                <span
                  v-for="(need, index) in needsArray"
                  :key="index"
                  class="need-tag"
                >
                  {{ need }}
                  <button
                    type="button"
                    class="remove-need"
                    @click="removeNeed(index)"
                  >
                    ×
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>Additional Comments</h3>
          <div class="form-group">
            <textarea
              v-model="student.comments"
              rows="4"
              placeholder="Add any additional notes or comments about the student..."
            ></textarea>
          </div>
        </div>
      </div>

      <!-- Step 4: Billing -->
      <div v-show="currentStep === 4" class="form-step">
        <div class="form-section">
          <h3>Billing Records</h3>

          <!-- Add New Billing Record Form -->
          <div class="billing-form">
            <h4>Add New Billing Record</h4>
            <div class="form-grid">
              <div class="form-group">
                <label for="billingStartDate">Start Date</label>
                <input
                  v-model="newBillingRecord.startDate"
                  id="billingStartDate"
                  type="date"
                  required
                />
              </div>
              <div class="form-group">
                <label for="billingEndDate">End Date</label>
                <input
                  v-model="newBillingRecord.endDate"
                  id="billingEndDate"
                  type="date"
                />
              </div>
              <div class="form-group">
                <label for="billingRate">Rate ($)</label>
                <input
                  v-model.number="newBillingRecord.rate"
                  id="billingRate"
                  type="number"
                  step="0.01"
                  required
                />
              </div>
              <div class="form-group full-width">
                <label for="billingNotes">Notes</label>
                <textarea
                  v-model="newBillingRecord.notes"
                  id="billingNotes"
                  placeholder="Add any billing notes..."
                ></textarea>
              </div>
            </div>
            <button
              type="button"
              class="primary-button"
              @click="addBillingRecord"
              :disabled="!canAddBilling"
            >
              Add Billing Record
            </button>
          </div>

          <!-- Billing Records List -->
          <div class="billing-records" v-if="billingRecords.length">
            <h4>Current Billing Records</h4>
            <div class="billing-list">
              <div
                v-for="record in billingRecords"
                :key="record.id"
                class="billing-item"
                :class="{ inactive: !record.isActive }"
              >
                <div class="billing-header">
                  <div class="billing-info">
                    <span class="billing-dates">
                      {{ formatDate(record.startDate) }} -
                      {{
                        record.endDate ? formatDate(record.endDate) : "Ongoing"
                      }}
                    </span>
                    <span class="billing-rate">${{ record.rate }}/day</span>
                  </div>
                  <div
                    class="billing-status"
                    :class="{ suspended: record.suspensionDate }"
                  >
                    {{ getBillingStatus(record) }}
                  </div>
                </div>

                <div class="billing-details">
                  <p v-if="record.school">School: {{ record.school.name }}</p>
                  <p v-if="record.district">
                    District: {{ record.district.name }}
                  </p>
                  <p v-if="record.notes" class="billing-notes">
                    {{ record.notes }}
                  </p>
                </div>

                <div class="billing-actions">
                  <button
                    v-if="record.isActive && !record.suspensionDate"
                    @click="suspendBilling(record)"
                    class="warning-button"
                  >
                    Suspend
                  </button>
                  <button
                    v-if="!record.isActive && record.suspensionDate"
                    @click="restartBilling(record)"
                    class="success-button"
                  >
                    Restart
                  </button>
                  <button
                    v-if="record.isActive"
                    @click="endBilling(record)"
                    class="danger-button"
                  >
                    End
                  </button>
                </div>
              </div>
            </div>
          </div>
          <p v-else class="no-records">No billing records found</p>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="form-navigation">
        <button
          type="button"
          @click="previousStep"
          class="secondary-button"
          v-if="currentStep > 1"
        >
          <span class="button-icon">←</span> Previous
        </button>
        <button
          type="button"
          @click="nextStep"
          class="primary-button"
          v-if="currentStep < totalSteps"
        >
          Next <span class="button-icon">→</span>
        </button>
        <button
          type="submit"
          class="submit-button"
          v-if="currentStep === totalSteps"
        >
          Update Student
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { API_URL } from "../config";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
import L from "leaflet";

// Fix Leaflet's default marker path issues
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Interfaces
interface School {
  id: number;
  name: string;
  address: string;
  city?: string;
  state?: string;
  zipcode?: string;
  latitude?: number;
  longitude?: number;
  district?: District;
}

interface District {
  id: number;
  name: string;
  address?: string;
  city?: string;
  notes?: string;
}

enum Gender {
  Male = "male",
  Female = "female",
}

// Form state
const student = ref({
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  birthday: "",
  gender: "" as Gender | "",
  weight: null as number | null,
  ethnicity: "",
  grade: "",
  level: "",
  studentType: "",
  comments: "",
  notes: "",
  needs: "",
  primarySchool: null as School | null,
  district: null as District | null,
  pickupLat: null as number | null,
  pickupLng: null as number | null,
  pickupAddress: null as string | null,
  pickupNotes: null as string | null,
});

// Form validation
const errors = ref({
  firstName: "",
  lastName: "",
});

// Steps configuration
const steps = [
  { number: 1, label: "Personal Info" },
  { number: 2, label: "School & District" },
  { number: 3, label: "Needs & Comments" },
  { number: 4, label: "Billing" },
];

const currentStep = ref(1);
const totalSteps = steps.length;

// Schools handling
const schools = ref<School[]>([]);
const schoolSearch = ref("");

// Needs handling
const needsArray = ref<string[]>([]);
const newNeed = ref("");
const commonNeeds = [
  "Special Education",
  "ESL Support",
  "Speech Therapy",
  "Physical Therapy",
  "Occupational Therapy",
  "Behavioral Support",
  "Academic Support",
  "Transportation",
  "Assistive Technology",
  "Medical Support",
];

// US States
const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

// Districts handling
const districts = ref<District[]>([]);
const districtSearch = ref("");

// Billing handling
interface BillingRecord {
  id?: number;
  startDate: string;
  endDate?: string;
  suspensionDate?: string;
  restartDate?: string;
  rate: number;
  notes?: string;
  isActive: boolean;
  suspensionReason?: string;
  terminationReason?: string;
  school: School;
  district: District;
}

const billingRecords = ref<BillingRecord[]>([]);
const newBillingRecord = ref<Partial<BillingRecord>>({
  startDate: new Date().toISOString().split("T")[0],
  rate: 0,
  isActive: true,
});

const route = useRoute();
const router = useRouter();

// Map reference
const map = ref<any>(null);

// Computed properties
const filteredSchools = computed(() => {
  const searchTerm = schoolSearch.value.toLowerCase();
  return schools.value.filter(
    (school) =>
      school.name.toLowerCase().includes(searchTerm) ||
      school.address.toLowerCase().includes(searchTerm)
  );
});

const filteredDistricts = computed(() => {
  const searchTerm = districtSearch.value.toLowerCase();
  return districts.value.filter(
    (district) =>
      district.name.toLowerCase().includes(searchTerm) ||
      district.address?.toLowerCase().includes(searchTerm)
  );
});

const mapZoom = ref(12);
const mapCenter = ref<[number, number]>([41.7658, -72.6734]); // Default center (Hartford, CT)

const hasPickupLocation = computed(() => {
  return !!(student.value.pickupLat && student.value.pickupLng);
});

// Update map center when student address is loaded
const updateMapCenter = () => {
  if (student.value.lat && student.value.lng) {
    mapCenter.value = [student.value.lat, student.value.lng];
    if (map.value) {
      // Access the Leaflet map instance
      const leafletMap = map.value.leafletObject;
      if (leafletMap) {
        leafletMap.setView(mapCenter.value, mapZoom.value);
      }
    }
  }
};

// Methods
const validateField = (field: string) => {
  errors.value[field] = "";
  if (field === "firstName" && !student.value.firstName.trim()) {
    errors.value.firstName = "First name is required";
  }
  if (field === "lastName" && !student.value.lastName.trim()) {
    errors.value.lastName = "Last name is required";
  }
};

const validateStep = () => {
  if (currentStep.value === 1) {
    validateField("firstName");
    validateField("lastName");
    return !errors.value.firstName && !errors.value.lastName;
  }
  return true;
};

const goToStep = (step: number) => {
  if (step < currentStep.value || validateStep()) {
    currentStep.value = step;
  }
};

const nextStep = () => {
  if (validateStep() && currentStep.value < totalSteps) {
    currentStep.value++;
  }
};

const previousStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};

const selectSchool = (school: School) => {
  student.value.primarySchool = school;
};

const addNeed = () => {
  const trimmedNeed = newNeed.value.trim();
  if (trimmedNeed && !needsArray.value.includes(trimmedNeed)) {
    needsArray.value.push(trimmedNeed);
  }
  newNeed.value = "";
};

const toggleCommonNeed = (need: string) => {
  const index = needsArray.value.indexOf(need);
  if (index === -1) {
    needsArray.value.push(need);
  } else {
    needsArray.value.splice(index, 1);
  }
};

const removeNeed = (index: number) => {
  needsArray.value.splice(index, 1);
};

const selectDistrict = (district: District) => {
  student.value.district = district;
};

const fetchSchools = async () => {
  try {
    const response = await fetch(`${API_URL}/organizations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      schools.value = await response.json();
    }
  } catch (error) {
    console.error("Error fetching schools:", error);
  }
};

const fetchDistricts = async () => {
  try {
    const response = await fetch(`${API_URL}/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      districts.value = await response.json();
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
  }
};

const fetchStudent = async () => {
  try {
    const response = await fetch(`${API_URL}/students/${route.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      student.value = { ...data };
      if (data.primarySchool) {
        student.value.primarySchool = data.primarySchool;
      }
      needsArray.value = data.needs
        ? data.needs.split(",").map((need: string) => need.trim())
        : [];
      // Update map center after loading student data
      updateMapCenter();
    }
  } catch (error) {
    console.error("Error fetching student:", error);
  }
};

const fetchBillingRecords = async () => {
  try {
    const response = await fetch(
      `${API_URL}/billing/student/${route.params.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.ok) {
      billingRecords.value = await response.json();
    }
  } catch (error) {
    console.error("Error fetching billing records:", error);
  }
};

const canAddBilling = computed(() => {
  return (
    newBillingRecord.value.startDate &&
    newBillingRecord.value.rate &&
    student.value.primarySchool &&
    student.value.district
  );
});

const addBillingRecord = async () => {
  try {
    const response = await fetch(`${API_URL}/billing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        ...newBillingRecord.value,
        studentId: route.params.id,
        schoolId: student.value.primarySchool?.id,
        districtId: student.value.district?.id,
      }),
    });

    if (response.ok) {
      await fetchBillingRecords();
      // Reset form
      newBillingRecord.value = {
        startDate: new Date().toISOString().split("T")[0],
        rate: 0,
        isActive: true,
      };
    }
  } catch (error) {
    console.error("Error adding billing record:", error);
  }
};

const suspendBilling = async (record: BillingRecord) => {
  try {
    const suspensionDate = new Date().toISOString().split("T")[0];
    const suspensionReason = prompt("Please enter reason for suspension:");

    if (suspensionReason) {
      const response = await fetch(`${API_URL}/billing/${record.id}/suspend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ suspensionDate, suspensionReason }),
      });

      if (response.ok) {
        await fetchBillingRecords();
      }
    }
  } catch (error) {
    console.error("Error suspending billing:", error);
  }
};

const restartBilling = async (record: BillingRecord) => {
  try {
    const restartDate = new Date().toISOString().split("T")[0];

    const response = await fetch(`${API_URL}/billing/${record.id}/restart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ restartDate }),
    });

    if (response.ok) {
      await fetchBillingRecords();
    }
  } catch (error) {
    console.error("Error restarting billing:", error);
  }
};

const endBilling = async (record: BillingRecord) => {
  try {
    const endDate = new Date().toISOString().split("T")[0];
    const terminationReason = prompt("Please enter reason for termination:");

    if (terminationReason) {
      const response = await fetch(`${API_URL}/billing/${record.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          endDate,
          terminationReason,
          isActive: false,
        }),
      });

      if (response.ok) {
        await fetchBillingRecords();
      }
    }
  } catch (error) {
    console.error("Error ending billing:", error);
  }
};

const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString();
};

const getBillingStatus = (record: BillingRecord) => {
  if (!record.isActive) {
    return record.suspensionDate ? "SUSPENDED" : "TERMINATED";
  }
  return "ACTIVE";
};

const submitForm = async () => {
  if (!validateStep()) return;

  try {
    student.value.needs = needsArray.value.join(", ");
    const response = await fetch(`${API_URL}/students/${route.params.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(student.value),
    });

    if (response.ok) {
      router.push("/students");
    } else {
      const error = await response.json();
      alert(`Error: ${error.message}`);
    }
  } catch (error) {
    console.error("Error updating student:", error);
    alert("An error occurred while updating the student.");
  }
};

const handleMapClick = (event: { latlng: { lat: number; lng: number } }) => {
  const { lat, lng } = event.latlng;
  student.value.pickupLat = lat;
  student.value.pickupLng = lng;

  // Reverse geocode to get address
  reverseGeocode(lat, lng);

  // Update the marker position
  if (map.value) {
    const leafletMap = map.value.leafletObject;
    if (leafletMap) {
      leafletMap.setView([lat, lng], mapZoom.value);
    }
  }
};

const useHomeAddress = async () => {
  if (student.value.addressLine1) {
    const address = `${student.value.addressLine1}, ${student.value.city}, ${student.value.state} ${student.value.zip}`;
    const coords = await geocode(address);
    if (coords) {
      student.value.pickupLat = coords.lat;
      student.value.pickupLng = coords.lng;
      student.value.pickupAddress = student.value.addressLine1;
      if (map.value) {
        const leafletMap = map.value.leafletObject;
        if (leafletMap) {
          leafletMap.setView([coords.lat, coords.lng], mapZoom.value);
        }
      }
    }
  }
};

const clearPickupLocation = () => {
  student.value.pickupLat = undefined;
  student.value.pickupLng = undefined;
  student.value.pickupAddress = "";
  student.value.pickupNotes = "";

  // Reset map view to home address if available
  if (student.value.lat && student.value.lng) {
    if (map.value) {
      const leafletMap = map.value.leafletObject;
      if (leafletMap) {
        leafletMap.setView(
          [student.value.lat, student.value.lng],
          mapZoom.value
        );
      }
    }
  }
};

const geocode = async (address: string) => {
  try {
    const response = await fetch(
      `${API_URL}/geocode?address=${encodeURIComponent(address)}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.error("Geocoding error:", error);
  }
  return null;
};

const reverseGeocode = async (lat: number, lng: number) => {
  try {
    const response = await fetch(
      `${API_URL}/geocode/reverse?lat=${lat}&lng=${lng}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.ok) {
      const result = await response.json();
      student.value.pickupAddress = result.address;
    }
  } catch (error) {
    console.error("Reverse geocoding error:", error);
  }
};

onMounted(() => {
  fetchSchools();
  fetchDistricts();
  if (route.params.id) {
    fetchStudent();
    fetchBillingRecords();
  }
});
</script>

<style scoped>
.edit-student-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.steps-indicator {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  position: relative;
}

.steps-indicator::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #e9ecef;
  z-index: 1;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

.step.active .step-number {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.step.completed .step-number {
  background-color: #28a745;
  border-color: #28a745;
  color: white;
}

.step-label {
  font-size: 14px;
  color: #6c757d;
}

.form-section {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.form-group label {
  font-weight: 500;
  color: #495057;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.error {
  border-color: #dc3545 !important;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
}

.school-search {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 10px;
}

.schools-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.school-item {
  padding: 12px;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.school-item:hover {
  background-color: #f8f9fa;
}

.school-item.selected {
  background-color: #e8f4ff;
}

.school-info h4 {
  margin: 0 0 4px 0;
  color: #212529;
}

.school-info p {
  margin: 0;
  color: #6c757d;
  font-size: 14px;
}

.needs-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.needs-input {
  display: flex;
  gap: 10px;
}

.need-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.add-need-btn {
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-need-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.common-needs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.common-need-btn {
  padding: 6px 12px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.common-need-btn.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.needs-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.need-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  background-color: #e9ecef;
  border-radius: 16px;
  font-size: 14px;
}

.remove-need {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  line-height: 1;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.primary-button,
.secondary-button,
.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.primary-button {
  background-color: #007bff;
  color: white;
}

.secondary-button {
  background-color: #6c757d;
  color: white;
}

.submit-button {
  background-color: #28a745;
  color: white;
}

.button-icon {
  font-size: 18px;
}

.no-results {
  text-align: center;
  color: #6c757d;
  padding: 20px;
}

.district-search {
  margin-bottom: 20px;
}

.districts-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.district-item {
  padding: 12px;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
}

.district-item:hover {
  background-color: #f8f9fa;
}

.district-item.selected {
  background-color: #e8f4ff;
}

.district-info h4 {
  margin: 0 0 4px 0;
  color: #212529;
}

.district-info p {
  margin: 0;
  color: #6c757d;
  font-size: 14px;
}

.map-container {
  height: 400px;
  width: 100%;
  margin: 1rem 0;
  border: 1px solid #ced4da;
  border-radius: 4px;
  overflow: hidden;
}

.map-container :deep(.leaflet-container) {
  height: 100%;
  width: 100%;
}

.marker-popup {
  padding: 5px;
}

.marker-popup h4 {
  margin: 5px 0;
  font-size: 14px;
}

.marker-popup p {
  margin: 5px 0;
  color: #666;
  font-size: 12px;
}

.map-controls {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  background: #f8f9fa;
  border-top: 1px solid #ced4da;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.billing-form {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.billing-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.billing-item {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 1rem;
}

.billing-item.inactive {
  background-color: #f8f9fa;
  border-color: #dee2e6;
}

.billing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.billing-info {
  display: flex;
  gap: 1rem;
}

.billing-rate {
  font-weight: bold;
  color: #28a745;
}

.billing-status {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: #28a745;
  color: white;
}

.billing-status.suspended {
  background-color: #ffc107;
}

.billing-details {
  margin: 1rem 0;
  font-size: 0.9rem;
}

.billing-notes {
  font-style: italic;
  color: #6c757d;
}

.billing-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.warning-button {
  background-color: #ffc107;
}

.danger-button {
  background-color: #dc3545;
}

.success-button {
  background-color: #28a745;
}

.no-records {
  text-align: center;
  color: #6c757d;
  padding: 2rem;
}
</style>
