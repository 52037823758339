<template>
  <div class="district-closure-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/districts">Districts</router-link> /
      <span>Add District-Wide Schedule Change</span>
    </nav>

    <h2 class="title">Add District-Wide Schedule Change</h2>

    <div class="form-container">
      <div class="form-section">
        <h3>Select District</h3>
        <div class="district-selector">
          <div class="search-container">
            <input
              v-model="districtSearch"
              placeholder="Search districts..."
              @input="filterDistricts"
              class="search-input"
            />
            <div v-if="isLoadingDistricts" class="loading-indicator">
              Loading...
            </div>
          </div>

          <div v-if="filteredDistricts.length > 0" class="district-list">
            <div
              v-for="district in filteredDistricts"
              :key="district.id"
              class="district-item"
              :class="{
                selected:
                  selectedDistrict && selectedDistrict.id === district.id,
              }"
              @click="selectDistrict(district)"
            >
              <font-awesome-icon icon="building" class="district-icon" />
              {{ district.name }} ({{
                district.organizations ? district.organizations.length : 0
              }}
              schools)
            </div>
          </div>
          <div v-else-if="!isLoadingDistricts" class="no-results">
            No districts found
          </div>
        </div>
      </div>

      <div class="form-section" v-if="selectedDistrict">
        <h3>Closure Details</h3>

        <div class="form-group">
          <label for="title">Title*</label>
          <input
            id="title"
            v-model="closureDetails.title"
            placeholder="e.g., Snow Day, Holiday, Teacher In-Service"
            required
          />
          <div v-if="errors.title" class="error-message">
            {{ errors.title }}
          </div>
        </div>

        <div class="form-group">
          <label for="type">Type*</label>
          <select
            id="type"
            v-model="closureDetails.type"
            required
            @change="handleTypeChange"
          >
            <option value="">Select a type</option>
            <option value="closed">Closed (Full Day)</option>
            <option value="snow_day">Snow Day</option>
            <option value="holiday">Holiday</option>
            <option value="sick_day">Sick Day</option>
            <option value="late_start">Late Start</option>
            <option value="early_dismissal">Early Dismissal</option>
          </select>
          <div v-if="errors.type" class="error-message">{{ errors.type }}</div>
        </div>

        <!-- Event type visual indicator -->
        <div v-if="closureDetails.type" class="event-type-indicator">
          <div class="event-icon">
            <font-awesome-icon
              :icon="getEventTypeIcon(closureDetails.type)"
              :class="'icon-' + closureDetails.type"
            />
          </div>
          <div class="event-description">
            <strong>{{ getEventTypeLabel(closureDetails.type) }}</strong>
            <p>{{ getEventTypeDescription(closureDetails.type) }}</p>
          </div>
        </div>

        <!-- Time fields for late start and early dismissal -->
        <div v-if="showTimeFields" class="form-row">
          <div class="form-group" v-if="closureDetails.type === 'late_start'">
            <label for="startTime">Start Time*</label>
            <input
              id="startTime"
              type="time"
              v-model="closureDetails.startTime"
              required
            />
            <div v-if="errors.startTime" class="error-message">
              {{ errors.startTime }}
            </div>
            <div class="field-hint">
              The time when school will start (later than usual)
            </div>
          </div>

          <div
            class="form-group"
            v-if="closureDetails.type === 'early_dismissal'"
          >
            <label for="endTime">Dismissal Time*</label>
            <input
              id="endTime"
              type="time"
              v-model="closureDetails.endTime"
              required
            />
            <div v-if="errors.endTime" class="error-message">
              {{ errors.endTime }}
            </div>
            <div class="field-hint">
              The time when school will end (earlier than usual)
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="description">Description</label>
          <textarea
            id="description"
            v-model="closureDetails.description"
            placeholder="Additional details about this closure"
            rows="3"
          ></textarea>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label for="startDate">Start Date*</label>
            <input
              id="startDate"
              type="date"
              v-model="closureDetails.startDate"
              required
            />
            <div v-if="errors.startDate" class="error-message">
              {{ errors.startDate }}
            </div>
          </div>

          <div class="form-group">
            <label for="endDate">End Date</label>
            <input
              id="endDate"
              type="date"
              v-model="closureDetails.endDate"
              :min="closureDetails.startDate"
            />
            <div v-if="errors.endDate" class="error-message">
              {{ errors.endDate }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="checkbox-group">
            <input
              id="affectsAttendance"
              type="checkbox"
              v-model="closureDetails.affectsAttendance"
            />
            <label for="affectsAttendance">Affects Attendance</label>
          </div>
        </div>

        <div class="form-group">
          <div class="checkbox-group">
            <input
              id="skipExistingClosures"
              type="checkbox"
              v-model="options.skipExistingClosures"
            />
            <label for="skipExistingClosures"
              >Skip schools that already have closures on these dates</label
            >
          </div>
        </div>
      </div>

      <div class="form-section" v-if="selectedDistrict">
        <h3>Apply To</h3>

        <div class="radio-group">
          <div class="radio-option">
            <input
              id="allCalendars"
              type="radio"
              v-model="options.applyTo"
              value="all"
              name="applyTo"
            />
            <label for="allCalendars">All active school calendars</label>
          </div>

          <div class="radio-option">
            <input
              id="specificYear"
              type="radio"
              v-model="options.applyTo"
              value="specificYear"
              name="applyTo"
            />
            <label for="specificYear"
              >Only calendars for specific academic year</label
            >
          </div>
        </div>

        <div class="form-group" v-if="options.applyTo === 'specificYear'">
          <label for="academicYear">Academic Year</label>
          <select id="academicYear" v-model="options.academicYear">
            <option value="">Select academic year</option>
            <option v-for="year in academicYears" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
          <div v-if="errors.academicYear" class="error-message">
            {{ errors.academicYear }}
          </div>
        </div>
      </div>

      <div class="form-actions">
        <button @click="goBack" class="btn-cancel">Cancel</button>
        <button
          @click="submitForm"
          class="btn-submit"
          :disabled="isSubmitting || !selectedDistrict"
        >
          {{
            isSubmitting
              ? "Creating..."
              : "Create District-Wide Schedule Change"
          }}
        </button>
      </div>
    </div>

    <div v-if="successMessage" class="success-message">
      {{ successMessage }}
    </div>

    <div v-if="errors.submit" class="error-message-global">
      {{ errors.submit }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config";

interface District {
  id: number;
  name: string;
  organizations?: any[];
}

interface ClosureDetails {
  title: string;
  type: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  affectsAttendance: boolean;
}

interface Options {
  skipExistingClosures: boolean;
  applyTo: "all" | "specificYear";
  academicYear?: string;
}

interface FormErrors {
  district?: string;
  title?: string;
  type?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  academicYear?: string;
  submit?: string;
}

const router = useRouter();

// State variables
const districts = ref<District[]>([]);
const filteredDistricts = ref<District[]>([]);
const selectedDistrict = ref<District | null>(null);
const districtSearch = ref("");
const isLoadingDistricts = ref(true);
const isSubmitting = ref(false);
const successMessage = ref("");
const errors = ref<FormErrors>({});

// Computed property to determine if time fields should be shown
const showTimeFields = computed(() => {
  return (
    closureDetails.value.type === "late_start" ||
    closureDetails.value.type === "early_dismissal"
  );
});

// Current year and past/future years for academic year selection
const currentYear = new Date().getFullYear();
const academicYears = [
  `${currentYear - 1}-${currentYear}`,
  `${currentYear}-${currentYear + 1}`,
  `${currentYear + 1}-${currentYear + 2}`,
];

// Form data
const closureDetails = ref<ClosureDetails>({
  title: "",
  type: "",
  description: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  affectsAttendance: true,
});

const options = ref<Options>({
  skipExistingClosures: true,
  applyTo: "all", // 'all' or 'specificYear'
  academicYear: "",
});

// Helper functions for event type visualization
const getEventTypeIcon = (type: string) => {
  switch (type) {
    case "closed":
      return "ban";
    case "snow_day":
      return "snowflake";
    case "holiday":
      return "calendar-alt";
    case "sick_day":
      return "user-check";
    case "late_start":
      return "hourglass-half";
    case "early_dismissal":
      return "hourglass-end";
    default:
      return "calendar-day";
  }
};

const getEventTypeLabel = (type: string) => {
  switch (type) {
    case "closed":
      return "School Closed";
    case "snow_day":
      return "Snow Day";
    case "holiday":
      return "Holiday";
    case "sick_day":
      return "Sick Day";
    case "late_start":
      return "Late Start";
    case "early_dismissal":
      return "Early Dismissal";
    default:
      return "Event";
  }
};

const getEventTypeDescription = (type: string) => {
  switch (type) {
    case "closed":
      return "School will be closed for the entire day.";
    case "snow_day":
      return "School will be closed due to inclement weather.";
    case "holiday":
      return "School will be closed for a holiday.";
    case "sick_day":
      return "School will be closed due to illness.";
    case "late_start":
      return "School will start later than usual. Please specify the start time.";
    case "early_dismissal":
      return "School will end earlier than usual. Please specify the dismissal time.";
    default:
      return "Please provide details for this event.";
  }
};

// Handle type change to set default title if empty
const handleTypeChange = () => {
  // Clear time fields when type changes
  closureDetails.value.startTime = "";
  closureDetails.value.endTime = "";

  // Set default title based on type if title is empty
  if (!closureDetails.value.title.trim()) {
    switch (closureDetails.value.type) {
      case "closed":
        closureDetails.value.title = "School Closed";
        break;
      case "snow_day":
        closureDetails.value.title = "Snow Day";
        break;
      case "holiday":
        closureDetails.value.title = "Holiday";
        break;
      case "sick_day":
        closureDetails.value.title = "Sick Day";
        break;
      case "late_start":
        closureDetails.value.title = "Late Start";
        break;
      case "early_dismissal":
        closureDetails.value.title = "Early Dismissal";
        break;
    }
  }
};

// Fetch districts on component mount
onMounted(async () => {
  await fetchDistricts();
});

// Fetch districts from API
const fetchDistricts = async () => {
  isLoadingDistricts.value = true;
  try {
    const response = await fetch(`${API_URL}/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      districts.value = data;
      filteredDistricts.value = data;
    } else {
      console.error("Failed to fetch districts:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
  } finally {
    isLoadingDistricts.value = false;
  }
};

// Filter districts based on search input
const filterDistricts = () => {
  if (!districtSearch.value.trim()) {
    filteredDistricts.value = districts.value;
    return;
  }

  const searchTerm = districtSearch.value.toLowerCase();
  filteredDistricts.value = districts.value.filter((district) =>
    district.name.toLowerCase().includes(searchTerm)
  );
};

// Select a district
const selectDistrict = (district: District) => {
  selectedDistrict.value = district;
};

// Form validation
const validateForm = () => {
  const newErrors: FormErrors = {};

  if (!selectedDistrict.value) {
    newErrors.district = "Please select a district";
  }

  if (!closureDetails.value.title.trim()) {
    newErrors.title = "Title is required";
  }

  if (!closureDetails.value.type) {
    newErrors.type = "Type is required";
  }

  if (!closureDetails.value.startDate) {
    newErrors.startDate = "Start date is required";
  }

  if (
    closureDetails.value.endDate &&
    new Date(closureDetails.value.endDate) <
      new Date(closureDetails.value.startDate)
  ) {
    newErrors.endDate = "End date cannot be before start date";
  }

  // Validate time fields for late start and early dismissal
  if (
    closureDetails.value.type === "late_start" &&
    !closureDetails.value.startTime
  ) {
    newErrors.startTime = "Start time is required for late start";
  }

  if (
    closureDetails.value.type === "early_dismissal" &&
    !closureDetails.value.endTime
  ) {
    newErrors.endTime = "Dismissal time is required for early dismissal";
  }

  if (options.value.applyTo === "specificYear" && !options.value.academicYear) {
    newErrors.academicYear = "Please select an academic year";
  }

  errors.value = newErrors;
  return Object.keys(newErrors).length === 0;
};

// Submit the form
const submitForm = async () => {
  if (!validateForm()) {
    return;
  }

  isSubmitting.value = true;
  successMessage.value = "";

  try {
    // Prepare request payload with all necessary fields
    const payload = {
      closureDetails: {
        ...closureDetails.value,
        // If end date is not provided, use start date
        endDate: closureDetails.value.endDate || closureDetails.value.startDate,
      },
      options: {
        skipExistingClosures: options.value.skipExistingClosures,
      } as any,
    };

    // Add academic year if specific year is selected
    if (
      options.value.applyTo === "specificYear" &&
      options.value.academicYear
    ) {
      payload.options.academicYear = options.value.academicYear;
    }

    if (selectedDistrict.value) {
      const response = await fetch(
        `${API_URL}/districts/${selectedDistrict.value.id}/closures`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        successMessage.value = `Successfully created closures for ${result.affectedCalendars} school calendars.`;

        // Reset form after successful submission
        closureDetails.value = {
          title: "",
          type: "",
          description: "",
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
          affectsAttendance: true,
        };
        selectedDistrict.value = null;
        districtSearch.value = "";
      } else {
        const errorData = await response.json();
        errors.value = {
          submit: errorData.message || "Failed to create district-wide closure",
        };
      }
    }
  } catch (error) {
    console.error("Error creating district-wide closure:", error);
    errors.value = { submit: "An unexpected error occurred" };
  } finally {
    isSubmitting.value = false;
  }
};

// Navigation
const goBack = () => {
  router.push("/districts");
};
</script>

<style scoped>
.district-closure-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.breadcrumb {
  margin-bottom: 20px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form-section {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
}

.form-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.district-selector {
  margin-bottom: 20px;
}

.search-container {
  position: relative;
  margin-bottom: 10px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.loading-indicator {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #6c757d;
}

.district-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.district-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
}

.district-icon {
  margin-right: 10px;
  color: #6c757d;
}

.district-item:hover {
  background-color: #f0f0f0;
}

.district-item.selected {
  background-color: #e3f2fd;
  font-weight: bold;
}

.no-results {
  padding: 10px;
  color: #6c757d;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

.checkbox-group,
.radio-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-group input[type="checkbox"],
.radio-option input[type="radio"] {
  width: auto;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.btn-cancel,
.btn-submit {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  flex: 1;
}

.btn-cancel {
  background-color: #f8f9fa;
  color: #212529;
  border: 1px solid #ddd;
}

.btn-submit {
  background-color: #007bff;
  color: white;
}

.btn-submit:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.error-message-global {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 4px;
  text-align: center;
}

.success-message {
  margin-top: 20px;
  padding: 15px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 4px;
  text-align: center;
}

.field-hint {
  font-size: 12px;
  color: #6c757d;
  margin-top: 5px;
}

.event-type-indicator {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f0f8ff;
  border-radius: 8px;
  margin-bottom: 20px;
  border-left: 4px solid #007bff;
}

.event-icon {
  font-size: 24px;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e6f2ff;
}

.event-description {
  flex: 1;
}

.event-description p {
  margin: 5px 0 0 0;
  color: #6c757d;
}

/* Icon colors for different event types */
.icon-closed {
  color: #dc3545;
}

.icon-snow_day {
  color: #17a2b8;
}

.icon-holiday {
  color: #28a745;
}

.icon-sick_day {
  color: #ffc107;
}

.icon-late_start {
  color: #fd7e14;
}

.icon-early_dismissal {
  color: #6f42c1;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .form-actions {
    flex-direction: column;
  }
}
</style>
