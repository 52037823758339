<template>
  <div class="view-event-container">
    <div class="header-actions">
      <button class="btn-back" @click="goBack">
        <font-awesome-icon icon="arrow-left" /> Back to Events
      </button>
      <div class="event-actions">
        <button class="btn-edit" @click="editEvent">
          <font-awesome-icon icon="edit" /> Edit Event
        </button>
        <button class="btn-delete" @click="confirmDelete">
          <font-awesome-icon icon="trash" /> Delete Event
        </button>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <p>Loading event data...</p>
    </div>

    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-else class="event-content">
      <div class="event-header">
        <h1>{{ event.title }}</h1>
        <span class="event-type" :class="event.type">{{
          formatEventType(event.type)
        }}</span>
      </div>

      <div class="event-details">
        <div class="detail-section">
          <h2>Basic Information</h2>

          <div class="detail-row">
            <div class="detail-item">
              <h3>Calendar</h3>
              <p>{{ calendarName }}</p>
            </div>

            <div class="detail-item">
              <h3>Type</h3>
              <p>{{ formatEventType(event.type) }}</p>
            </div>
          </div>

          <div class="detail-item full-width" v-if="event.description">
            <h3>Description</h3>
            <p>{{ event.description }}</p>
          </div>
        </div>

        <div class="detail-section">
          <h2>Date and Time</h2>

          <div class="detail-row">
            <div class="detail-item">
              <h3>Start Date</h3>
              <p>{{ formatDate(event.startDate) }}</p>
            </div>

            <div class="detail-item">
              <h3>End Date</h3>
              <p>
                {{
                  event.endDate
                    ? formatDate(event.endDate)
                    : "Same as start date"
                }}
              </p>
            </div>
          </div>

          <div class="detail-row" v-if="event.startTime || event.endTime">
            <div class="detail-item">
              <h3>Start Time</h3>
              <p>
                {{
                  event.startTime
                    ? formatTime(event.startTime)
                    : "Not specified"
                }}
              </p>
            </div>

            <div class="detail-item">
              <h3>End Time</h3>
              <p>
                {{
                  event.endTime ? formatTime(event.endTime) : "Not specified"
                }}
              </p>
            </div>
          </div>

          <div class="detail-item">
            <h3>Affects Attendance</h3>
            <p>{{ event.affectsAttendance ? "Yes" : "No" }}</p>
          </div>
        </div>

        <div class="detail-section" v-if="event.isRecurring">
          <h2>Recurrence</h2>

          <div class="detail-item">
            <h3>Recurrence Pattern</h3>
            <p>{{ formatRecurrencePattern(event.recurrencePattern) }}</p>
          </div>
        </div>

        <div
          class="detail-section"
          v-if="event.type === 'bell_schedule' && event.bellSchedule"
        >
          <h2>Bell Schedule</h2>

          <div class="bell-schedule">
            <table>
              <thead>
                <tr>
                  <th>Period</th>
                  <th>Type</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(period, index) in event.bellSchedule.periods"
                  :key="index"
                >
                  <td>{{ period.name }}</td>
                  <td>{{ formatPeriodType(period.type) }}</td>
                  <td>{{ formatTime(period.startTime) }}</td>
                  <td>{{ formatTime(period.endTime) }}</td>
                  <td>
                    {{ calculateDuration(period.startTime, period.endTime) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="detail-section">
          <h2>Additional Information</h2>

          <div class="detail-row">
            <div class="detail-item">
              <h3>Created</h3>
              <p>{{ formatDateTime(event.createdAt) }}</p>
            </div>

            <div class="detail-item">
              <h3>Last Updated</h3>
              <p>{{ formatDateTime(event.updatedAt) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Confirm Delete</h3>
        <p>Are you sure you want to delete the event "{{ event.title }}"?</p>
        <p class="warning">This action cannot be undone.</p>

        <div class="modal-actions">
          <button class="btn-cancel" @click="showDeleteModal = false">
            Cancel
          </button>
          <button class="btn-confirm-delete" @click="deleteEvent">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { API_URL } from "../config";

// Router and Route
const router = useRouter();
const route = useRoute();
const calendarId = route.params.calendarId as string;
const eventId = route.params.eventId as string;

// State
const event = ref<any>({});
const calendarName = ref("");
const loading = ref(true);
const error = ref("");
const showDeleteModal = ref(false);

// Format functions
const formatDate = (dateString: string): string => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Invalid Date";

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });
};

const formatTime = (timeString: string): string => {
  if (!timeString) return "N/A";

  // Convert 24-hour format to 12-hour format
  const [hours, minutes] = timeString.split(":");
  const hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;

  return `${hour12}:${minutes} ${ampm}`;
};

const formatDateTime = (dateTimeString: string): string => {
  if (!dateTimeString) return "N/A";

  const date = new Date(dateTimeString);
  if (isNaN(date.getTime())) return "Invalid Date";

  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

const formatEventType = (type: string): string => {
  if (!type) return "";

  // Convert snake_case to Title Case
  return type
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const formatPeriodType = (type: string): string => {
  if (!type) return "Regular";

  return type.charAt(0).toUpperCase() + type.slice(1);
};

const formatRecurrencePattern = (pattern: string): string => {
  if (!pattern) return "Not specified";

  const patterns: { [key: string]: string } = {
    daily: "Daily",
    weekly: "Weekly",
    biweekly: "Every two weeks",
    monthly: "Monthly",
    yearly: "Yearly",
  };

  return patterns[pattern] || pattern;
};

const calculateDuration = (startTime: string, endTime: string): string => {
  if (!startTime || !endTime) return "N/A";

  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  let durationMinutes =
    endHours * 60 + endMinutes - (startHours * 60 + startMinutes);

  // Handle cases where end time is on the next day
  if (durationMinutes < 0) {
    durationMinutes += 24 * 60;
  }

  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;

  if (hours === 0) {
    return `${minutes} minutes`;
  } else if (minutes === 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""}`;
  } else {
    return `${hours} hour${hours !== 1 ? "s" : ""} ${minutes} minute${
      minutes !== 1 ? "s" : ""
    }`;
  }
};

// Functions
const fetchEvent = async () => {
  loading.value = true;
  try {
    const response = await axios.get(
      `${API_URL}/calendar/${calendarId}/events/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    event.value = response.data;

    // Fetch calendar name
    const calendarResponse = await axios.get(
      `${API_URL}/calendar/${calendarId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    calendarName.value = calendarResponse.data.name;
  } catch (err: any) {
    error.value = err.message || "Failed to fetch event";
    console.error("Error fetching event:", err);
  } finally {
    loading.value = false;
  }
};

const editEvent = () => {
  router.push(`/calendar/${calendarId}/events/${eventId}/edit`);
};

const confirmDelete = () => {
  showDeleteModal.value = true;
};

const deleteEvent = async () => {
  try {
    await axios.delete(`${API_URL}/calendar/${calendarId}/events/${eventId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // Navigate back to events list
    router.push(`/calendar/${calendarId}/events`);
  } catch (err: any) {
    error.value = err.message || "Failed to delete event";
    console.error("Error deleting event:", err);
  }
};

const goBack = () => {
  router.push(`/calendar/${calendarId}/events`);
};

// Lifecycle hooks
onMounted(() => {
  fetchEvent();
});
</script>

<style scoped>
.view-event-container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-back,
.btn-edit {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}

.btn-back {
  background-color: #f0f0f0;
  color: #333;
}

.btn-back:hover {
  background-color: #ddd;
}

.btn-edit {
  background-color: #f39c12;
  color: white;
}

.btn-edit:hover {
  background-color: #e67e22;
}

.loading {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.event-header h1 {
  margin: 0;
  color: #2c3e50;
}

.event-type {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.event-type.regular {
  background-color: #3498db;
  color: white;
}

.event-type.closed {
  background-color: #e74c3c;
  color: white;
}

.event-type.snow_day {
  background-color: #3498db;
  color: white;
}

.event-type.early_dismissal {
  background-color: #f39c12;
  color: white;
}

.event-type.late_start {
  background-color: #9b59b6;
  color: white;
}

.event-type.sick_day {
  background-color: #e74c3c;
  color: white;
}

.event-type.holiday {
  background-color: #2ecc71;
  color: white;
}

.event-type.bell_schedule {
  background-color: #1abc9c;
  color: white;
}

.event-type.other {
  background-color: #95a5a6;
  color: white;
}

.event-details {
  margin-bottom: 20px;
}

.detail-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.detail-section h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: 1.2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.detail-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.detail-item {
  flex: 1;
}

.detail-item.full-width {
  flex: 100%;
}

.detail-item h3 {
  margin: 0 0 5px 0;
  font-size: 1rem;
  color: #7f8c8d;
}

.detail-item p {
  margin: 0;
  color: #2c3e50;
  font-size: 1rem;
}

.bell-schedule {
  overflow-x: auto;
}

.bell-schedule table {
  width: 100%;
  border-collapse: collapse;
}

.bell-schedule th,
.bell-schedule td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.bell-schedule th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #2c3e50;
}

.bell-schedule tr:hover {
  background-color: #f8f9fa;
}

.event-actions {
  display: flex;
  gap: 10px;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-delete:hover {
  background-color: #c82333;
}

.warning {
  color: #dc3545;
  font-weight: 600;
  margin-top: 10px;
}

.btn-confirm-delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-confirm-delete:hover {
  background-color: #c82333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.modal-content h3 {
  margin-top: 0;
  color: #2c3e50;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #333;
}

@media (max-width: 768px) {
  .header-actions {
    flex-direction: column;
    gap: 10px;
  }

  .event-actions {
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
