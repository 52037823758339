<template>
  <div class="add-calendar-container">
    <h1>Add New Calendar</h1>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <form @submit.prevent="handleSubmit" class="calendar-form">
      <div class="form-group">
        <label for="name">Calendar Name</label>
        <input
          type="text"
          id="name"
          v-model="formData.name"
          required
          placeholder="Enter calendar name"
        />
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <textarea
          id="description"
          v-model="formData.description"
          placeholder="Enter calendar description"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="type">Calendar Type</label>
        <select id="type" v-model="formData.type" required>
          <option value="school">School Calendar</option>
          <option value="district">District Calendar</option>
        </select>
      </div>

      <div class="form-group" v-if="formData.type === 'school'">
        <label for="schoolId">School</label>
        <div class="school-select-container">
          <input
            type="text"
            id="schoolSearch"
            v-model="schoolSearch"
            placeholder="Search schools..."
            class="school-search"
          />
          <select id="schoolId" v-model="formData.schoolId" required>
            <option value="">Select a school</option>
            <option
              v-for="school in filteredSchools"
              :key="school.id"
              :value="school.id"
            >
              {{ school.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="formData.type === 'district'">
        <label for="districtId">District</label>
        <div class="district-select-container">
          <input
            type="text"
            id="districtSearch"
            v-model="districtSearch"
            placeholder="Search districts..."
            class="district-search"
          />
          <select id="districtId" v-model="formData.districtId" required>
            <option value="">Select a district</option>
            <option
              v-for="district in filteredDistricts"
              :key="district.id"
              :value="district.id"
            >
              {{ district.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="academicYear">Academic Year</label>
        <select id="academicYear" v-model="formData.academicYear" required>
          <option value="">Select academic year</option>
          <option v-for="year in academicYears" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="startDate">Start Date</label>
          <input
            type="date"
            id="startDate"
            v-model="formData.startDate"
            required
          />
        </div>

        <div class="form-group">
          <label for="endDate">End Date</label>
          <input type="date" id="endDate" v-model="formData.endDate" required />
        </div>
      </div>

      <div class="form-actions">
        <button type="button" class="btn-cancel" @click="goBack">Cancel</button>
        <button type="submit" class="btn-submit" :disabled="loading">
          {{ loading ? "Creating..." : "Create Calendar" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { API_URL } from "../config";

const router = useRouter();
const loading = ref(false);
const error = ref("");
const schools = ref<any[]>([]);
const schoolSearch = ref("");
const districts = ref<any[]>([]);
const districtSearch = ref("");

// Generate academic years (current year - 2 to current year + 5)
const currentYear = new Date().getFullYear();
const academicYears = Array.from({ length: 8 }, (_, i) => currentYear - 2 + i);

const formData = reactive({
  name: "",
  description: "",
  type: "school",
  academicYear: currentYear.toString(),
  startDate: "",
  endDate: "",
  schoolId: "",
  districtId: "",
});

// Computed property for filtered and sorted schools
const filteredSchools = computed(() => {
  return schools.value
    .filter((school) =>
      school.name.toLowerCase().includes(schoolSearch.value.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

// Computed property for filtered and sorted districts
const filteredDistricts = computed(() => {
  return districts.value
    .filter((district) =>
      district.name.toLowerCase().includes(districtSearch.value.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

// Watch for type changes to reset selections
watch(
  () => formData.type,
  (newType) => {
    if (newType === "school") {
      formData.schoolId = "";
      formData.districtId = "";
    } else {
      formData.schoolId = "";
      formData.districtId = "";
    }
  }
);

// Fetch schools
const fetchSchools = async () => {
  try {
    const response = await axios.get(`${API_URL}/organizations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    schools.value = response.data;
  } catch (err: any) {
    error.value = "Failed to fetch schools";
    console.error("Error fetching schools:", err);
  }
};

// Fetch districts
const fetchDistricts = async () => {
  try {
    const response = await axios.get(`${API_URL}/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    districts.value = response.data;
  } catch (err: any) {
    error.value = "Failed to fetch districts";
    console.error("Error fetching districts:", err);
  }
};

const handleSubmit = async () => {
  loading.value = true;
  error.value = "";

  try {
    const submitData = {
      ...formData,
      schoolId: formData.type === "school" ? formData.schoolId : undefined,
      districtId:
        formData.type === "district" ? formData.districtId : undefined,
    };

    await axios.post(`${API_URL}/calendar`, submitData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    router.push("/calendar");
  } catch (err: any) {
    error.value = err.response?.data?.message || "Failed to create calendar";
    console.error("Error creating calendar:", err);
  } finally {
    loading.value = false;
  }
};

const goBack = () => {
  router.push("/calendar");
};

onMounted(() => {
  fetchSchools();
  fetchDistricts();
});
</script>

<style scoped>
.add-calendar-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  margin-bottom: 30px;
  color: #2c3e50;
}

.calendar-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #2c3e50;
}

input[type="text"],
input[type="date"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 30px;
}

.btn-cancel,
.btn-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #333;
}

.btn-cancel:hover {
  background-color: #e0e0e0;
}

.btn-submit {
  background-color: #3498db;
  color: white;
}

.btn-submit:hover:not(:disabled) {
  background-color: #2980b9;
}

.btn-submit:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.error-message {
  background-color: #fee;
  color: #c00;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .form-row {
    grid-template-columns: 1fr;
  }
}

.school-select-container {
  position: relative;
}

.school-search {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.school-search:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  cursor: pointer;
}

select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.schools-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
}

.school-checkbox {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.school-checkbox:last-child {
  border-bottom: none;
}

.school-checkbox input[type="checkbox"] {
  margin-right: 10px;
}

.school-checkbox label {
  margin: 0;
  cursor: pointer;
  flex: 1;
}

.school-checkbox:hover {
  background-color: #f8f9fa;
}
</style>
