<template>
  <div class="district-form-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/districts">Districts</router-link> /
      <span>{{ isEditMode ? "Edit District" : "Create District" }}</span>
    </nav>

    <h2 class="title">
      {{ isEditMode ? "Edit District" : "Create District" }}
    </h2>

    <form @submit.prevent="saveDistrict">
      <!-- Tabs -->
      <div class="tabs">
        <div
          :class="{ active: selectedTab === 'General Info' }"
          @click="selectedTab = 'General Info'"
        >
          General Info
        </div>
        <div
          :class="{ active: selectedTab === 'Contacts' }"
          @click="selectedTab = 'Contacts'"
        >
          Contacts
        </div>
        <div
          :class="{ active: selectedTab === 'Schools' }"
          @click="selectedTab = 'Schools'"
        >
          Schools
        </div>
      </div>

      <!-- General Info Tab Content -->
      <div v-show="selectedTab === 'General Info'">
        <!-- Name Field -->
        <div class="form-group">
          <label for="name">Name:</label>
          <input v-model="district.name" id="name" required />
        </div>

        <!-- Address Field -->
        <div class="form-group">
          <label for="address">Address:</label>
          <input v-model="district.address" id="address" />
        </div>

        <!-- City Field -->
        <div class="form-group">
          <label for="city">City:</label>
          <input v-model="district.city" id="city" />
        </div>

        <!-- Notes Field -->
        <div class="form-group">
          <label for="notes">Notes:</label>
          <textarea v-model="district.notes" id="notes"></textarea>
        </div>

        <!-- Organizations Count (Read-only) -->
        <div
          v-if="
            isEditMode &&
            district.organizations &&
            district.organizations.length > 0
          "
          class="form-group"
        >
          <label>Schools Count:</label>
          <input type="text" :value="district.organizations.length" readonly />
        </div>
      </div>

      <!-- Contacts Tab Content -->
      <div v-show="selectedTab === 'Contacts'">
        <div class="form-group">
          <label for="contact-select">Select Contacts to Add:</label>
          <select
            v-model="selectedContact"
            id="contact-select"
            class="contact-select"
          >
            <option
              v-for="contact in allContacts"
              :key="contact.id"
              :value="contact.id"
            >
              {{ contact.name }} ({{ contact.email }})
            </option>
          </select>
          <button @click.prevent="addContact" class="add-contact-button">
            Add Contact
          </button>
        </div>

        <!-- Selected Contacts Section -->
        <div
          v-if="district.contacts && district.contacts.length > 0"
          class="contacts-section"
        >
          <h3>Selected Contacts</h3>
          <ul>
            <li v-for="contact in district.contacts" :key="contact.id">
              {{ contact.name }} ({{ contact.email }})
              <button
                @click.prevent="removeContact(contact.id)"
                class="remove-contact-button"
              >
                Remove
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Schools Tab Content -->
      <div v-show="selectedTab === 'Schools'">
        <div class="form-group">
          <label for="school-select">Select Schools to Add:</label>
          <div class="search-container">
            <input
              v-model="schoolSearch"
              type="text"
              placeholder="Search schools..."
              @input="filterSchools"
              @focus="showSchoolDropdown = true"
              @blur="handleBlur"
            />
          </div>
          <div
            v-if="showSchoolDropdown && filteredSchools.length > 0"
            class="dropdown"
          >
            <div
              v-for="school in filteredSchools"
              :key="school.id"
              class="dropdown-item"
              @click="addSchool(school)"
            >
              {{ school.name }}
            </div>
          </div>
        </div>

        <!-- Selected Schools Section -->
        <div
          v-if="district.organizations && district.organizations.length > 0"
          class="schools-section"
        >
          <h3>Schools in District</h3>
          <div class="schools-grid">
            <div
              v-for="school in district.organizations"
              :key="school.id"
              class="school-card"
            >
              <div class="school-info">
                <h4>{{ school.name }}</h4>
                <p>{{ school.address }}</p>
                <p>{{ school.city }}</p>
              </div>
              <button
                @click.prevent="removeSchool(school.id)"
                class="remove-school-button"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="save-button">
        {{ isEditMode ? "Save Changes" : "Create District" }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { API_URL } from "../config";

const district = ref({
  name: "",
  address: "",
  city: "",
  notes: "",
  organizations: [],
  contacts: [],
});
const allContacts = ref([]);
const selectedContact = ref(null);
const isEditMode = ref(false);
const selectedTab = ref("General Info");
const router = useRouter();
const route = useRoute();

// School management
const allSchools = ref([]);
const schoolSearch = ref("");
const showSchoolDropdown = ref(false);
const filteredSchools = ref([]);

const fetchDistrict = async (id) => {
  try {
    const response = await fetch(`${API_URL}/districts/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      district.value = await response.json();
    } else {
      console.error("Failed to fetch district:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching district:", error);
  }
};

const fetchContacts = async () => {
  try {
    const response = await fetch(`${API_URL}/contacts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      allContacts.value = await response.json();
    } else {
      console.error("Failed to fetch contacts:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching contacts:", error);
  }
};

const fetchSchools = async () => {
  try {
    const response = await fetch(`${API_URL}/organizations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      allSchools.value = await response.json();
      filterSchools();
    } else {
      console.error("Failed to fetch schools:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching schools:", error);
  }
};

const handleBlur = () => {
  // Small delay to allow click events to fire on dropdown items
  setTimeout(() => {
    showSchoolDropdown.value = false;
  }, 200);
};

const filterSchools = () => {
  showSchoolDropdown.value = true;
  if (!schoolSearch.value) {
    filteredSchools.value = allSchools.value.filter(
      (school) =>
        !district.value.organizations.some((org) => org.id === school.id)
    );
    return;
  }

  filteredSchools.value = allSchools.value.filter(
    (school) =>
      school.name.toLowerCase().includes(schoolSearch.value.toLowerCase()) &&
      !district.value.organizations.some((org) => org.id === school.id)
  );
};

const addSchool = (school) => {
  if (!district.value.organizations.some((org) => org.id === school.id)) {
    district.value.organizations.push(school);
  }
  schoolSearch.value = "";
  showSchoolDropdown.value = false;
  filterSchools();
};

const removeSchool = (schoolId) => {
  district.value.organizations = district.value.organizations.filter(
    (org) => org.id !== schoolId
  );
  filterSchools();
};

const addContact = () => {
  const contactToAdd = allContacts.value.find(
    (c) => c.id === selectedContact.value
  );
  if (
    contactToAdd &&
    !district.value.contacts.find((c) => c.id === contactToAdd.id)
  ) {
    district.value.contacts.push(contactToAdd);
  }
};

const removeContact = (contactId) => {
  district.value.contacts = district.value.contacts.filter(
    (c) => c.id !== contactId
  );
};

const saveDistrict = async () => {
  const method = isEditMode.value ? "PUT" : "POST";
  const url = isEditMode.value
    ? `${API_URL}/districts/${district.value.id}`
    : `${API_URL}/districts`;

  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(district.value),
    });

    if (response.ok) {
      router.push("/districts");
    } else {
      console.error("Failed to save district:", response.statusText);
    }
  } catch (error) {
    console.error("Error saving district:", error);
  }
};

onMounted(() => {
  fetchContacts();
  fetchSchools();
  const id = Number(route.params.id);
  if (id) {
    isEditMode.value = true;
    fetchDistrict(id);
  } else {
    isEditMode.value = false;
  }
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.district-form-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.tabs div {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

.tabs div.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
textarea,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

textarea {
  min-height: 100px;
}

.save-button,
.add-contact-button,
.remove-contact-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
  display: block;
  width: 100%;
}

.remove-contact-button {
  background-color: #dc3545;
  margin-top: 0;
  margin-left: 10px;
}

.contacts-section {
  margin-top: 20px;
}

.contacts-section h3 {
  margin-bottom: 10px;
}

.contacts-section ul {
  list-style-type: none;
  padding: 0;
}

.contacts-section ul li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.search-container {
  position: relative;
  margin-bottom: 10px;
}

.search-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.schools-section {
  margin-top: 20px;
}

.schools-section h3 {
  margin-bottom: 15px;
}

.schools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}

.school-card {
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.school-info {
  flex: 1;
}

.school-info h4 {
  margin: 0 0 8px 0;
  color: #333;
}

.school-info p {
  margin: 4px 0;
  color: #666;
  font-size: 0.9em;
}

.remove-school-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 10px;
}

.remove-school-button:hover {
  background-color: #c82333;
}
</style>
