<template>
  <div class="navigation-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <span>Navigation</span>
    </nav>

    <h2 class="title">Student Navigation</h2>

    <!-- Map Section -->
    <div class="map-section">
      <div class="map-container">
        <l-map
          ref="map"
          v-model:zoom="zoom"
          :center="center"
          :use-global-leaflet="false"
        >
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
          ></l-tile-layer>
          <l-marker
            v-for="marker in markers"
            :key="marker.id"
            :lat-lng="[marker.latitude, marker.longitude]"
            @click="showMarkerInfo(marker)"
          >
            <l-icon
              :icon-url="getMarkerIcon(marker.type)"
              :icon-size="[25, 41]"
            >
            </l-icon>
            <l-popup>
              <div class="marker-popup">
                <span :class="marker.type">{{ marker.label }}</span>
                <h4>{{ marker.name }}</h4>
                <p>{{ marker.address }}</p>
              </div>
            </l-popup>
          </l-marker>

          <!-- Route lines -->
          <l-polyline
            v-if="routeLines.length > 0"
            :lat-lngs="routeLines"
            :color="routeColor"
            :weight="routeWeight"
            :opacity="routeOpacity"
          />
        </l-map>
      </div>

      <div class="map-legend">
        <div class="legend-item">
          <img
            src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"
            alt="Student"
            width="20"
          />
          <span>Student Location</span>
        </div>
        <div class="legend-item">
          <img
            src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"
            alt="School"
            width="20"
          />
          <span>School Location</span>
        </div>
      </div>
    </div>

    <div class="main-content">
      <!-- Left Column: Student Selection -->
      <div class="student-selection">
        <h3>Select Students</h3>
        <div class="filters">
          <div v-if="selectedSchoolId" class="filter-notice">
            Showing only students from
            {{
              students.find((s) => s.primarySchool?.id === selectedSchoolId)
                ?.primarySchool?.name
            }}
            <button
              class="clear-filter"
              @click="
                selectedSchoolId = null;
                selectedStudentIds = [];
              "
            >
              Clear Selection
            </button>
          </div>
          <div class="search-inputs">
            <div class="input-group">
              <label for="studentSearch">Search Students</label>
              <input
                v-model="filters.student"
                id="studentSearch"
                placeholder="Search by student name"
              />
            </div>
            <div class="input-group">
              <label for="schoolFilter">Filter by School</label>
              <select
                v-model="filters.schoolId"
                id="schoolFilter"
                :disabled="selectedSchoolId !== null"
              >
                <option value="">All Schools</option>
                <option
                  v-for="school in sortedSchools"
                  :key="school.id"
                  :value="school.id"
                >
                  {{ school.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="student-list">
          <div
            v-for="student in filteredStudents"
            :key="student.id"
            class="student-item"
            :class="{ selected: selectedStudentIds.includes(student.id) }"
          >
            <input
              type="checkbox"
              :id="'student-' + student.id"
              v-model="selectedStudentIds"
              :value="student.id"
            />
            <label :for="'student-' + student.id">
              {{ student.firstName }} {{ student.lastName }}
              <span class="school-name">{{ student.school?.name }}</span>
            </label>
          </div>
        </div>
      </div>

      <!-- Right Column: Trip Details -->
      <div class="trip-details">
        <h3>Trip Details</h3>
        <div class="trip-form">
          <div class="input-group">
            <label for="tripName">Trip Name</label>
            <input
              v-model="tripDetails.name"
              id="tripName"
              placeholder="Enter trip name"
            />
          </div>
          <div class="input-group">
            <label for="tripDate">Trip Date</label>
            <input v-model="tripDetails.date" id="tripDate" type="date" />
          </div>
          <div class="time-inputs">
            <div class="input-group">
              <label for="startTime">Start Time</label>
              <input
                v-model="tripDetails.startTime"
                id="startTime"
                type="time"
              />
            </div>
            <div class="input-group">
              <label for="endTime">End Time</label>
              <input v-model="tripDetails.endTime" id="endTime" type="time" />
            </div>
          </div>
          <div class="trip-notes">
            <label for="tripNotes">Notes</label>
            <textarea
              v-model="tripDetails.notes"
              id="tripNotes"
              placeholder="Enter trip notes"
            ></textarea>
          </div>
          <div class="selected-count">
            Selected Students: {{ selectedStudentIds.length }}
          </div>
          <button
            @click="createTrip"
            :disabled="!canCreateTrip"
            class="create-trip-btn"
          >
            Create Trip
          </button>
        </div>
      </div>

      <div class="delta1-section">
        <h3>Delta1 Analysis</h3>
        <button
          @click="generateDelta1"
          class="generate-delta1-btn"
          :disabled="isGenerating"
        >
          {{ isGenerating ? "Generating..." : "Generate Delta1" }}
        </button>

        <div v-if="delta1Data.length > 0" class="delta1-table">
          <table>
            <thead>
              <tr>
                <th>Student</th>
                <th>School</th>
                <th>Distance</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in delta1Data" :key="item.studentName">
                <td>{{ item.studentName }}</td>
                <td>{{ item.schoolName }}</td>
                <td>{{ item.distance }}</td>
                <td>{{ item.duration }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="delta2-section">
        <h3>Delta2 Route Optimization</h3>
        <button
          @click="generateDelta2"
          class="generate-delta2-btn"
          :disabled="isGeneratingDelta2 || selectedStudentIds.length === 0"
        >
          {{ isGeneratingDelta2 ? "Generating..." : "Generate Optimal Route" }}
        </button>

        <div v-if="delta2Data.length > 0" class="delta2-table">
          <table>
            <thead>
              <tr>
                <th>Segment</th>
                <th>From</th>
                <th>To</th>
                <th>Distance</th>
                <th>Duration</th>
                <th>Total Distance</th>
                <th>Total Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in delta2Data" :key="item.segmentNumber">
                <td>{{ item.segmentNumber }}</td>
                <td>{{ item.startAddress }}</td>
                <td>{{ item.endAddress }}</td>
                <td>{{ item.distance }}</td>
                <td>{{ item.duration }}</td>
                <td>{{ item.cumulativeDistance }}</td>
                <td>{{ item.cumulativeDuration }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { API_URL } from "../config";
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LPolyline,
} from "@vue-leaflet/vue-leaflet";
import type { LatLngTuple } from "leaflet";

// Types
interface School {
  id: number;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}

interface Student {
  id: number;
  firstName: string;
  lastName: string;
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  school?: School;
  latitude?: number;
  longitude?: number;
  primarySchool?: {
    id: number;
    name: string;
  };
}

interface Marker {
  id: number;
  type: "student" | "school";
  label: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}

interface TripDetails {
  name: string;
  date: string;
  startTime: string;
  endTime: string;
  notes: string;
}

interface Delta1Result {
  studentName: string;
  schoolName: string;
  distance: string;
  duration: string;
}

interface Delta2Result {
  segmentNumber: number;
  startAddress: string;
  endAddress: string;
  distance: string;
  duration: string;
  cumulativeDistance: string;
  cumulativeDuration: string;
}

interface RoutePoint {
  latitude: number;
  longitude: number;
}

interface RouteSegment {
  segmentNumber: number;
  startAddress: string;
  endAddress: string;
  distance: string;
  duration: string;
  cumulativeDistance: string;
  cumulativeDuration: string;
  startPoint?: RoutePoint;
  endPoint?: RoutePoint;
}

// State
const students = ref<Student[]>([]);
const schools = ref<School[]>([]);
const selectedStudentIds = ref<number[]>([]);
const selectedSchoolId = ref<number | null>(null);
const filters = ref({
  student: "",
  schoolId: "",
});

const tripDetails = ref<TripDetails>({
  name: "",
  date: new Date().toISOString().split("T")[0],
  startTime: "",
  endTime: "",
  notes: "",
});

// Map state
const zoom = ref<number>(12);
const center = ref<LatLngTuple>([39.8283, -98.5795]);

// Sort schools alphabetically
const sortedSchools = computed(() => {
  return [...schools.value].sort((a, b) => a.name.localeCompare(b.name));
});

// Watch for changes in selected students to update selectedSchoolId
watch(selectedStudentIds, (newSelectedIds) => {
  if (newSelectedIds.length === 0) {
    selectedSchoolId.value = null;
    if (mapRef.value) {
      center.value = [39.8283, -98.5795];
      zoom.value = 12;
    }
    return;
  }

  // Get the first selected student's school
  const firstSelectedStudent = students.value.find(
    (s) => s.id === newSelectedIds[0]
  );
  if (firstSelectedStudent?.primarySchool) {
    selectedSchoolId.value = firstSelectedStudent.primarySchool.id;

    if (
      firstSelectedStudent.primarySchool.latitude &&
      firstSelectedStudent.primarySchool.longitude &&
      mapRef.value
    ) {
      center.value = [
        firstSelectedStudent.primarySchool.latitude,
        firstSelectedStudent.primarySchool.longitude,
      ];
      zoom.value = 13;
    }
  }
});

// Filter students based on search and school selection
const filteredStudents = computed(() => {
  return students.value.filter((student) => {
    const matchesSearch =
      student.firstName
        .toLowerCase()
        .includes(filters.value.student.toLowerCase()) ||
      student.lastName
        .toLowerCase()
        .includes(filters.value.student.toLowerCase());

    // Show all students if no school filter and no student selected
    if (!filters.value.schoolId && !selectedSchoolId.value) {
      return matchesSearch;
    }

    // If a student is selected, only show students from the same school
    if (selectedSchoolId.value) {
      return (
        matchesSearch && student.primarySchool?.id === selectedSchoolId.value
      );
    }

    // Otherwise, use the school filter
    return (
      matchesSearch &&
      student.primarySchool?.id === parseInt(filters.value.schoolId)
    );
  });
});

// Update markers when students or selection changes
const markers = computed(() => {
  const studentMarkers = students.value
    .filter(
      (student) =>
        selectedStudentIds.value.includes(student.id) &&
        student.latitude &&
        student.longitude
    )
    .map((student) => ({
      id: student.id,
      latitude: student.latitude!,
      longitude: student.longitude!,
      type: "student" as const,
      label: `${student.firstName} ${student.lastName}`,
      name: student.primarySchool?.name || "No School",
      address: student.addressLine1 || "No Address",
    }));

  // Add school marker if students are selected
  if (selectedStudentIds.value.length > 0) {
    const firstStudent = students.value.find((s) =>
      selectedStudentIds.value.includes(s.id)
    );
    const school = firstStudent?.primarySchool;

    if (school?.latitude && school?.longitude) {
      studentMarkers.push({
        id: -1, // Use negative ID to avoid conflicts with student IDs
        latitude: school.latitude,
        longitude: school.longitude,
        type: "school" as const,
        label: "School",
        name: school.name,
        address: school.address || "",
      });
    }
  }

  return studentMarkers;
});

const mapRef = ref<any>(null);

// Watch for changes in markers to update map bounds
watch(
  markers,
  (newMarkers) => {
    if (newMarkers.length > 0 && mapRef.value) {
      const bounds = newMarkers.map(
        (m) => [m.latitude, m.longitude] as LatLngTuple
      );
      mapRef.value.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 15,
      });
    }
  },
  { deep: true }
);

const getMarkerIcon = (type: string) => {
  return type === "student"
    ? "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"
    : "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png";
};

const showMarkerInfo = (marker: Marker) => {
  // Optional: Add any additional marker click handling
  console.log("Marker clicked:", marker);
};

const canCreateTrip = computed(() => {
  return (
    tripDetails.value.name &&
    tripDetails.value.date &&
    tripDetails.value.startTime &&
    tripDetails.value.endTime &&
    selectedStudentIds.value.length > 0
  );
});

const createTrip = async () => {
  try {
    // Check if all selected students are from the same school
    const selectedStudents = students.value.filter((s) =>
      selectedStudentIds.value.includes(s.id)
    );
    const schoolIds = new Set(selectedStudents.map((s) => s.primarySchool?.id));

    if (schoolIds.size > 1) {
      alert("All students in a trip must be from the same school");
      return;
    }

    const response = await fetch(`${API_URL}/trips`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        ...tripDetails.value,
        studentIds: selectedStudentIds.value,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to create trip");
    }

    const result = await response.json();
    // Reset form and selection
    tripDetails.value = {
      name: "",
      date: new Date().toISOString().split("T")[0],
      startTime: "",
      endTime: "",
      notes: "",
    };
    selectedStudentIds.value = [];

    alert("Trip created successfully! You can view it in the trips page.");
  } catch (error: any) {
    console.error("Error creating trip:", error);
    alert(`Failed to create trip: ${error.message}`);
  }
};

// Methods
const fetchData = async () => {
  try {
    const [studentsResponse, schoolsResponse] = await Promise.all([
      fetch(`${API_URL}/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      fetch(`${API_URL}/organizations`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    ]);

    if (!studentsResponse.ok || !schoolsResponse.ok) {
      const error = (await studentsResponse.ok)
        ? await schoolsResponse.json()
        : await studentsResponse.json();
      throw new Error(error.message || "Failed to fetch data");
    }

    const studentsData = await studentsResponse.json();
    const schoolsData = await schoolsResponse.json();

    students.value = studentsData.map((student: any) => ({
      ...student,
      latitude: student.lat,
      longitude: student.lng,
      school: student.primarySchool,
    }));

    schools.value = schoolsData.map((school: any) => ({
      ...school,
      latitude: school.latitude || 0,
      longitude: school.longitude || 0,
    }));

    // Update map center to first school if available
    if (schools.value.length > 0) {
      const firstSchool = schools.value[0];
      center.value = [firstSchool.latitude, firstSchool.longitude];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    alert(
      "Failed to load students and schools. Please check your connection and try again."
    );
  }
};

onMounted(() => {
  fetchData();
});

// Add components registration
const components = {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LPolyline,
};

const isGenerating = ref(false);
const delta1Data = ref<Delta1Result[]>([]);

const generateDelta1 = async () => {
  try {
    isGenerating.value = true;
    const response = await fetch(`${API_URL}/delta1/generate`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.ok) {
      delta1Data.value = await response.json();
    } else {
      alert("Failed to generate Delta1 data");
    }
  } catch (error) {
    console.error("Error generating Delta1:", error);
    alert("Error generating Delta1 data");
  } finally {
    isGenerating.value = false;
  }
};

const isGeneratingDelta2 = ref(false);
const delta2Data = ref<Delta2Result[]>([]);
const routeLines = ref<Array<[number, number][]>>([]);
const routeColor = ref("#2196f3");
const routeWeight = ref(3);
const routeOpacity = ref(0.8);

const generateDelta2 = async () => {
  try {
    isGeneratingDelta2.value = true;
    const response = await fetch(`${API_URL}/delta2/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        studentIds: selectedStudentIds.value,
      }),
    });

    if (response.ok) {
      delta2Data.value = await response.json();
      // Create route lines from the ordered points
      const points = await getRoutePoints();
      if (points.length >= 2) {
        routeLines.value = [points.map((p) => [p.latitude, p.longitude])];
      }
    } else {
      alert("Failed to generate Delta2 data");
    }
  } catch (error) {
    console.error("Error generating Delta2:", error);
    alert("Error generating Delta2 data");
  } finally {
    isGeneratingDelta2.value = false;
  }
};

const getRoutePoints = async (): Promise<RoutePoint[]> => {
  const selectedStudents = students.value.filter((s) =>
    selectedStudentIds.value.includes(s.id)
  );

  if (selectedStudents.length === 0) return [];

  const school = selectedStudents[0].primarySchool;
  if (!school) return [];

  // Create a map of addresses to coordinates
  const addressToCoords = new Map<string, RoutePoint>();

  // Add student coordinates
  selectedStudents.forEach((student) => {
    if (student.latitude && student.longitude) {
      addressToCoords.set(student.addressLine1, {
        latitude: student.latitude,
        longitude: student.longitude,
      });
    }
  });

  // Add school coordinates
  if (school.latitude && school.longitude) {
    addressToCoords.set(school.address, {
      latitude: school.latitude,
      longitude: school.longitude,
    });
  }

  // Map the route points in order
  const routePoints: RoutePoint[] = [];
  for (const segment of delta2Data.value) {
    const startCoords = addressToCoords.get(segment.startAddress);
    if (
      startCoords &&
      !routePoints.some(
        (p) =>
          p.latitude === startCoords.latitude &&
          p.longitude === startCoords.longitude
      )
    ) {
      routePoints.push(startCoords);
    }

    const endCoords = addressToCoords.get(segment.endAddress);
    if (
      endCoords &&
      !routePoints.some(
        (p) =>
          p.latitude === endCoords.latitude &&
          p.longitude === endCoords.longitude
      )
    ) {
      routePoints.push(endCoords);
    }
  }

  return routePoints;
};

// Add watch to clear route lines when selection changes
watch(selectedStudentIds, () => {
  routeLines.value = [];
});
</script>

<style scoped>
.navigation-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.map-section {
  margin-bottom: 2rem;
}

.map-container {
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.map-legend {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 4px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.main-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.student-selection,
.trip-details {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #2c3e50;
}

.student-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  max-height: 400px;
  overflow-y: auto;
}

.student-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.student-item:hover {
  background-color: #e9ecef;
}

.student-item.selected {
  background-color: #e3f2fd;
  border-left: 4px solid #1976d2;
}

.create-trip-btn {
  padding: 12px 24px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s;
}

.create-trip-btn:hover:not(:disabled) {
  background-color: #45a049;
}

.create-trip-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.selected-count {
  text-align: center;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin-top: 1rem;
  font-weight: 500;
}

.controls {
  margin-bottom: 20px;
}

.search-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  font-weight: 500;
  color: #666;
}

.input-group input,
.input-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.marker-popup {
  padding: 5px;
}

.marker-popup h4 {
  margin: 5px 0;
}

.marker-popup p {
  margin: 5px 0;
  color: #666;
}

.time-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.trip-notes {
  margin-top: 1rem;
}

.trip-notes textarea {
  width: 100%;
  min-height: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.filter-notice {
  background-color: #e3f2fd;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-filter {
  background-color: #1976d2;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
}

.clear-filter:hover {
  background-color: #1565c0;
}

.delta1-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.generate-delta1-btn {
  padding: 0.75rem 1.5rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.generate-delta1-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.delta1-table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

th,
td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.delta2-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.generate-delta2-btn {
  padding: 0.75rem 1.5rem;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.generate-delta2-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.delta2-table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

th,
td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.leaflet-polyline {
  pointer-events: none;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
}
</style>
