<template>
  <div class="searchable-dropdown">
    <div class="dropdown-header" @click="toggleDropdown">
      <input
        type="text"
        :value="displayValue"
        :placeholder="placeholder"
        @input="handleSearch"
        @click.stop
        class="search-input"
      />
      <span class="dropdown-arrow">▼</span>
    </div>
    <div v-if="isOpen" class="dropdown-content">
      <div
        v-for="option in filteredOptions"
        :key="option.value"
        class="dropdown-option"
        :class="{ selected: modelValue === option.value }"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  defineProps,
  defineEmits,
} from "vue";

interface Option {
  value: string | number;
  label: string;
}

const props = defineProps<{
  modelValue: string | number;
  options: Option[];
  placeholder?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string | number): void;
}>();

const isOpen = ref(false);
const searchQuery = ref("");

const displayValue = computed(() => {
  const selectedOption = props.options.find(
    (opt) => opt.value === props.modelValue
  );
  return selectedOption ? selectedOption.label : "";
});

const filteredOptions = computed(() => {
  return props.options
    .filter((option) =>
      option.label.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
    .sort((a, b) => a.label.localeCompare(b.label));
});

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const handleSearch = (event: Event) => {
  const input = event.target as HTMLInputElement;
  searchQuery.value = input.value;
  isOpen.value = true;
};

const selectOption = (option: Option) => {
  emit("update:modelValue", option.value);
  isOpen.value = false;
  searchQuery.value = "";
};

const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (!target.closest(".searchable-dropdown")) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped>
.searchable-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  background-color: white;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  font-size: 14px;
}

.dropdown-arrow {
  margin-left: 8px;
  font-size: 12px;
  color: #666;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-option:hover {
  background-color: #f5f5f5;
}

.dropdown-option.selected {
  background-color: #e3f2fd;
  color: #1976d2;
}
</style>
