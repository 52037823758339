<template>
  <div class="view-student-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/students">Students</router-link> /
      <span>View Student</span>
    </nav>

    <h2 class="title">Student Details</h2>

    <div class="student-details" v-if="student">
      <!-- Personal Information Section -->
      <section class="detail-section">
        <h3>Personal Information</h3>
        <div class="detail-grid">
          <div class="detail-item">
            <label>First Name:</label>
            <span>{{ student.firstName }}</span>
          </div>
          <div class="detail-item">
            <label>Last Name:</label>
            <span>{{ student.lastName }}</span>
          </div>
        </div>
      </section>

      <!-- Address Section -->
      <section class="detail-section">
        <h3>Address</h3>
        <div class="detail-grid">
          <div class="detail-item">
            <label>Address:</label>
            <span>{{ student.addressLine1 }}</span>
          </div>
          <div class="detail-item">
            <label>City:</label>
            <span>{{ student.city }}</span>
          </div>
          <div class="detail-item">
            <label>State:</label>
            <span>{{ student.state }}</span>
          </div>
          <div class="detail-item">
            <label>ZIP:</label>
            <span>{{ student.zip }}</span>
          </div>
        </div>
      </section>

      <!-- School Information -->
      <section class="detail-section">
        <h3>School Information</h3>
        <div class="detail-grid">
          <div class="detail-item">
            <label>School:</label>
            <span>{{ student.primarySchool?.name || "Not assigned" }}</span>
          </div>
          <div class="detail-item">
            <label>Group:</label>
            <span>{{ student.group?.name || "Not assigned" }}</span>
          </div>
        </div>
      </section>

      <!-- Needs and Comments -->
      <section class="detail-section">
        <h3>Additional Information</h3>
        <div class="detail-grid">
          <div class="detail-item full-width">
            <label>Needs:</label>
            <div class="needs-list">
              <span v-for="need in needsList" :key="need" class="need-tag">
                {{ need }}
              </span>
            </div>
          </div>
          <div class="detail-item full-width">
            <label>Comments:</label>
            <p class="comments">{{ student.comments || "No comments" }}</p>
          </div>
        </div>
      </section>
    </div>

    <div class="actions">
      <button @click="goBack" class="secondary-button">Back to List</button>
      <button @click="editStudent" class="primary-button">Edit Student</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { API_URL } from "../config";

interface Student {
  id: number;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  birthday?: string;
  gender?: "male" | "female";
  weight?: number;
  ethnicity?: string;
  grade?: string;
  level?: string;
  studentType?: string;
  comments?: string;
  notes?: string;
  needs?: string;
  lng?: number;
  lat?: number;
  pickupLat?: number;
  pickupLng?: number;
  pickupAddress?: string;
  pickupNotes?: string;
  primarySchool?: {
    id: number;
    name: string;
  };
  group?: {
    id: number;
    name: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

const route = useRoute();
const router = useRouter();
const student = ref<Student | null>(null);

const needsList = computed(() => {
  if (!student.value?.needs) return [];
  return student.value.needs.split(",").map((need: string) => need.trim());
});

const fetchStudent = async () => {
  try {
    const response = await fetch(`${API_URL}/students/${route.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      student.value = await response.json();
    } else {
      console.error("Failed to fetch student details");
      alert("Failed to load student details");
    }
  } catch (error) {
    console.error("Error:", error);
    alert("An error occurred while fetching student details");
  }
};

const goBack = () => {
  router.push("/students");
};

const editStudent = () => {
  router.push(`/students/${route.params.id}/edit`);
};

onMounted(() => {
  fetchStudent();
});
</script>

<style scoped>
.view-student-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.detail-section {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.detail-section h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 1.2em;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detail-item.full-width {
  grid-column: 1 / -1;
}

.detail-item label {
  font-weight: 600;
  color: #666;
  font-size: 0.9em;
}

.detail-item span {
  color: #333;
}

.needs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.need-tag {
  background-color: #e9ecef;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.9em;
}

.comments {
  white-space: pre-wrap;
  margin: 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.primary-button,
.secondary-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.primary-button {
  background-color: #007bff;
  color: white;
}

.secondary-button {
  background-color: #6c757d;
  color: white;
}

.primary-button:hover,
.secondary-button:hover {
  opacity: 0.9;
}
</style>
