<template>
  <div class="edit-organization-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/organizations">Schools</router-link> /
      <span>Edit School</span>
    </nav>

    <h2 class="title">Edit School</h2>

    <!-- Progress Indicator -->
    <div class="steps-indicator">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="step"
        :class="{
          active: selectedTab === tab.id,
          completed: isTabComplete(tab.id),
        }"
        @click="selectedTab = tab.id"
      >
        <div class="step-number">
          <span v-if="isTabComplete(tab.id)" class="checkmark">✓</span>
          <span v-else>{{ tab.number }}</span>
        </div>
        <div class="step-label">{{ tab.label }}</div>
      </div>
    </div>

    <form @submit.prevent="saveOrganization" class="edit-form">
      <!-- General Info Tab -->
      <div v-show="selectedTab === 'general'" class="form-step">
        <div class="form-section">
          <h3>Basic Information</h3>
          <div class="form-grid">
            <div class="form-group">
              <label for="name">School Name</label>
              <div class="input-wrapper">
                <input
                  v-model="organization.name"
                  id="name"
                  required
                  placeholder="Enter school name"
                  :class="{ error: errors.name }"
                />
                <span class="error-message" v-if="errors.name">{{
                  errors.name
                }}</span>
              </div>
            </div>
            <div class="form-group">
              <label for="districtName">District</label>
              <div class="district-select-container">
                <div class="district-input-wrapper">
                  <input
                    v-model="districtSearch"
                    @focus="showDistrictDropdown = true"
                    :placeholder="
                      organization.districtName || 'Search districts...'
                    "
                    class="district-search-input"
                    :class="{ 'has-value': organization.districtName }"
                  />
                  <button
                    type="button"
                    class="district-dropdown-toggle"
                    @click="toggleDistrictDropdown"
                  >
                    {{ showDistrictDropdown ? "▼" : "▶" }}
                  </button>
                </div>
                <div v-if="showDistrictDropdown" class="district-dropdown">
                  <div
                    v-for="district in filteredDistricts"
                    :key="district.id"
                    class="district-option"
                    :class="{
                      selected: district.name === organization.districtName,
                    }"
                    @click="selectDistrict(district)"
                  >
                    {{ district.name }}
                  </div>
                </div>
                <div
                  v-if="organization.districtName"
                  class="selected-district-badge"
                >
                  {{ organization.districtName }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="organizationType">Type</label>
              <select
                v-model="organization.organizationType"
                id="organizationType"
              >
                <option value="">Select Type</option>
                <option value="Elementary">Elementary School</option>
                <option value="Middle">Middle School</option>
                <option value="High">High School</option>
                <option value="Charter">Charter School</option>
                <option value="Private">Private School</option>
              </select>
            </div>
            <div class="form-group">
              <label for="organizationCode">School Code</label>
              <input
                v-model="organization.organizationCode"
                id="organizationCode"
              />
            </div>
            <div class="form-group">
              <label class="checkbox-label">
                <input
                  type="checkbox"
                  v-model="organization.isActive"
                  id="isActive"
                />
                <span>Active School</span>
              </label>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>School Hours</h3>
          <div class="form-grid">
            <div class="form-group">
              <label>Default Start Time</label>
              <input
                type="time"
                v-model="organization.defaultStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Default End Time</label>
              <input
                type="time"
                v-model="organization.defaultEndTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Early Dismissal Start Time</label>
              <input
                type="time"
                v-model="organization.earlyDismissalStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Early Dismissal End Time</label>
              <input
                type="time"
                v-model="organization.earlyDismissalEndTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Emergency Start Time</label>
              <input
                type="time"
                v-model="organization.emergencyStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Emergency End Time</label>
              <input
                type="time"
                v-model="organization.emergencyEndTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Shortened Day Start Time</label>
              <input
                type="time"
                v-model="organization.shortenedDayStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Shortened Day End Time</label>
              <input
                type="time"
                v-model="organization.shortenedDayEndTime"
                step="300"
              />
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>Contact Information</h3>
          <div class="form-grid">
            <div class="form-group full-width">
              <label for="address">Street Address</label>
              <input v-model="organization.address" id="address" />
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input v-model="organization.city" id="city" required />
            </div>
            <div class="form-group">
              <label for="state">State</label>
              <select v-model="organization.state" id="state">
                <option value="">Select State</option>
                <option v-for="state in states" :key="state" :value="state">
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="zipcode">ZIP Code</label>
              <input
                v-model="organization.zipcode"
                id="zipcode"
                pattern="[0-9]*"
                maxlength="5"
              />
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <input
                v-model="organization.phone"
                id="phone"
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="123-456-7890"
              />
            </div>
            <div class="form-group">
              <label for="website">Website</label>
              <input
                v-model="organization.website"
                id="website"
                type="url"
                placeholder="https://example.com"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Grades Tab -->
      <div v-show="selectedTab === 'grades'" class="form-step">
        <div class="form-section">
          <h3>Grade Levels</h3>
          <div class="grades-grid">
            <div
              v-for="grade in gradesList"
              :key="grade.key"
              class="grade-item"
            >
              <label :for="grade.key" class="grade-label">
                <span class="grade-name">{{ grade.label }}</span>
                <div class="toggle-switch">
                  <input
                    type="checkbox"
                    :id="grade.key"
                    :checked="organization[grade.key] === 1"
                    @change="toggleGrade(grade.key, $event)"
                  />
                  <span class="toggle-slider"></span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Location Tab -->
      <div v-show="selectedTab === 'location'" class="form-step">
        <div class="form-section">
          <h3>Geographic Location</h3>
          <div class="form-grid">
            <div class="form-group">
              <label for="latitude">Latitude</label>
              <input
                v-model="organization.latitude"
                id="latitude"
                type="number"
                step="0.000001"
              />
            </div>
            <div class="form-group">
              <label for="longitude">Longitude</label>
              <input
                v-model="organization.longitude"
                id="longitude"
                type="number"
                step="0.000001"
              />
            </div>
          </div>
          <!-- Add map integration here if needed -->
        </div>
      </div>

      <!-- Groups Tab -->
      <div v-show="selectedTab === 'groups'" class="form-step">
        <div class="form-section">
          <h3>Student Groups</h3>
          <div class="groups-container">
            <div
              v-for="(group, index) in organization.groups"
              :key="index"
              class="group-card"
            >
              <div class="group-header">
                <h4>Group {{ index + 1 }}</h4>
                <button
                  type="button"
                  class="remove-group-btn"
                  @click="removeGroup(index)"
                >
                  ×
                </button>
              </div>
              <div class="group-content">
                <div class="form-group">
                  <label :for="'groupName' + index">Group Name</label>
                  <input v-model="group.name" :id="'groupName' + index" />
                </div>
                <div class="form-grid">
                  <div class="form-group">
                    <label :for="'bellTime' + index">Bell Time</label>
                    <input
                      type="time"
                      v-model="group.bellTime"
                      :id="'bellTime' + index"
                    />
                  </div>
                  <div class="form-group">
                    <label :for="'dismissalTime' + index">Dismissal Time</label>
                    <input
                      type="time"
                      v-model="group.dismissalTime"
                      :id="'dismissalTime' + index"
                    />
                  </div>
                </div>
                <div class="form-grid">
                  <div class="form-group">
                    <label :for="'startDate' + index">Start Date</label>
                    <input
                      type="date"
                      v-model="group.startDate"
                      :id="'startDate' + index"
                    />
                  </div>
                  <div class="form-group">
                    <label :for="'endDate' + index">End Date</label>
                    <input
                      type="date"
                      v-model="group.endDate"
                      :id="'endDate' + index"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label :for="'pickupNotes' + index">Pickup Notes</label>
                  <textarea
                    v-model="group.pickupNotes"
                    :id="'pickupNotes' + index"
                    rows="2"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label :for="'dropOffNotes' + index">Drop-off Notes</label>
                  <textarea
                    v-model="group.dropOffNotes"
                    :id="'dropOffNotes' + index"
                    rows="2"
                  ></textarea>
                </div>
              </div>
            </div>
            <button type="button" class="add-group-btn" @click="addGroup">
              + Add New Group
            </button>
          </div>
        </div>
      </div>

      <!-- Form Navigation -->
      <div class="form-navigation">
        <button
          type="button"
          class="secondary-button"
          @click="previousTab"
          v-if="!isFirstTab"
        >
          <span class="button-icon">←</span> Previous
        </button>
        <button
          type="button"
          class="primary-button"
          @click="nextTab"
          v-if="!isLastTab"
        >
          Next <span class="button-icon">→</span>
        </button>
        <button type="submit" class="submit-button" v-if="isLastTab">
          Save Changes
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { API_URL } from "../config";
import { formatTimeTo12Hour, formatTimeTo24Hour } from "../utils/timeFormat";

interface Group {
  name: string;
  pickupNotes: string;
  dropOffNotes: string;
  bellTime: string;
  dismissalTime: string;
  startDate: string;
  endDate: string;
}

interface District {
  id: number;
  name: string;
}

interface Organization {
  id: number;
  districtName: string;
  name: string;
  organizationType: string;
  organizationCode: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  website: string;
  defaultStartTime: string;
  defaultEndTime: string;
  earlyDismissalStartTime: string;
  earlyDismissalEndTime: string;
  emergencyStartTime: string;
  emergencyEndTime: string;
  shortenedDayStartTime: string;
  shortenedDayEndTime: string;
  isActive: boolean;
  prekindergarten: number;
  kindergarten: number;
  grade1: number;
  grade2: number;
  grade3: number;
  grade4: number;
  grade5: number;
  grade6: number;
  grade7: number;
  grade8: number;
  grade9: number;
  grade10: number;
  grade11: number;
  grade12: number;
  latitude: string;
  longitude: string;
  groups: Group[];
}

interface GradeOption {
  key: keyof Organization;
  label: string;
}

const route = useRoute();
const router = useRouter();
const districts = ref<District[]>([]);
const districtSearch = ref("");
const showDistrictDropdown = ref(false);
const organization = ref<Organization>({
  id: 0,
  districtName: "",
  name: "",
  organizationType: "",
  organizationCode: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  phone: "",
  website: "",
  defaultStartTime: "",
  defaultEndTime: "",
  earlyDismissalStartTime: "",
  earlyDismissalEndTime: "",
  emergencyStartTime: "",
  emergencyEndTime: "",
  shortenedDayStartTime: "",
  shortenedDayEndTime: "",
  isActive: true,
  prekindergarten: 0,
  kindergarten: 0,
  grade1: 0,
  grade2: 0,
  grade3: 0,
  grade4: 0,
  grade5: 0,
  grade6: 0,
  grade7: 0,
  grade8: 0,
  grade9: 0,
  grade10: 0,
  grade11: 0,
  grade12: 0,
  latitude: "0",
  longitude: "0",
  groups: [],
});

const errors = ref({
  name: "",
  city: "",
});

const selectedTab = ref("general");

const tabs = [
  { id: "general", number: 1, label: "General Info" },
  { id: "grades", number: 2, label: "Grades" },
  { id: "location", number: 3, label: "Location" },
  { id: "groups", number: 4, label: "Groups" },
];

const gradesList: GradeOption[] = [
  { key: "prekindergarten", label: "Pre-K" },
  { key: "kindergarten", label: "Kindergarten" },
  { key: "grade1", label: "Grade 1" },
  { key: "grade2", label: "Grade 2" },
  { key: "grade3", label: "Grade 3" },
  { key: "grade4", label: "Grade 4" },
  { key: "grade5", label: "Grade 5" },
  { key: "grade6", label: "Grade 6" },
  { key: "grade7", label: "Grade 7" },
  { key: "grade8", label: "Grade 8" },
  { key: "grade9", label: "Grade 9" },
  { key: "grade10", label: "Grade 10" },
  { key: "grade11", label: "Grade 11" },
  { key: "grade12", label: "Grade 12" },
];

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const fetchOrganization = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      Object.assign(organization.value, await response.json());
    } else {
      console.error("Failed to fetch organization:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching organization:", error);
  }
};

const fetchDistricts = async () => {
  try {
    const response = await fetch(`${API_URL}/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      districts.value = await response.json();
    } else {
      console.error("Failed to fetch districts:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
  }
};

const filteredDistricts = computed(() => {
  return districts.value.filter((district) =>
    district.name.toLowerCase().includes(districtSearch.value.toLowerCase())
  );
});

const selectDistrict = (district: District) => {
  organization.value.districtName = district.name;
  showDistrictDropdown.value = false;
  districtSearch.value = ""; // Clear the search input
};

const toggleDistrictDropdown = () => {
  showDistrictDropdown.value = !showDistrictDropdown.value;
};

const saveOrganization = async () => {
  if (!validateForm()) {
    selectedTab.value = "general"; // Go back to general tab if validation fails
    return;
  }

  try {
    const response = await fetch(
      `${API_URL}/organizations/${organization.value.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(organization.value),
      }
    );
    if (response.ok) {
      await fetchOrganization(organization.value.id);
      alert("Organization updated successfully!");
    } else {
      const error = await response.json();
      alert(
        `Failed to save organization: ${error.message || response.statusText}`
      );
    }
  } catch (error) {
    console.error("Error saving organization:", error);
    alert("An error occurred while saving the organization.");
  }
};

const addGroup = () => {
  organization.value.groups.push({
    name: "",
    pickupNotes: "",
    dropOffNotes: "",
    bellTime: "",
    dismissalTime: "",
    startDate: "",
    endDate: "",
  });
};

const removeGroup = (index: number) => {
  organization.value.groups.splice(index, 1);
};

const currentTabIndex = computed(() => {
  return tabs.findIndex((tab) => tab.id === selectedTab.value);
});

const isFirstTab = computed(() => currentTabIndex.value === 0);
const isLastTab = computed(() => currentTabIndex.value === tabs.length - 1);

const previousTab = () => {
  if (!isFirstTab.value) {
    selectedTab.value = tabs[currentTabIndex.value - 1].id;
  }
};

const nextTab = () => {
  if (!isLastTab.value) {
    selectedTab.value = tabs[currentTabIndex.value + 1].id;
  }
};

const validateForm = () => {
  let isValid = true;
  errors.value.name = "";
  errors.value.city = "";

  if (!organization.value.name.trim()) {
    errors.value.name = "School name is required";
    isValid = false;
  }

  if (!organization.value.city.trim()) {
    errors.value.city = "City is required";
    isValid = false;
  }

  return isValid;
};

const isTabComplete = (tabId: string) => {
  switch (tabId) {
    case "general":
      return !!organization.value.name && !!organization.value.city;
    case "grades":
      return true; // Optional section
    case "location":
      return true; // Optional section
    case "groups":
      return true; // Optional section
    default:
      return false;
  }
};

const toggleGrade = (gradeKey: string, event: Event) => {
  const target = event.target as HTMLInputElement;
  organization.value[gradeKey] = target.checked ? 1 : 0;
};

onMounted(() => {
  const id = Number(route.params.id);
  if (id) {
    fetchOrganization(id);
  }
  fetchDistricts();
});
</script>

<style scoped>
.edit-organization-container {
  padding: 2rem;
  max-width: 1400px;
  margin: 2rem auto;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.breadcrumb {
  margin-bottom: 2rem;
  padding: 0.75rem 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  font-size: 0.9rem;
}

.breadcrumb a {
  color: #4a90e2;
  text-decoration: none;
  transition: color 0.2s;
}

.breadcrumb a:hover {
  color: #357abd;
}

.breadcrumb span {
  color: #6c757d;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  color: #2c3e50;
  font-size: 2rem;
  font-weight: 600;
}

.form-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.form-section h3 {
  color: #2c3e50;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f2f5;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #495057;
  font-weight: 500;
  font-size: 0.9rem;
  transition: color 0.2s;
}

.form-group:focus-within label {
  color: #4a90e2;
}

input[type="text"],
input[type="number"],
input[type="time"],
input[type="date"],
input[type="tel"],
input[type="url"],
select,
textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s;
  background-color: #fff;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="time"]:focus,
input[type="date"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
  border-radius: 4px;
  border: 2px solid #dee2e6;
  cursor: pointer;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #495057;
  font-size: 0.95rem;
}

.steps-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  position: relative;
}

.steps-indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #dee2e6;
  z-index: 1;
}

.step {
  position: relative;
  z-index: 2;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.2s;
}

.step.active {
  background-color: #4a90e2;
  color: #fff;
}

.step.completed {
  background-color: #28a745;
  color: #fff;
}

.step-number {
  font-weight: 600;
  font-size: 1.1rem;
}

.step-label {
  margin-top: 0.25rem;
  font-size: 0.9rem;
}

.grades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
}

.grade-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s;
}

.grade-item:hover {
  background-color: #e9ecef;
  transform: translateY(-1px);
}

.grade-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grade-name {
  font-size: 0.95rem;
  color: #495057;
}

.group-card {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  overflow: hidden;
  transition: all 0.2s;
}

.group-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.group-header {
  background-color: #f8f9fa;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.group-header h4 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.1rem;
}

.group-content {
  padding: 1.5rem;
}

.remove-group-btn {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
  border-radius: 4px;
  transition: all 0.2s;
}

.remove-group-btn:hover {
  background-color: #fff;
  transform: scale(1.1);
}

.add-group-btn {
  width: 100%;
  padding: 1.25rem;
  background-color: #f8f9fa;
  border: 2px dashed #dee2e6;
  border-radius: 12px;
  color: #6c757d;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s;
}

.add-group-btn:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
  color: #495057;
  transform: translateY(-1px);
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1rem;
}

.secondary-button,
.primary-button,
.submit-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.secondary-button {
  background-color: #6c757d;
  color: #fff;
}

.secondary-button:hover {
  background-color: #5a6268;
  transform: translateY(-1px);
}

.primary-button {
  background-color: #4a90e2;
  color: #fff;
}

.primary-button:hover {
  background-color: #357abd;
  transform: translateY(-1px);
}

.submit-button {
  background-color: #28a745;
  color: #fff;
  width: 100%;
  justify-content: center;
}

.submit-button:hover {
  background-color: #218838;
  transform: translateY(-1px);
}

.button-icon {
  font-size: 1.1rem;
}

.error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 0.25rem;
  display: block;
}

input.error {
  border-color: #dc3545;
}

input.error:focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.1);
}

@media (max-width: 768px) {
  .edit-organization-container {
    padding: 1rem;
    margin: 1rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .steps-indicator {
    flex-direction: column;
    gap: 0.5rem;
  }

  .steps-indicator::before {
    display: none;
  }

  .form-navigation {
    flex-direction: column;
  }

  .secondary-button,
  .primary-button,
  .submit-button {
    width: 100%;
    justify-content: center;
  }
}

.district-select-container {
  position: relative;
}

.district-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background-color: #fff;
}

.district-search-input {
  flex: 1;
  border: none;
  padding: 0.75rem;
  font-size: 0.95rem;
  border-radius: 8px 0 0 8px;
}

.district-search-input:focus {
  outline: none;
  box-shadow: none;
}

.district-dropdown-toggle {
  padding: 0.75rem;
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  border-radius: 0 8px 8px 0;
  transition: all 0.2s;
}

.district-dropdown-toggle:hover {
  background-color: #f8f9fa;
}

.district-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.district-option {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.district-option:hover {
  background-color: #f8f9fa;
  color: #4a90e2;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s;
  background-color: #fff;
}

.input-wrapper input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.input-wrapper input.error {
  border-color: #dc3545;
}

.input-wrapper input.error:focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.1);
}

.input-wrapper .error-message {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.grade-input {
  width: 80px;
  text-align: right;
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 0.95rem;
}

.grade-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #4a90e2;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.district-search-input.has-value {
  color: #2c3e50;
  font-weight: 500;
}

.district-option.selected {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
}

.selected-district-badge {
  position: absolute;
  top: -8px;
  left: 8px;
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 1;
}

.district-select-container {
  position: relative;
  margin-top: 8px;
}
</style>
