<template>
  <div class="view-organization-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/organizations">Schools</router-link> /
      <span>View School</span>
    </nav>

    <div class="header-section">
      <h2 class="title">View School</h2>
      <router-link
        :to="`/organizations/${organization.id}/edit`"
        class="btn-edit"
      >
        <font-awesome-icon icon="edit" /> Edit School
      </router-link>
    </div>

    <div class="tabs">
      <div
        :class="{ active: selectedTab === 'General Info' }"
        @click="selectedTab = 'General Info'"
      >
        General Info
      </div>
      <div
        :class="{ active: selectedTab === 'Grades' }"
        @click="selectedTab = 'Grades'"
      >
        Grades
      </div>
      <div
        :class="{ active: selectedTab === 'Location' }"
        @click="selectedTab = 'Location'"
      >
        Location
      </div>
      <div
        :class="{ active: selectedTab === 'Groups' }"
        @click="selectedTab = 'Groups'"
      >
        Groups
      </div>
      <div
        :class="{ active: selectedTab === 'Calendars' }"
        @click="selectedTab = 'Calendars'"
      >
        <font-awesome-icon icon="calendar" /> Calendars
      </div>
    </div>

    <div class="tab-content">
      <!-- General Info Tab -->
      <div v-show="selectedTab === 'General Info'">
        <div class="form-group">
          <label>District Name:</label>
          <p>{{ organization.districtName }}</p>
        </div>
        <div class="form-group">
          <label>Name:</label>
          <p>{{ organization.name }}</p>
        </div>
        <div class="form-group">
          <label>Organization Type:</label>
          <p>{{ organization.organizationType }}</p>
        </div>
        <div class="form-group">
          <label>Organization Code:</label>
          <p>{{ organization.organizationCode }}</p>
        </div>
        <div class="form-group">
          <label>Status:</label>
          <p>
            <span
              :class="[
                'status-badge',
                organization.isActive ? 'active' : 'inactive',
              ]"
            >
              {{ organization.isActive ? "Active" : "Inactive" }}
            </span>
          </p>
        </div>
        <div class="form-group">
          <label>Address:</label>
          <p>{{ organization.address }}</p>
        </div>
        <div class="form-group">
          <label>City:</label>
          <p>{{ organization.city }}</p>
        </div>
        <div class="form-group">
          <label>State:</label>
          <p>{{ organization.state }}</p>
        </div>
        <div class="form-group">
          <label>Zipcode:</label>
          <p>{{ organization.zipcode }}</p>
        </div>
        <div class="form-group">
          <label>Phone:</label>
          <p>{{ organization.phone }}</p>
        </div>
        <div class="form-group">
          <label>Website:</label>
          <p>{{ organization.website }}</p>
        </div>
        <div class="form-group">
          <label>Default School Hours:</label>
          <p>
            {{ formatTimeTo12Hour(organization.defaultStartTime) }} -
            {{ formatTimeTo12Hour(organization.defaultEndTime) }}
          </p>
        </div>
        <div class="form-group">
          <label>Early Dismissal Hours:</label>
          <p>
            {{ formatTimeTo12Hour(organization.earlyDismissalStartTime) }} -
            {{ formatTimeTo12Hour(organization.earlyDismissalEndTime) }}
          </p>
        </div>
        <div class="form-group">
          <label>Emergency Schedule Hours:</label>
          <p>
            {{ formatTimeTo12Hour(organization.emergencyStartTime) }} -
            {{ formatTimeTo12Hour(organization.emergencyEndTime) }}
          </p>
        </div>
        <div class="form-group">
          <label>Shortened Day Hours:</label>
          <p>
            {{ formatTimeTo12Hour(organization.shortenedDayStartTime) }} -
            {{ formatTimeTo12Hour(organization.shortenedDayEndTime) }}
          </p>
        </div>
      </div>

      <!-- Grades Tab -->
      <div v-show="selectedTab === 'Grades'" class="form-step">
        <div class="form-section">
          <h3>Grade Levels</h3>
          <div class="grades-container">
            <div v-if="activeGrades.length === 0" class="no-grades">
              No grade levels configured
            </div>
            <div v-else class="grades-tags">
              <div
                v-for="grade in activeGrades"
                :key="grade.key"
                class="grade-tag"
              >
                {{ grade.label }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Location Tab -->
      <div v-show="selectedTab === 'Location'">
        <div class="form-group">
          <label>Latitude:</label>
          <p>{{ organization.latitude }}</p>
        </div>
        <div class="form-group">
          <label>Longitude:</label>
          <p>{{ organization.longitude }}</p>
        </div>
      </div>

      <!-- Groups Tab -->
      <div v-show="selectedTab === 'Groups'">
        <div
          v-for="(group, index) in organization.groups"
          :key="index"
          class="group-container"
        >
          <div class="form-group">
            <label>Group Name:</label>
            <p>{{ group.name }}</p>
          </div>
          <div class="form-group">
            <label>Pickup Notes:</label>
            <p>{{ group.pickupNotes }}</p>
          </div>
          <div class="form-group">
            <label>Drop-off Notes:</label>
            <p>{{ group.dropOffNotes }}</p>
          </div>
          <div class="form-group">
            <label>Bell Time:</label>
            <p>{{ group.bellTime }}</p>
          </div>
          <div class="form-group">
            <label>Dismissal Time:</label>
            <p>{{ group.dismissalTime }}</p>
          </div>
          <div class="form-group">
            <label>Start Date:</label>
            <p>{{ group.startDate }}</p>
          </div>
          <div class="form-group">
            <label>End Date:</label>
            <p>{{ group.endDate }}</p>
          </div>
        </div>
      </div>

      <!-- Calendars Tab -->
      <div v-show="selectedTab === 'Calendars'">
        <div class="calendars-header">
          <h3>School Calendars</h3>
          <button class="btn-add" @click="addCalendar">
            <font-awesome-icon icon="plus" /> Add Calendar
          </button>
        </div>

        <div v-if="loadingCalendars" class="loading">
          <p>Loading calendars...</p>
        </div>

        <div v-else-if="calendars.length === 0" class="no-data">
          <p>No calendars found for this school.</p>
          <button class="btn-add-calendar" @click="addCalendar">
            <font-awesome-icon icon="plus" /> Create First Calendar
          </button>
        </div>

        <div v-else class="calendar-list">
          <div
            v-for="calendar in calendars"
            :key="calendar.id"
            class="calendar-card"
          >
            <div class="calendar-header">
              <h3>{{ calendar.name }}</h3>
              <span class="calendar-type" :class="calendar.type">{{
                calendar.type
              }}</span>
            </div>

            <div class="calendar-details">
              <p>
                <strong>Academic Year:</strong>
                {{ calendar.academicYear || "N/A" }}
              </p>
              <p>
                <strong>Start Date:</strong>
                {{ formatDate(calendar.startDate) }}
              </p>
              <p>
                <strong>End Date:</strong> {{ formatDate(calendar.endDate) }}
              </p>
              <p v-if="calendar.defaultStartTime || calendar.defaultEndTime">
                <strong>Default Times:</strong>
                {{ calendar.defaultStartTime || "N/A" }} -
                {{ calendar.defaultEndTime || "N/A" }}
              </p>
              <p>
                <strong>Status:</strong>
                <span :class="'status-' + calendar.status">{{
                  calendar.status
                }}</span>
              </p>
            </div>

            <div class="calendar-actions">
              <button class="btn-view" @click="viewCalendar(calendar.id)">
                <font-awesome-icon icon="eye" /> View Details
              </button>
              <button class="btn-edit" @click="editCalendar(calendar.id)">
                <font-awesome-icon icon="edit" /> Edit
              </button>
              <button
                class="btn-events"
                @click="viewCalendarEvents(calendar.id)"
              >
                <font-awesome-icon icon="calendar-day" /> Events
              </button>
            </div>

            <!-- Calendar Attachments Section -->
            <div class="calendar-attachments">
              <h4>
                <font-awesome-icon icon="paperclip" /> Attachments
                <span
                  v-if="calendar.attachments && calendar.attachments.length > 0"
                  >({{ calendar.attachments.length }})</span
                >
              </h4>

              <div
                v-if="
                  !calendar.attachments || calendar.attachments.length === 0
                "
                class="no-attachments"
              >
                <p>No attachments</p>
              </div>

              <div v-else class="attachment-preview-list">
                <div
                  v-for="attachment in calendar.attachments.slice(0, 3)"
                  :key="attachment.id"
                  class="attachment-preview-item"
                >
                  <div class="attachment-icon">
                    <font-awesome-icon
                      v-if="attachment.type === 'pdf'"
                      icon="file-pdf"
                    />
                    <font-awesome-icon
                      v-else-if="attachment.type === 'document'"
                      icon="file-word"
                    />
                    <font-awesome-icon
                      v-else-if="attachment.type === 'image'"
                      icon="image"
                    />
                    <font-awesome-icon v-else icon="file" />
                  </div>
                  <span class="attachment-name">{{
                    attachment.originalFilename
                  }}</span>
                </div>

                <div
                  v-if="calendar.attachments.length > 3"
                  class="more-attachments"
                >
                  <span>+{{ calendar.attachments.length - 3 }} more</span>
                </div>
              </div>

              <button
                class="btn-view-all"
                @click="viewCalendarAttachments(calendar.id)"
              >
                View All Attachments
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { API_URL } from "../config";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { formatTimeTo12Hour } from "../utils/timeFormat";
import axios from "axios";

const route = useRoute();
const router = useRouter();
const organization = ref({
  id: 0,
  districtName: "",
  name: "",
  organizationType: "",
  organizationCode: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  phone: "",
  website: "",
  defaultStartTime: "",
  defaultEndTime: "",
  earlyDismissalStartTime: "",
  earlyDismissalEndTime: "",
  emergencyStartTime: "",
  emergencyEndTime: "",
  shortenedDayStartTime: "",
  shortenedDayEndTime: "",
  isActive: false,
  prekindergarten: 0,
  kindergarten: 0,
  grade1: 0,
  grade2: 0,
  grade3: 0,
  grade4: 0,
  grade5: 0,
  grade6: 0,
  grade7: 0,
  grade8: 0,
  grade9: 0,
  grade10: 0,
  grade11: 0,
  grade12: 0,
  latitude: "0",
  longitude: "0",
  groups: [] as Array<{
    name: string;
    pickupNotes: string;
    dropOffNotes: string;
    bellTime: string;
    dismissalTime: string;
    startDate: string;
    endDate: string;
  }>, // Initialize groups array with type
});

const selectedTab = ref("General Info");
const calendars = ref<any[]>([]);
const loadingCalendars = ref(false);

const gradesList = [
  { key: "prekindergarten", label: "Pre-K" },
  { key: "kindergarten", label: "Kindergarten" },
  { key: "grade1", label: "Grade 1" },
  { key: "grade2", label: "Grade 2" },
  { key: "grade3", label: "Grade 3" },
  { key: "grade4", label: "Grade 4" },
  { key: "grade5", label: "Grade 5" },
  { key: "grade6", label: "Grade 6" },
  { key: "grade7", label: "Grade 7" },
  { key: "grade8", label: "Grade 8" },
  { key: "grade9", label: "Grade 9" },
  { key: "grade10", label: "Grade 10" },
  { key: "grade11", label: "Grade 11" },
  { key: "grade12", label: "Grade 12" },
];

const activeGrades = computed(() => {
  return gradesList.filter((grade) => organization.value[grade.key] === 1);
});

const fetchOrganization = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      Object.assign(organization.value, await response.json());
    } else {
      console.error("Failed to fetch organization:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching organization:", error);
  }
};

const fetchCalendars = async () => {
  loadingCalendars.value = true;
  try {
    const response = await axios.get(`${API_URL}/calendar`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        type: "school",
        schoolId: organization.value.id,
      },
    });

    // For each calendar, fetch its attachments
    const calendarsWithAttachments = await Promise.all(
      response.data.map(async (calendar: any) => {
        try {
          const attachmentsResponse = await axios.get(
            `${API_URL}/calendar/${calendar.id}/attachments`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          return { ...calendar, attachments: attachmentsResponse.data };
        } catch (error) {
          console.error(
            `Error fetching attachments for calendar ${calendar.id}:`,
            error
          );
          return { ...calendar, attachments: [] };
        }
      })
    );

    calendars.value = calendarsWithAttachments;
  } catch (error) {
    console.error("Error fetching calendars:", error);
  } finally {
    loadingCalendars.value = false;
  }
};

const formatDate = (dateString: string): string => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Invalid Date";

  // Adjust for timezone offset
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() + userTimezoneOffset);

  return adjustedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const addCalendar = () => {
  // Navigate to add calendar page with pre-filled school info
  router.push({
    name: "AddCalendar",
    query: {
      type: "school",
      schoolId: organization.value.id.toString(),
      schoolName: organization.value.name,
      returnTo: `/organizations/${organization.value.id}`,
    },
  });
};

const editCalendar = (calendarId: number) => {
  router.push({
    name: "EditCalendar",
    params: { id: calendarId },
    query: {
      returnTo: `/organizations/${organization.value.id}`,
    },
  });
};

const viewCalendar = (calendarId: number) => {
  router.push({
    name: "ViewCalendar",
    params: { id: calendarId },
    query: {
      returnTo: `/organizations/${organization.value.id}`,
    },
  });
};

const viewCalendarEvents = (calendarId: number) => {
  router.push({
    name: "CalendarEvents",
    params: { calendarId },
    query: {
      returnTo: `/organizations/${organization.value.id}`,
    },
  });
};

const viewCalendarAttachments = (calendarId: number) => {
  router.push({
    name: "ViewCalendar",
    params: { id: calendarId },
    query: {
      tab: "attachments",
      returnTo: `/organizations/${organization.value.id}`,
    },
  });
};

onMounted(() => {
  const id = Number(route.params.id);
  if (id) {
    fetchOrganization(id);
    // Check if the Calendars tab is selected or if there's a tab parameter in the URL
    if (selectedTab.value === "Calendars" || route.query.tab === "calendars") {
      selectedTab.value = "Calendars";
      fetchCalendars();
    }
  }
});

// Watch for tab changes to load calendars when the Calendars tab is selected
watch(selectedTab, (newTab: string) => {
  if (newTab === "Calendars" && organization.value.id) {
    fetchCalendars();
  }
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
  background-color: #fff;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
  background-color: #fff;
}

.breadcrumb span {
  color: #6c757d;
  background-color: #fff;
}

.view-organization-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

p {
  margin: 0;
  padding: 8px;
  background-color: #f7f7f7;
  border-radius: 4px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.tabs div {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  gap: 5px;
}

.tabs div.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.tab-content {
  padding: 20px;
}

.group-container {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Calendar styles */
.calendars-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-add-calendar {
  padding: 8px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}

.btn-add-calendar:hover {
  background-color: #218838;
}

.loading,
.no-data {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 20px;
}

.no-data button {
  margin-top: 15px;
}

.calendar-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.calendar-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.calendar-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

.calendar-type {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: capitalize;
}

.calendar-type.school {
  background-color: #007bff;
  color: white;
}

.calendar-type.district {
  background-color: #6c757d;
  color: white;
}

.calendar-type.student {
  background-color: #28a745;
  color: white;
}

.calendar-details {
  padding: 15px;
}

.calendar-details p {
  margin-bottom: 8px;
  background-color: transparent;
  padding: 0;
}

.status-active {
  color: #28a745;
  font-weight: 600;
}

.status-archived {
  color: #6c757d;
  font-weight: 600;
}

.calendar-actions {
  display: flex;
  gap: 10px;
  padding: 0 15px 15px;
}

.btn-view,
.btn-edit,
.btn-events {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.btn-view {
  background-color: #17a2b8;
  color: white;
}

.btn-view:hover {
  background-color: #138496;
}

.btn-edit {
  background-color: #ffc107;
  color: #212529;
}

.btn-edit:hover {
  background-color: #e0a800;
}

.btn-events {
  background-color: #6f42c1;
  color: white;
}

.btn-events:hover {
  background-color: #5a32a3;
}

.calendar-attachments {
  padding: 15px;
  border-top: 1px solid #eee;
}

.calendar-attachments h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #495057;
  display: flex;
  align-items: center;
  gap: 5px;
}

.no-attachments {
  font-style: italic;
  color: #6c757d;
  margin-bottom: 10px;
}

.attachment-preview-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.attachment-preview-item {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 0.9rem;
}

.attachment-icon {
  color: #6c757d;
}

.attachment-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.more-attachments {
  padding: 5px 10px;
  background-color: #e9ecef;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #495057;
}

.btn-view-all {
  padding: 6px 12px;
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 10px;
}

.btn-view-all:hover {
  background-color: #e9ecef;
}

@media (max-width: 768px) {
  .tabs {
    flex-wrap: wrap;
  }

  .tabs div {
    flex: 1 1 auto;
    text-align: center;
    padding: 8px 10px;
    font-size: 14px;
  }

  .calendar-actions {
    flex-direction: column;
  }

  .btn-view,
  .btn-edit,
  .btn-events {
    width: 100%;
    justify-content: center;
  }
}

.btn-edit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease;
}

.btn-edit:hover {
  background-color: #27ae60;
  transform: translateY(-1px);
}

.btn-edit svg {
  font-size: 1rem;
}

.status-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge.active {
  background-color: #d4edda;
  color: #155724;
}

.status-badge.inactive {
  background-color: #f8d7da;
  color: #721c24;
}

.grades-container {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.no-grades {
  color: #6c757d;
  font-style: italic;
  text-align: center;
  padding: 1rem;
}

.grades-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  padding: 0.5rem;
}

.grade-tag {
  background-color: #4a90e2;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(74, 144, 226, 0.2);
  transition: all 0.2s ease;
}

.grade-tag:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(74, 144, 226, 0.3);
}
</style>
