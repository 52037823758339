<template>
  <div class="edit-calendar-container">
    <h1>Edit Calendar</h1>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-if="loading" class="loading">
      <p>Loading calendar data...</p>
    </div>

    <form v-else @submit.prevent="submitForm" class="calendar-form">
      <div class="form-group">
        <label for="name">Calendar Name *</label>
        <input
          type="text"
          id="name"
          v-model="formData.name"
          required
          placeholder="Enter calendar name"
        />
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <textarea
          id="description"
          v-model="formData.description"
          placeholder="Enter calendar description"
          rows="3"
        ></textarea>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="type">Calendar Type *</label>
          <select
            id="type"
            v-model="formData.type"
            required
            @change="handleTypeChange"
          >
            <option value="">Select Type</option>
            <option value="school">School</option>
            <option value="district">District</option>
            <option value="student">Student</option>
          </select>
        </div>

        <div class="form-group">
          <label for="academicYear">Academic Year</label>
          <select id="academicYear" v-model="formData.academicYear">
            <option value="">Select Academic Year</option>
            <option v-for="year in academicYears" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <!-- School Selector (shown when type is 'school') -->
      <div v-if="formData.type === 'school'" class="form-group">
        <label for="schoolId">School *</label>
        <SearchableDropdown
          v-model="formData.schoolId"
          :options="schoolOptions"
          placeholder="Search and select a school"
        />
      </div>

      <!-- District Selector (shown when type is 'district') -->
      <div v-if="formData.type === 'district'" class="form-group">
        <label for="districtId">District *</label>
        <SearchableDropdown
          v-model="formData.districtId"
          :options="districtOptions"
          placeholder="Search and select a district"
        />
      </div>

      <!-- Student Selector (shown when type is 'student') -->
      <div v-if="formData.type === 'student'" class="form-group">
        <label for="studentId">Student *</label>
        <SearchableDropdown
          v-model="formData.studentId"
          :options="studentOptions"
          placeholder="Search and select a student"
        />
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="startDate">Start Date *</label>
          <input
            type="date"
            id="startDate"
            v-model="formData.startDate"
            required
          />
        </div>

        <div class="form-group">
          <label for="endDate">End Date *</label>
          <input type="date" id="endDate" v-model="formData.endDate" required />
        </div>
      </div>

      <div class="form-section">
        <h2>Additional Settings</h2>

        <div class="form-group">
          <label for="status">Status</label>
          <select id="status" v-model="formData.status">
            <option value="active">Active</option>
            <option value="archived">Archived</option>
            <option value="draft">Draft</option>
          </select>
          <small class="form-help"
            >Set to "Active" to make this calendar visible to users</small
          >
        </div>
      </div>

      <div class="form-actions">
        <button type="button" class="btn-cancel" @click="goBack">Cancel</button>
        <button type="submit" class="btn-submit" :disabled="isSubmitting">
          <span v-if="isSubmitting">Saving...</span>
          <span v-else>Update Calendar</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { API_URL } from "../config";
import SearchableDropdown from "../components/SearchableDropdown.vue";

// Router and Route
const router = useRouter();
const route = useRoute();
const calendarId = Number(route.params.calendarId);

// State
const error = ref("");
const loading = ref(true);
const isSubmitting = ref(false);

// Data loading states
const loadingSchools = ref(false);
const loadingDistricts = ref(false);
const loadingStudents = ref(false);

// Data lists
const schools = ref<any[]>([]);
const districts = ref<any[]>([]);
const students = ref<any[]>([]);

// Generate academic years (current year - 2 to current year + 5)
const currentYear = new Date().getFullYear();
const academicYears = Array.from({ length: 8 }, (_, i) => currentYear - 2 + i);

// Form data
const formData = reactive({
  name: "",
  description: "",
  type: "",
  academicYear: "",
  startDate: "",
  endDate: "",
  status: "active",
  schoolId: "",
  districtId: "",
  studentId: "",
});

// Add computed properties for dropdown options
const schoolOptions = computed(() => {
  return schools.value.map((school) => ({
    value: school.id.toString(),
    label: school.name,
  }));
});

const districtOptions = computed(() => {
  return districts.value.map((district) => ({
    value: district.id.toString(),
    label: district.name,
  }));
});

const studentOptions = computed(() => {
  return students.value.map((student) => ({
    value: student.id.toString(),
    label: student.name,
  }));
});

// Format date for input fields (YYYY-MM-DD)
const formatDateForInput = (dateString: string): string => {
  if (!dateString) return "";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "";

  return date.toISOString().split("T")[0];
};

// Fetch schools
const fetchSchools = async () => {
  loadingSchools.value = true;
  try {
    const response = await axios.get(`${API_URL}/organizations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    schools.value = response.data;
  } catch (err: any) {
    console.error("Error fetching schools:", err);
    error.value = "Failed to load schools. Please try again.";
  } finally {
    loadingSchools.value = false;
  }
};

// Fetch districts
const fetchDistricts = async () => {
  loadingDistricts.value = true;
  try {
    const response = await axios.get(`${API_URL}/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    districts.value = response.data;
  } catch (err: any) {
    console.error("Error fetching districts:", err);
    error.value = "Failed to load districts. Please try again.";
  } finally {
    loadingDistricts.value = false;
  }
};

// Fetch students
const fetchStudents = async () => {
  loadingStudents.value = true;
  try {
    const response = await axios.get(`${API_URL}/students`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    students.value = response.data;
  } catch (err: any) {
    console.error("Error fetching students:", err);
    error.value = "Failed to load students. Please try again.";
  } finally {
    loadingStudents.value = false;
  }
};

// Handle type change
const handleTypeChange = () => {
  // Reset the IDs
  formData.schoolId = "";
  formData.districtId = "";
  formData.studentId = "";

  // Fetch the appropriate data based on the selected type
  if (formData.type === "school" && schools.value.length === 0) {
    fetchSchools();
  } else if (formData.type === "district" && districts.value.length === 0) {
    fetchDistricts();
  } else if (formData.type === "student" && students.value.length === 0) {
    fetchStudents();
  }
};

// Fetch calendar data
const fetchCalendar = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`${API_URL}/calendar/${calendarId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const calendar = response.data;

    // Populate form data
    formData.name = calendar.name || "";
    formData.description = calendar.description || "";
    formData.type = calendar.type || "";
    formData.academicYear = calendar.academicYear?.toString() || "";
    formData.startDate = formatDateForInput(calendar.startDate);
    formData.endDate = formatDateForInput(calendar.endDate);
    formData.status = calendar.status || "active";

    // Set the appropriate ID based on the calendar type
    if (calendar.type === "school" && calendar.school) {
      formData.schoolId = calendar.school.id.toString();
      // Fetch schools if not already loaded
      if (schools.value.length === 0) {
        await fetchSchools();
      }
    } else if (calendar.type === "district" && calendar.district) {
      formData.districtId = calendar.district.id.toString();
      // Fetch districts if not already loaded
      if (districts.value.length === 0) {
        await fetchDistricts();
      }
    } else if (calendar.type === "student" && calendar.student) {
      formData.studentId = calendar.student.id.toString();
      // Fetch students if not already loaded
      if (students.value.length === 0) {
        await fetchStudents();
      }
    }
  } catch (err: any) {
    error.value = err.message || "Failed to fetch calendar";
    console.error("Error fetching calendar:", err);
  } finally {
    loading.value = false;
  }
};

// Functions
const submitForm = async () => {
  // Validate dates
  if (new Date(formData.endDate) < new Date(formData.startDate)) {
    error.value = "End date cannot be before start date";
    return;
  }

  // Validate that the appropriate entity is selected based on type
  if (formData.type === "school" && !formData.schoolId) {
    error.value = "Please select a school";
    return;
  } else if (formData.type === "district" && !formData.districtId) {
    error.value = "Please select a district";
    return;
  } else if (formData.type === "student" && !formData.studentId) {
    error.value = "Please select a student";
    return;
  }

  isSubmitting.value = true;
  error.value = "";

  try {
    await axios.put(`${API_URL}/calendar/${calendarId}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // Check if we should return to a specific page
    const returnTo = route.query.returnTo as string;
    if (returnTo) {
      router.push(returnTo);
    } else {
      // Default: redirect to calendar list
      router.push("/calendar");
    }
  } catch (err: any) {
    error.value =
      err.response?.data?.message || err.message || "Failed to update calendar";
    console.error("Error updating calendar:", err);
    isSubmitting.value = false;
  }
};

const goBack = () => {
  // Check if we should return to a specific page
  const returnTo = route.query.returnTo as string;
  if (returnTo) {
    router.push(returnTo);
  } else {
    // Default: go back to calendar list
    router.push("/calendar");
  }
};

// Lifecycle hooks
onMounted(() => {
  fetchCalendar();
});
</script>

<style scoped>
.edit-calendar-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.loading {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 20px;
}

.loading-indicator {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
}

.form-help {
  display: block;
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

.calendar-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.form-group:nth-child(3) {
  z-index: 2;
}

.form-group:nth-child(4) {
  z-index: 3;
}

.form-group:nth-child(5) {
  z-index: 4;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #2c3e50;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

textarea {
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-cancel,
.btn-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #333;
}

.btn-cancel:hover {
  background-color: #ddd;
}

.btn-submit {
  background-color: #4caf50;
  color: white;
}

.btn-submit:hover {
  background-color: #45a049;
}

.btn-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
