<template>
  <div class="add-organization-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/organizations">Schools</router-link> /
      <span>Add School</span>
    </nav>

    <h2 class="title">Add New School</h2>

    <!-- Progress Indicator -->
    <div class="steps-indicator">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="step"
        :class="{
          active: selectedTab === tab.id,
          completed: isTabComplete(tab.id),
        }"
        @click="selectedTab = tab.id"
      >
        <div class="step-number">
          <span v-if="isTabComplete(tab.id)" class="checkmark">✓</span>
          <span v-else>{{ tab.number }}</span>
        </div>
        <div class="step-label">{{ tab.label }}</div>
      </div>
    </div>

    <form @submit.prevent="createOrganization" class="add-form">
      <!-- General Info Tab -->
      <div v-show="selectedTab === 'general'" class="form-step">
        <div class="form-section">
          <h3>Basic Information</h3>
          <div class="form-grid">
            <div class="form-group">
              <label for="name">School Name</label>
              <input
                v-model="organization.name"
                id="name"
                name="name"
                required
                :class="{ error: errors.name }"
              />
              <span class="error-message" v-if="errors.name">{{
                errors.name
              }}</span>
            </div>
            <div class="form-group">
              <label for="districtName">District</label>
              <div class="district-select-container">
                <div class="district-input-wrapper">
                  <input
                    v-model="districtSearch"
                    @focus="showDistrictDropdown = true"
                    :placeholder="
                      organization.districtName || 'Search districts...'
                    "
                    class="district-search-input"
                    :class="{ 'has-value': organization.districtName }"
                  />
                  <button
                    type="button"
                    class="district-dropdown-toggle"
                    @click="toggleDistrictDropdown"
                  >
                    {{ showDistrictDropdown ? "▼" : "▶" }}
                  </button>
                </div>
                <div v-if="showDistrictDropdown" class="district-dropdown">
                  <div
                    v-for="district in filteredDistricts"
                    :key="district.id"
                    class="district-option"
                    :class="{
                      selected: district.name === organization.districtName,
                    }"
                    @click="selectDistrict(district)"
                  >
                    {{ district.name }}
                  </div>
                </div>
                <div
                  v-if="organization.districtName"
                  class="selected-district-badge"
                >
                  {{ organization.districtName }}
                </div>
              </div>
              <span class="error-message" v-if="errors.districtName">{{
                errors.districtName
              }}</span>
            </div>
            <div class="form-group">
              <label for="organizationType">Type</label>
              <select
                v-model="organization.organizationType"
                id="organizationType"
                name="organizationType"
                required
                :class="{ error: errors.organizationType }"
              >
                <option value="">Select Type</option>
                <option value="Elementary">Elementary School</option>
                <option value="Middle">Middle School</option>
                <option value="High">High School</option>
                <option value="Charter">Charter School</option>
                <option value="Private">Private School</option>
              </select>
            </div>
            <div class="form-group">
              <label for="organizationCode">School Code</label>
              <input
                v-model="organization.organizationCode"
                id="organizationCode"
                name="organizationCode"
                required
                :class="{ error: errors.organizationCode }"
              />
            </div>
            <div class="form-group">
              <label class="checkbox-label">
                <input
                  type="checkbox"
                  v-model="organization.isActive"
                  id="isActive"
                />
                <span>Active School</span>
              </label>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>School Hours</h3>
          <div class="form-grid">
            <div class="form-group">
              <label>Default Start Time</label>
              <input
                type="time"
                v-model="organization.defaultStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Default End Time</label>
              <input
                type="time"
                v-model="organization.defaultEndTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Early Dismissal Start Time</label>
              <input
                type="time"
                v-model="organization.earlyDismissalStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Early Dismissal End Time</label>
              <input
                type="time"
                v-model="organization.earlyDismissalEndTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Emergency Start Time</label>
              <input
                type="time"
                v-model="organization.emergencyStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Emergency End Time</label>
              <input
                type="time"
                v-model="organization.emergencyEndTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Shortened Day Start Time</label>
              <input
                type="time"
                v-model="organization.shortenedDayStartTime"
                step="300"
              />
            </div>
            <div class="form-group">
              <label>Shortened Day End Time</label>
              <input
                type="time"
                v-model="organization.shortenedDayEndTime"
                step="300"
              />
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>Contact Information</h3>
          <div class="form-grid">
            <div class="form-group full-width">
              <label for="address">Street Address</label>
              <div class="address-input-container">
                <input
                  ref="addressInput"
                  v-model="organization.address"
                  id="address"
                  name="address"
                  required
                  :class="{ error: errors.address }"
                  placeholder="Start typing address..."
                  class="address-input"
                />
                <div class="address-suggestions" v-if="autocomplete"></div>
              </div>
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input
                v-model="organization.city"
                id="city"
                name="city"
                required
                :class="{ error: errors.city }"
              />
            </div>
            <div class="form-group">
              <label for="state">State</label>
              <select
                v-model="organization.state"
                id="state"
                name="state"
                required
                :class="{ error: errors.state }"
              >
                <option value="">Select State</option>
                <option v-for="state in states" :key="state" :value="state">
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="zipcode">ZIP Code</label>
              <input
                v-model="organization.zipcode"
                id="zipcode"
                name="zipcode"
                required
                pattern="[0-9]{5}"
                maxlength="5"
                :class="{ error: errors.zipcode }"
              />
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <input
                v-model="organization.phone"
                id="phone"
                name="phone"
                type="tel"
                required
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="123-456-7890"
                :class="{ error: errors.phone }"
              />
            </div>
            <div class="form-group">
              <label for="website">Website</label>
              <input
                v-model="organization.website"
                id="website"
                type="url"
                placeholder="https://example.com"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Grades Tab -->
      <div v-show="selectedTab === 'grades'" class="form-step">
        <div class="form-section">
          <h3>Grade Levels</h3>
          <div class="grades-grid">
            <div
              v-for="grade in gradesList"
              :key="grade.key"
              class="grade-item"
            >
              <label :for="grade.key" class="grade-label">
                <input
                  type="checkbox"
                  v-model="organization[grade.key]"
                  :id="grade.key"
                />
                <span class="grade-name">{{ grade.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Location Tab -->
      <div v-show="selectedTab === 'location'" class="form-step">
        <div class="form-section">
          <h3>Geographic Location</h3>
          <div class="form-grid">
            <div class="form-group">
              <label for="latitude">Latitude</label>
              <input
                v-model="organization.latitude"
                id="latitude"
                type="number"
                step="0.000001"
              />
            </div>
            <div class="form-group">
              <label for="longitude">Longitude</label>
              <input
                v-model="organization.longitude"
                id="longitude"
                type="number"
                step="0.000001"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Groups Tab -->
      <div v-show="selectedTab === 'groups'" class="form-step">
        <div class="form-section">
          <h3>Student Groups</h3>
          <div class="groups-container">
            <div
              v-for="(group, index) in organization.groups"
              :key="index"
              class="group-card"
            >
              <div class="group-header">
                <h4>Group {{ index + 1 }}</h4>
                <button
                  type="button"
                  class="remove-group-btn"
                  @click="removeGroup(index)"
                >
                  ×
                </button>
              </div>
              <div class="group-content">
                <div class="form-group">
                  <label :for="'groupName' + index">Group Name</label>
                  <input v-model="group.name" :id="'groupName' + index" />
                </div>
                <div class="form-grid">
                  <div class="form-group">
                    <label :for="'bellTime' + index">Bell Time</label>
                    <input
                      type="time"
                      v-model="group.bellTime"
                      :id="'bellTime' + index"
                    />
                  </div>
                  <div class="form-group">
                    <label :for="'dismissalTime' + index">Dismissal Time</label>
                    <input
                      type="time"
                      v-model="group.dismissalTime"
                      :id="'dismissalTime' + index"
                    />
                  </div>
                </div>
                <div class="form-grid">
                  <div class="form-group">
                    <label :for="'startDate' + index">Start Date</label>
                    <input
                      type="date"
                      v-model="group.startDate"
                      :id="'startDate' + index"
                    />
                  </div>
                  <div class="form-group">
                    <label :for="'endDate' + index">End Date</label>
                    <input
                      type="date"
                      v-model="group.endDate"
                      :id="'endDate' + index"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label :for="'pickupNotes' + index">Pickup Notes</label>
                  <textarea
                    v-model="group.pickupNotes"
                    :id="'pickupNotes' + index"
                    rows="2"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label :for="'dropOffNotes' + index">Drop-off Notes</label>
                  <textarea
                    v-model="group.dropOffNotes"
                    :id="'dropOffNotes' + index"
                    rows="2"
                  ></textarea>
                </div>
              </div>
            </div>
            <button type="button" class="add-group-btn" @click="addGroup">
              + Add New Group
            </button>
          </div>
        </div>
      </div>

      <!-- Form Navigation -->
      <div class="form-navigation">
        <button
          type="button"
          class="secondary-button"
          @click="previousTab"
          v-if="!isFirstTab"
        >
          <span class="button-icon">←</span> Previous
        </button>
        <button
          type="button"
          class="primary-button"
          @click="nextTab"
          v-if="!isLastTab"
        >
          Next <span class="button-icon">→</span>
        </button>
        <button type="submit" class="submit-button" v-if="isLastTab">
          Create School
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config";
import { Loader } from "@googlemaps/js-api-loader";

// Declare Google Maps types
declare global {
  interface Window {
    google: typeof google;
  }
}

interface Group {
  name: string;
  pickupNotes: string;
  dropOffNotes: string;
  bellTime: string;
  dismissalTime: string;
  startDate: string;
  endDate: string;
}

interface Organization {
  id: number;
  districtName: string;
  name: string;
  organizationType: string;
  organizationCode: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  website: string;
  defaultStartTime: string;
  defaultEndTime: string;
  earlyDismissalStartTime: string;
  earlyDismissalEndTime: string;
  emergencyStartTime: string;
  emergencyEndTime: string;
  shortenedDayStartTime: string;
  shortenedDayEndTime: string;
  isActive: boolean;
  prekindergarten: number;
  kindergarten: number;
  grade1: number;
  grade2: number;
  grade3: number;
  grade4: number;
  grade5: number;
  grade6: number;
  grade7: number;
  grade8: number;
  grade9: number;
  grade10: number;
  grade11: number;
  grade12: number;
  latitude: string;
  longitude: string;
  groups: Group[];
}

interface GradeOption {
  key: keyof Organization;
  label: string;
}

interface District {
  id: number;
  name: string;
}

const router = useRouter();
const googleMapsApiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
const addressInput = ref<HTMLInputElement | null>(null);
const autocomplete = ref<google.maps.places.Autocomplete | null>(null);
const districts = ref<District[]>([]);
const districtSearch = ref("");
const showDistrictDropdown = ref(false);

const organization = ref<Organization>({
  id: 0,
  districtName: "",
  name: "",
  organizationType: "",
  organizationCode: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  phone: "",
  website: "",
  defaultStartTime: "",
  defaultEndTime: "",
  earlyDismissalStartTime: "",
  earlyDismissalEndTime: "",
  emergencyStartTime: "",
  emergencyEndTime: "",
  shortenedDayStartTime: "",
  shortenedDayEndTime: "",
  isActive: true,
  prekindergarten: 0,
  kindergarten: 0,
  grade1: 0,
  grade2: 0,
  grade3: 0,
  grade4: 0,
  grade5: 0,
  grade6: 0,
  grade7: 0,
  grade8: 0,
  grade9: 0,
  grade10: 0,
  grade11: 0,
  grade12: 0,
  latitude: "0",
  longitude: "0",
  groups: [],
});

const errors = ref({
  name: "",
  city: "",
  districtName: "",
  organizationType: "",
  organizationCode: "",
  address: "",
  state: "",
  zipcode: "",
  phone: "",
});

const selectedTab = ref("general");

const tabs = [
  { id: "general", number: 1, label: "General Info" },
  { id: "grades", number: 2, label: "Grades" },
  { id: "location", number: 3, label: "Location" },
  { id: "groups", number: 4, label: "Groups" },
];

const gradesList: GradeOption[] = [
  { key: "prekindergarten", label: "Pre-K" },
  { key: "kindergarten", label: "Kindergarten" },
  { key: "grade1", label: "Grade 1" },
  { key: "grade2", label: "Grade 2" },
  { key: "grade3", label: "Grade 3" },
  { key: "grade4", label: "Grade 4" },
  { key: "grade5", label: "Grade 5" },
  { key: "grade6", label: "Grade 6" },
  { key: "grade7", label: "Grade 7" },
  { key: "grade8", label: "Grade 8" },
  { key: "grade9", label: "Grade 9" },
  { key: "grade10", label: "Grade 10" },
  { key: "grade11", label: "Grade 11" },
  { key: "grade12", label: "Grade 12" },
];

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

// Add computed property for filtered and sorted districts
const filteredDistricts = computed(() => {
  return districts.value
    .filter((district) =>
      district.name.toLowerCase().includes(districtSearch.value.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));
});

// Add method to handle district selection
const selectDistrict = (district: District) => {
  organization.value.districtName = district.name;
  showDistrictDropdown.value = false;
  districtSearch.value = "";
  errors.value.districtName = "";
};

// Add method to toggle district dropdown
const toggleDistrictDropdown = () => {
  showDistrictDropdown.value = !showDistrictDropdown.value;
};

const fetchDistricts = async () => {
  try {
    const response = await fetch(`${API_URL}/districts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      districts.value = await response.json();
    } else {
      console.error("Failed to fetch districts:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
  }
};

onMounted(async () => {
  await fetchDistricts();

  // Add click outside handler for district dropdown
  document.addEventListener("click", (e) => {
    const target = e.target as HTMLElement;
    if (!target.closest(".district-select-container")) {
      showDistrictDropdown.value = false;
    }
  });

  if (googleMapsApiKey) {
    const loader = new Loader({
      apiKey: googleMapsApiKey,
      version: "weekly",
      libraries: ["places"],
    });

    try {
      await loader.load();
      if (addressInput.value) {
        autocomplete.value = new google.maps.places.Autocomplete(
          addressInput.value,
          {
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry", "formatted_address"],
          }
        );

        autocomplete.value.addListener("place_changed", () => {
          const place = autocomplete.value?.getPlace();
          if (place && place.geometry) {
            // Update address fields
            const addressComponents = place.address_components || [];
            const streetNumber =
              addressComponents.find((c) => c.types.includes("street_number"))
                ?.long_name || "";
            const route =
              addressComponents.find((c) => c.types.includes("route"))
                ?.long_name || "";
            organization.value.address = `${streetNumber} ${route}`.trim();

            organization.value.city =
              addressComponents.find((c) => c.types.includes("locality"))
                ?.long_name || "";
            organization.value.state =
              addressComponents.find((c) =>
                c.types.includes("administrative_area_level_1")
              )?.short_name || "";
            organization.value.zipcode =
              addressComponents.find((c) => c.types.includes("postal_code"))
                ?.long_name || "";

            // Update coordinates
            const location = place.geometry?.location;
            if (location) {
              organization.value.latitude = location.lat().toString();
              organization.value.longitude = location.lng().toString();
            }
          }
        });
      }
    } catch (error) {
      console.error("Error loading Google Maps:", error);
    }
  }
});

const createOrganization = async () => {
  if (!validateForm()) {
    selectedTab.value = "general";
    return;
  }

  try {
    const response = await fetch(`${API_URL}/organizations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(organization.value),
    });

    if (response.ok) {
      const newOrganization = await response.json();
      router.push(`/organizations/${newOrganization.id}`);
    } else {
      const error = await response.json();
      alert(
        `Failed to create organization: ${error.message || response.statusText}`
      );
    }
  } catch (error) {
    console.error("Error creating organization:", error);
    alert("An error occurred while creating the organization.");
  }
};

const addGroup = () => {
  organization.value.groups.push({
    name: "",
    pickupNotes: "",
    dropOffNotes: "",
    bellTime: "",
    dismissalTime: "",
    startDate: "",
    endDate: "",
  });
};

const removeGroup = (index: number) => {
  organization.value.groups.splice(index, 1);
};

const currentTabIndex = computed(() => {
  return tabs.findIndex((tab) => tab.id === selectedTab.value);
});

const isFirstTab = computed(() => currentTabIndex.value === 0);
const isLastTab = computed(() => currentTabIndex.value === tabs.length - 1);

const previousTab = () => {
  if (!isFirstTab.value) {
    selectedTab.value = tabs[currentTabIndex.value - 1].id;
  }
};

const nextTab = () => {
  if (!isLastTab.value) {
    selectedTab.value = tabs[currentTabIndex.value + 1].id;
  }
};

const validateForm = () => {
  let isValid = true;
  errors.value = {
    name: "",
    city: "",
    districtName: "",
    organizationType: "",
    organizationCode: "",
    address: "",
    state: "",
    zipcode: "",
    phone: "",
  };

  // Required fields validation
  if (!organization.value.name.trim()) {
    errors.value.name = "School name is required";
    isValid = false;
  }

  if (!organization.value.city.trim()) {
    errors.value.city = "City is required";
    isValid = false;
  }

  if (!organization.value.districtName) {
    errors.value.districtName = "District is required";
    isValid = false;
  }

  if (!organization.value.organizationType) {
    errors.value.organizationType = "Organization type is required";
    isValid = false;
  }

  if (!organization.value.organizationCode.trim()) {
    errors.value.organizationCode = "Organization code is required";
    isValid = false;
  }

  if (!organization.value.address.trim()) {
    errors.value.address = "Address is required";
    isValid = false;
  }

  if (!organization.value.state) {
    errors.value.state = "State is required";
    isValid = false;
  }

  if (!organization.value.zipcode.trim()) {
    errors.value.zipcode = "ZIP code is required";
    isValid = false;
  }

  if (!organization.value.phone.trim()) {
    errors.value.phone = "Phone number is required";
    isValid = false;
  }

  // Format validation
  if (
    organization.value.phone &&
    !/^\d{3}-\d{3}-\d{4}$/.test(organization.value.phone)
  ) {
    errors.value.phone = "Phone number must be in format: 123-456-7890";
    isValid = false;
  }

  if (
    organization.value.zipcode &&
    !/^\d{5}$/.test(organization.value.zipcode)
  ) {
    errors.value.zipcode = "ZIP code must be 5 digits";
    isValid = false;
  }

  return isValid;
};

const isTabComplete = (tabId: string) => {
  switch (tabId) {
    case "general":
      return !!organization.value.name && !!organization.value.city;
    case "grades":
      return true;
    case "location":
      return true;
    case "groups":
      return true;
    default:
      return false;
  }
};
</script>

<style scoped>
.add-organization-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.breadcrumb {
  margin-bottom: 1.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e9ecef;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s;
}

.breadcrumb a:hover {
  color: #0056b3;
}

.breadcrumb span {
  color: #6c757d;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  color: #2c3e50;
  font-size: 2rem;
  font-weight: 600;
}

/* Steps Indicator */
.steps-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.step {
  flex: 1;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.step:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  height: 2px;
  background: #dee2e6;
  transform: translateY(-50%);
}

.step.active::after {
  background: #007bff;
}

.step-number {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #dee2e6;
  margin: 0 auto 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.step.active .step-number {
  background: #007bff;
  border-color: #007bff;
  color: #fff;
}

.step.completed .step-number {
  background: #28a745;
  border-color: #28a745;
  color: #fff;
}

.step-label {
  font-size: 0.9rem;
  color: #6c757d;
  transition: color 0.3s ease;
}

.step.active .step-label {
  color: #007bff;
  font-weight: 500;
}

.step.completed .step-label {
  color: #28a745;
}

/* Form Sections */
.form-section {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.form-section h3 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #e9ecef;
  font-size: 1.25rem;
  font-weight: 600;
}

/* Form Grid */
.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #495057;
  font-size: 0.95rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  background-color: #fff;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15);
}

.form-group input.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Grades Grid */
.grades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.grade-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s ease;
}

.grade-item:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
}

.grade-label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
}

.grade-name {
  font-size: 0.95rem;
  color: #495057;
}

/* Groups */
.groups-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.group-card {
  background: #fff;
  border: 1px solid #e9ecef;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s ease;
}

.group-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.group-header {
  background: #f8f9fa;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
}

.group-header h4 {
  margin: 0;
  color: #495057;
  font-size: 1.1rem;
  font-weight: 600;
}

.group-content {
  padding: 1.5rem;
}

.remove-group-btn {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
  transition: color 0.2s;
}

.remove-group-btn:hover {
  color: #c82333;
}

.add-group-btn {
  width: 100%;
  padding: 1.25rem;
  background: #f8f9fa;
  border: 2px dashed #dee2e6;
  border-radius: 12px;
  color: #6c757d;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.add-group-btn:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

/* Form Navigation */
.form-navigation {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e9ecef;
}

.form-navigation button {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.secondary-button {
  background-color: #6c757d;
  color: #fff;
  border: none;
}

.secondary-button:hover {
  background-color: #5a6268;
  transform: translateY(-1px);
}

.primary-button {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.primary-button:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.submit-button:hover {
  background-color: #218838;
  transform: translateY(-1px);
}

/* Address Input */
.address-input-container {
  position: relative;
  width: 100%;
}

.address-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.address-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15);
}

/* Google Places Autocomplete */
.pac-container {
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.pac-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pac-item:hover {
  background-color: #f8f9fa;
}

.pac-item-selected {
  background-color: #e9ecef;
}

/* Responsive Design */
@media (max-width: 768px) {
  .add-organization-container {
    padding: 1rem;
  }

  .steps-indicator {
    flex-direction: column;
    gap: 1rem;
  }

  .step:not(:last-child)::after {
    display: none;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .form-section {
    padding: 1rem;
  }

  .form-navigation {
    flex-direction: column;
  }

  .form-navigation button {
    width: 100%;
    justify-content: center;
  }
}

/* District Select Styles */
.district-select-container {
  position: relative;
  width: 100%;
}

.district-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.district-search-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  background-color: #fff;
}

.district-search-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15);
}

.district-dropdown-toggle {
  position: absolute;
  right: 1rem;
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
  font-size: 0.8rem;
  transition: transform 0.2s ease;
}

.district-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.district-option {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid #f8f9fa;
}

.district-option:last-child {
  border-bottom: none;
}

.district-option:hover {
  background-color: #f8f9fa;
  color: #007bff;
}

/* Checkbox Label Styles */
.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.checkbox-label input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.checkbox-label span {
  font-size: 0.95rem;
  color: #495057;
}

.district-search-input.has-value {
  color: #2c3e50;
  font-weight: 500;
}

.district-option.selected {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
}

.selected-district-badge {
  position: absolute;
  top: -8px;
  left: 8px;
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 1;
}

.district-select-container {
  position: relative;
  margin-top: 8px;
}
</style>
