<template>
  <div class="import-ics-container">
    <div class="import-options">
      <div class="option-tabs">
        <button
          :class="['tab-button', { active: importMethod === 'file' }]"
          @click="importMethod = 'file'"
        >
          Upload File
        </button>
        <button
          :class="['tab-button', { active: importMethod === 'url' }]"
          @click="importMethod = 'url'"
        >
          Import from URL
        </button>
      </div>

      <div v-if="importMethod === 'file'" class="file-upload">
        <div
          class="upload-area"
          @dragover.prevent
          @drop.prevent="handleFileDrop"
          :class="{ 'has-file': selectedFile }"
        >
          <input
            type="file"
            ref="fileInput"
            accept=".ics"
            @change="handleFileSelect"
            class="file-input"
            style="display: none"
          />
          <div v-if="!selectedFile" class="upload-prompt">
            <font-awesome-icon icon="file-upload" class="upload-icon" />
            <p>Drag and drop your .ics file here</p>
            <p>or</p>
            <button class="btn-browse" @click="fileInput?.click()">
              Browse Files
            </button>
            <p class="file-type">Supported format: .ics</p>
          </div>
          <div v-else class="file-info">
            <font-awesome-icon icon="file-alt" class="file-icon" />
            <p>{{ selectedFile.name }}</p>
            <button class="btn-remove" @click="removeFile">
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </div>
      </div>

      <div v-else class="url-import">
        <div class="input-group">
          <input
            type="url"
            v-model="icsUrl"
            placeholder="Enter ICS file URL"
            class="url-input"
          />
          <button
            class="btn-fetch"
            @click="fetchFromUrl"
            :disabled="!icsUrl || isFetching"
          >
            <span v-if="isFetching">Fetching...</span>
            <span v-else>Fetch</span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="previewEvents.length > 0" class="preview-section">
      <h3>Preview Events</h3>
      <div class="import-options">
        <div class="checkbox-group">
          <label>
            <input type="checkbox" v-model="importOptions.skipExisting" />
            Skip existing events
          </label>
          <label>
            <input type="checkbox" v-model="importOptions.updateExisting" />
            Update existing events
          </label>
        </div>
      </div>
      <div class="events-list">
        <div v-for="event in previewEvents" :key="event.uid" class="event-item">
          <div class="event-checkbox">
            <input
              type="checkbox"
              v-model="selectedEvents"
              :value="event.uid"
            />
          </div>
          <div class="event-details">
            <h4>{{ event.title }}</h4>
            <p class="event-date">
              {{ formatDate(event.startDate) }}
              <span v-if="event.endDate && event.endDate !== event.startDate">
                - {{ formatDate(event.endDate) }}
              </span>
            </p>
            <p v-if="event.description" class="event-description">
              {{ event.description }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <div class="actions">
      <button class="btn-cancel" @click="$emit('close')">Cancel</button>
      <button class="btn-import" @click="confirmImport" :disabled="!canImport">
        Import Selected Events
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, defineEmits } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFileUpload,
  faFileAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL } from "../config";

// Register Font Awesome icons
library.add(faFileUpload, faFileAlt, faTimes);

const props = defineProps<{
  calendarId: number;
}>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "import-complete"): void;
}>();

// State
const importMethod = ref<"file" | "url">("file");
const selectedFile = ref<File | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);
const icsUrl = ref("");
const previewEvents = ref<any[]>([]);
const selectedEvents = ref<string[]>([]);
const error = ref("");
const isFetching = ref(false);

const importOptions = ref({
  skipExisting: false,
  updateExisting: false,
});

// Computed
const canImport = computed(() => {
  return selectedEvents.value.length > 0;
});

// Methods
const handleFileSelect = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files[0]) {
    selectedFile.value = input.files[0];
    processFile(input.files[0]);
  }
};

const handleFileDrop = (event: DragEvent) => {
  if (event.dataTransfer?.files && event.dataTransfer.files[0]) {
    selectedFile.value = event.dataTransfer.files[0];
    processFile(event.dataTransfer.files[0]);
  }
};

const removeFile = () => {
  selectedFile.value = null;
  previewEvents.value = [];
  selectedEvents.value = [];
};

const processFile = async (file: File) => {
  if (!file.name.endsWith(".ics")) {
    error.value = "Please select a valid .ics file";
    return;
  }

  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      `${API_URL}/calendar/${props.calendarId}/events/import/preview`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    previewEvents.value = response.data;
    selectedEvents.value = response.data.map((event: any) => event.uid);
  } catch (err: any) {
    error.value = err.response?.data?.message || "Error processing file";
  }
};

const fetchFromUrl = async () => {
  if (!icsUrl.value) return;

  isFetching.value = true;
  error.value = "";

  try {
    const response = await axios.post(
      `${API_URL}/calendar/${props.calendarId}/events/import/preview`,
      { icsUrl: icsUrl.value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    previewEvents.value = response.data;
    selectedEvents.value = response.data.map((event: any) => event.uid);
  } catch (err: any) {
    error.value =
      err.response?.data?.message || "Error fetching ICS file from URL";
    previewEvents.value = [];
    selectedEvents.value = [];
  } finally {
    isFetching.value = false;
  }
};

const confirmImport = async () => {
  try {
    const eventsToImport = previewEvents.value.filter((event) =>
      selectedEvents.value.includes(event.uid)
    );

    await axios.post(
      `${API_URL}/calendar/${props.calendarId}/events/import/confirm`,
      {
        events: eventsToImport,
        importOptions: importOptions.value,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    emit("import-complete");
    emit("close");
  } catch (err: any) {
    error.value = err.response?.data?.message || "Error importing events";
  }
};

const formatDate = (dateString: string): string => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });
};
</script>

<style scoped>
.import-ics-container {
  padding: 20px;
}

.import-options {
  margin-bottom: 20px;
}

.option-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  background: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
}

.tab-button.active {
  background-color: #4caf50;
  color: white;
  border-color: #4caf50;
}

.file-upload {
  margin-bottom: 20px;
}

.upload-area {
  border: 2px dashed #ddd;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-area.has-file {
  border-color: #4caf50;
  background-color: #f8f9fa;
}

.upload-prompt {
  color: #666;
}

.upload-icon {
  font-size: 48px;
  color: #999;
  margin-bottom: 10px;
}

.btn-browse {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
}

.file-type {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

.file-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.file-icon {
  font-size: 24px;
  color: #4caf50;
}

.btn-remove {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 5px;
}

.url-import {
  display: flex;
  gap: 10px;
}

.url-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn-fetch {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-fetch:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.preview-section {
  margin-top: 20px;
}

.checkbox-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.events-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.event-item {
  display: flex;
  gap: 15px;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.event-item:last-child {
  border-bottom: none;
}

.event-checkbox {
  display: flex;
  align-items: center;
}

.event-details {
  flex: 1;
}

.event-details h4 {
  margin: 0 0 5px 0;
  color: #2c3e50;
}

.event-date {
  color: #666;
  font-size: 14px;
  margin: 0 0 5px 0;
}

.event-description {
  color: #666;
  font-size: 14px;
  margin: 0;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.btn-cancel {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-import {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-import:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
