<template>
  <div class="attachments-container">
    <div class="header-actions">
      <h3>Calendar Attachments</h3>
      <button class="btn-upload" @click="openUploadModal">
        <font-awesome-icon icon="upload" /> Upload Attachment
      </button>
    </div>

    <div v-if="loading" class="loading">
      <p>Loading attachments...</p>
    </div>

    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-else-if="attachments.length === 0" class="no-data">
      <p>No attachments found for this calendar.</p>
    </div>

    <div v-else class="attachments-list">
      <div
        v-for="attachment in attachments"
        :key="attachment.id"
        class="attachment-card"
      >
        <div class="attachment-icon">
          <font-awesome-icon
            v-if="attachment.type === 'pdf'"
            icon="file-pdf"
            size="2x"
          />
          <font-awesome-icon
            v-else-if="attachment.type === 'document'"
            icon="file-word"
            size="2x"
          />
          <font-awesome-icon
            v-else-if="attachment.type === 'image'"
            icon="image"
            size="2x"
          />
          <font-awesome-icon v-else icon="file" size="2x" />
        </div>

        <div class="attachment-details">
          <h4>{{ attachment.name || attachment.originalFilename }}</h4>
          <p v-if="attachment.description" class="attachment-description">
            {{ attachment.description }}
          </p>
          <div
            v-if="attachment.startYear || attachment.endYear"
            class="attachment-years"
          >
            <span v-if="attachment.startYear"
              >From: {{ attachment.startYear }}</span
            >
            <span v-if="attachment.endYear">To: {{ attachment.endYear }}</span>
          </div>
          <div class="attachment-meta">
            <span class="file-size">{{ formatFileSize(attachment.size) }}</span>
            <span class="file-type">{{ attachment.type }}</span>
            <span class="upload-date">{{
              formatDate(attachment.createdAt)
            }}</span>
          </div>
        </div>

        <div class="attachment-preview" v-if="attachment.type === 'image'">
          <img
            :src="attachmentPreviewUrls[attachment.id] || ''"
            alt="Attachment preview"
          />
        </div>

        <div class="attachment-actions">
          <button class="btn-preview" @click="openPreview(attachment)">
            <font-awesome-icon icon="eye" /> Preview
          </button>
          <button
            class="btn-download"
            @click="downloadAttachment(attachment.id)"
          >
            <font-awesome-icon icon="download" /> Download
          </button>
          <button class="btn-delete" @click="confirmDelete(attachment)">
            <font-awesome-icon icon="trash" /> Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Upload Modal -->
    <div v-if="showUploadModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Upload Attachment</h3>
        <form @submit.prevent="handleUpload" class="upload-form">
          <div class="form-group">
            <label for="file">File *</label>
            <input
              type="file"
              id="file"
              ref="fileInput"
              @change="handleFileSelect"
              required
            />
            <small class="form-help"
              >Supported formats: PDF, Images, Documents</small
            >
          </div>

          <div class="form-group">
            <label for="name">Display Name</label>
            <input
              type="text"
              id="name"
              v-model="uploadForm.name"
              placeholder="Enter a display name for the attachment"
            />
            <small class="form-help"
              >Leave blank to use the original filename</small
            >
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              id="description"
              v-model="uploadForm.description"
              placeholder="Enter a description for the attachment"
              rows="3"
            ></textarea>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label for="startYear">Start Year</label>
              <input
                type="number"
                id="startYear"
                v-model="uploadForm.startYear"
                placeholder="e.g., 2024"
                min="2000"
                max="2100"
              />
            </div>

            <div class="form-group">
              <label for="endYear">End Year</label>
              <input
                type="number"
                id="endYear"
                v-model="uploadForm.endYear"
                placeholder="e.g., 2025"
                min="2000"
                max="2100"
              />
            </div>
          </div>

          <div class="modal-actions">
            <button type="button" class="btn-cancel" @click="closeUploadModal">
              Cancel
            </button>
            <button type="submit" class="btn-submit" :disabled="isUploading">
              <span v-if="isUploading">Uploading...</span>
              <span v-else>Upload</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Confirm Delete</h3>
        <p>
          Are you sure you want to delete the attachment "{{
            attachmentToDelete?.originalFilename
          }}"?
        </p>

        <div class="modal-actions">
          <button class="btn-cancel" @click="showDeleteModal = false">
            Cancel
          </button>
          <button class="btn-confirm-delete" @click="deleteAttachment">
            Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Preview Modal -->
    <div v-if="showPreviewModal" class="modal-overlay">
      <div class="modal-content preview-modal">
        <div class="preview-header">
          <h3>
            {{
              selectedAttachment?.name || selectedAttachment?.originalFilename
            }}
          </h3>
          <button class="btn-close" @click="closePreview">
            <font-awesome-icon icon="times" />
          </button>
        </div>

        <div class="preview-content">
          <!-- PDF Preview -->
          <iframe
            v-if="selectedAttachment?.type === 'pdf'"
            :src="selectedAttachment?.previewUrl"
            class="pdf-preview"
          ></iframe>

          <!-- Image Preview -->
          <img
            v-else-if="selectedAttachment?.type === 'image'"
            :src="selectedAttachment?.previewUrl"
            :alt="selectedAttachment?.name"
            class="image-preview"
          />

          <!-- Document Preview -->
          <div
            v-else-if="selectedAttachment?.type === 'document'"
            class="document-preview"
          >
            <div class="document-icon">
              <font-awesome-icon icon="file-word" size="3x" />
            </div>
            <p>Document preview is not available. Please download to view.</p>
          </div>

          <!-- Other File Types -->
          <div v-else class="other-preview">
            <div class="file-icon">
              <font-awesome-icon icon="file" size="3x" />
            </div>
            <p>
              Preview is not available for this file type. Please download to
              view.
            </p>
          </div>
        </div>

        <div class="preview-footer">
          <button
            class="btn-download"
            @click="downloadAttachment(selectedAttachment?.id)"
          >
            <font-awesome-icon icon="download" /> Download
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps, defineEmits, defineExpose } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { API_URL } from "../config";

enum AttachmentType {
  PDF = "pdf",
  IMAGE = "image",
  DOCUMENT = "document",
  OTHER = "other",
}

interface CalendarAttachment {
  id: number;
  name?: string;
  startYear?: number;
  endYear?: number;
  filename: string;
  originalFilename: string;
  type: AttachmentType;
  mimeType: string;
  size: number;
  description?: string;
  createdAt: string;
  updatedAt: string;
  previewUrl?: string;
}

interface UploadForm {
  name: string;
  description: string;
  startYear: string;
  endYear: string;
  file: File | null;
}

const props = defineProps<{
  calendarId: number;
}>();

const emit = defineEmits(["attachment-deleted"]);

// State
const attachments = ref<CalendarAttachment[]>([]);
const loading = ref(true);
const error = ref("");
const showDeleteModal = ref(false);
const attachmentToDelete = ref<CalendarAttachment | null>(null);
const showUploadModal = ref(false);
const isUploading = ref(false);
const fileInput = ref<HTMLInputElement | null>(null);

// Upload form state
const uploadForm = ref<UploadForm>({
  name: "",
  description: "",
  startYear: "",
  endYear: "",
  file: null,
});

// Add new state for preview modal
const showPreviewModal = ref(false);
const selectedAttachment = ref<CalendarAttachment | null>(null);

// Add new state for attachment preview URLs
const attachmentPreviewUrls = ref<{ [key: number]: string }>({});

// Fetch attachments
const fetchAttachments = async () => {
  loading.value = true;
  try {
    const response = await axios.get(
      `${API_URL}/calendar/${props.calendarId}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    attachments.value = response.data;
  } catch (err) {
    error.value =
      err instanceof Error ? err.message : "Failed to fetch attachments";
    console.error("Error fetching attachments:", err);
  } finally {
    loading.value = false;
  }
};

// Format file size
const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

// Format date
const formatDate = (dateString: string): string => {
  if (!dateString) return "";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "";

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

// Get attachment URL for images and previews
const getAttachmentUrl = async (attachmentId: number): Promise<string> => {
  // Return cached URL if available
  if (attachmentPreviewUrls.value[attachmentId]) {
    return attachmentPreviewUrls.value[attachmentId];
  }

  try {
    const response = await axios.get(
      `${API_URL}/attachments/${attachmentId}/download`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      }
    );

    // Create a blob URL from the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = URL.createObjectURL(blob);

    // Cache the URL
    attachmentPreviewUrls.value[attachmentId] = url;
    return url;
  } catch (error) {
    console.error("Error getting attachment URL:", error);
    return "";
  }
};

// Download attachment
const downloadAttachment = (attachmentId: number) => {
  window.open(`${API_URL}/attachments/${attachmentId}/download`, "_blank");
};

// Confirm delete
const confirmDelete = (attachment: CalendarAttachment) => {
  attachmentToDelete.value = attachment;
  showDeleteModal.value = true;
};

// Delete attachment
const deleteAttachment = async () => {
  if (!attachmentToDelete.value) return;

  try {
    await axios.delete(
      `${API_URL}/attachments/${attachmentToDelete.value.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    // Remove from local list
    const attachmentId = attachmentToDelete.value.id;
    attachments.value = attachments.value.filter((a) => a.id !== attachmentId);

    // Close modal
    showDeleteModal.value = false;
    attachmentToDelete.value = null;

    // Emit event
    emit("attachment-deleted");
  } catch (err) {
    error.value =
      err instanceof Error ? err.message : "Failed to delete attachment";
    console.error("Error deleting attachment:", err);
  }
};

// Upload modal functions
const openUploadModal = () => {
  showUploadModal.value = true;
  uploadForm.value = {
    name: "",
    description: "",
    startYear: "",
    endYear: "",
    file: null,
  };
};

const closeUploadModal = () => {
  showUploadModal.value = false;
  if (fileInput.value) {
    fileInput.value.value = "";
  }
};

const handleFileSelect = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files[0]) {
    uploadForm.value.file = input.files[0];
  }
};

const handleUpload = async () => {
  if (!uploadForm.value.file) {
    error.value = "Please select a file to upload";
    return;
  }

  isUploading.value = true;
  error.value = "";

  try {
    const formData = new FormData();
    formData.append("file", uploadForm.value.file);
    formData.append("name", uploadForm.value.name);
    formData.append("description", uploadForm.value.description);
    if (uploadForm.value.startYear) {
      formData.append("startYear", uploadForm.value.startYear);
    }
    if (uploadForm.value.endYear) {
      formData.append("endYear", uploadForm.value.endYear);
    }

    await axios.post(
      `${API_URL}/calendar/${props.calendarId}/attachments`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Refresh attachments list
    await fetchAttachments();
    closeUploadModal();
  } catch (err) {
    error.value =
      err instanceof Error ? err.message : "Failed to upload attachment";
    console.error("Error uploading attachment:", err);
  } finally {
    isUploading.value = false;
  }
};

// Refresh attachments
const refreshAttachments = () => {
  fetchAttachments();
};

// Expose methods to parent component
defineExpose({
  refreshAttachments,
});

// Lifecycle hooks
onMounted(() => {
  fetchAttachments();
});

// Update the preview functions
const openPreview = async (attachment: CalendarAttachment) => {
  try {
    const url = await getAttachmentUrl(attachment.id);
    if (url) {
      selectedAttachment.value = { ...attachment, previewUrl: url };
      showPreviewModal.value = true;
    }
  } catch (error) {
    console.error("Error opening preview:", error);
  }
};

const closePreview = () => {
  if (selectedAttachment.value?.previewUrl) {
    URL.revokeObjectURL(selectedAttachment.value.previewUrl);
  }
  showPreviewModal.value = false;
  selectedAttachment.value = null;
};
</script>

<style scoped>
.attachments-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #2c3e50;
}

.loading,
.no-data {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.attachments-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.attachment-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.attachment-icon {
  margin-right: 15px;
  color: #3498db;
}

.attachment-details {
  flex: 1;
  min-width: 200px;
}

.attachment-details h4 {
  margin: 0 0 8px 0;
  color: #2c3e50;
  font-size: 16px;
}

.attachment-description {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
}

.attachment-years {
  display: flex;
  gap: 15px;
  margin: 8px 0;
  color: #2c3e50;
  font-weight: 500;
}

.attachment-meta {
  display: flex;
  gap: 15px;
  font-size: 12px;
  color: #777;
  margin-top: 8px;
}

.file-size,
.file-type,
.upload-date {
  display: flex;
  align-items: center;
  gap: 5px;
}

.file-type {
  text-transform: capitalize;
}

.attachment-preview {
  width: 100%;
  margin: 10px 0;
}

.attachment-preview img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.attachment-actions {
  display: flex;
  gap: 10px;
  margin-left: auto;
  flex-wrap: wrap;
}

.btn-preview,
.btn-download,
.btn-delete {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-preview {
  background-color: #3498db;
  color: white;
}

.btn-preview:hover {
  background-color: #2980b9;
}

.btn-download {
  background-color: #3498db;
  color: white;
}

.btn-download:hover {
  background-color: #2980b9;
}

.btn-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-delete:hover {
  background-color: #c0392b;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.btn-cancel,
.btn-confirm-delete {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #333;
}

.btn-cancel:hover {
  background-color: #ddd;
}

.btn-confirm-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-confirm-delete:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .attachment-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .attachment-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .attachment-actions {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;
  }
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-upload {
  padding: 8px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.btn-upload:hover {
  background-color: #45a049;
}

.upload-form {
  margin-top: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #2c3e50;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
  min-height: 80px;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-row .form-group {
  flex: 1;
}

.form-help {
  display: block;
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.btn-submit {
  background-color: #4caf50;
  color: white;
}

.btn-submit:hover:not(:disabled) {
  background-color: #45a049;
}

.btn-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Preview Modal Styles */
.preview-modal {
  max-width: 90vw;
  max-height: 90vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.preview-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #2c3e50;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #666;
  cursor: pointer;
  padding: 5px;
}

.btn-close:hover {
  color: #333;
}

.preview-content {
  flex: 1;
  overflow: auto;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-preview {
  width: 100%;
  height: 100%;
  border: none;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.document-preview,
.other-preview {
  text-align: center;
  color: #666;
}

.document-icon,
.file-icon {
  margin-bottom: 15px;
  color: #3498db;
}

.preview-footer {
  padding-top: 15px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.btn-preview {
  padding: 8px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-preview:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .preview-modal {
    max-width: 100vw;
    max-height: 100vh;
  }

  .attachment-actions {
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
