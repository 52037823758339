<template>
  <div class="calendar-container">
    <div class="header-actions">
      <div class="header-content">
        <h1>School Calendars</h1>
        <p class="subtitle">
          Manage and organize your school's academic calendar
        </p>
      </div>
      <router-link to="/calendar/add" class="btn-add">
        <font-awesome-icon icon="plus" /> Add Calendar
      </router-link>
    </div>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-if="loading" class="loading">
      <p>Loading calendars...</p>
    </div>

    <div v-else-if="calendars.length === 0" class="empty-state">
      <div class="empty-state-content">
        <font-awesome-icon icon="calendar" class="empty-state-icon" />
        <h2>No Calendars Found</h2>
        <p>Get started by creating your first school calendar</p>
        <router-link to="/calendar/add" class="btn-add">
          <font-awesome-icon icon="plus" /> Create First Calendar
        </router-link>
      </div>
    </div>

    <div v-else class="calendar-list">
      <div
        v-for="calendar in calendars"
        :key="calendar.id"
        class="calendar-card"
      >
        <div class="calendar-header">
          <h2>{{ calendar.name }}</h2>
          <span class="calendar-status" :class="calendar.status">
            {{ calendar.status }}
          </span>
        </div>

        <div class="calendar-details">
          <p class="calendar-date-range">
            {{ formatDate(calendar.startDate) }} -
            {{ formatDate(calendar.endDate) }}
          </p>
          <p v-if="calendar.description" class="calendar-description">
            {{ calendar.description }}
          </p>
          <p class="calendar-academic-year">
            Academic Year: {{ calendar.academicYear }}
          </p>
        </div>

        <div class="calendar-actions">
          <router-link :to="`/calendar/${calendar.id}`" class="btn-view">
            <font-awesome-icon icon="eye" /> View Details
          </router-link>
          <router-link :to="`/calendar/${calendar.id}/events`" class="btn-view">
            <font-awesome-icon icon="calendar" /> View Events
          </router-link>
          <router-link :to="`/calendar/${calendar.id}/edit`" class="btn-edit">
            <font-awesome-icon icon="edit" /> Edit
          </router-link>
          <button
            v-if="calendar.type === 'school'"
            class="btn-copy"
            @click="openCopyModal(calendar)"
          >
            <font-awesome-icon icon="copy" /> Copy
          </button>
          <button class="btn-delete" @click="confirmDelete(calendar)">
            <font-awesome-icon icon="trash" /> Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Confirm Delete</h3>
        <p>
          Are you sure you want to delete the calendar "{{
            calendarToDelete?.name
          }}"?
        </p>
        <p class="warning">This action cannot be undone.</p>

        <div class="modal-actions">
          <button class="btn-cancel" @click="showDeleteModal = false">
            Cancel
          </button>
          <button class="btn-confirm-delete" @click="deleteCalendar">
            Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Copy Calendar Modal -->
    <div v-if="showCopyCalendarModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Copy Calendar</h3>
        <p>Select schools to copy the calendar to:</p>

        <div v-if="loadingSchools" class="loading">
          Loading available schools...
        </div>

        <div v-else class="school-list">
          <div
            v-for="school in availableSchools"
            :key="school.id"
            class="school-item"
          >
            <input
              type="checkbox"
              :id="'school-' + school.id"
              v-model="selectedSchools"
              :value="school.id"
            />
            <label :for="'school-' + school.id">{{ school.name }}</label>
          </div>
        </div>

        <div v-if="error" class="error-message">
          {{ error }}
        </div>

        <div class="modal-actions">
          <button class="btn-cancel" @click="showCopyCalendarModal = false">
            Cancel
          </button>
          <button
            class="btn-confirm"
            @click="copyCalendar"
            :disabled="selectedSchools.length === 0 || isCopying"
          >
            <span v-if="isCopying">Copying...</span>
            <span v-else>Copy Calendar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { API_URL } from "../config";

// Router
const router = useRouter();

// State
const calendars = ref<any[]>([]);
const loading = ref(true);
const error = ref("");
const showDeleteModal = ref(false);
const calendarToDelete = ref<any>(null);
const showCopyCalendarModal = ref(false);
const calendarToCopy = ref<any>(null);
const availableSchools = ref<any[]>([]);
const loadingSchools = ref(false);
const selectedSchools = ref<number[]>([]);
const isCopying = ref(false);

// Generate academic years (current year - 2 to current year + 5)
const currentYear = new Date().getFullYear();
const academicYears = Array.from({ length: 8 }, (_, i) => currentYear - 2 + i);

// Filters
const filters = reactive({
  type: "",
  academicYear: "",
  status: "active",
});

// Format date function
const formatDate = (dateString: string): string => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Invalid Date";

  // Adjust for timezone offset
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() + userTimezoneOffset);

  return adjustedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

// Functions
const fetchCalendars = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`${API_URL}/calendar`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    // Remove the filter to show all calendars
    calendars.value = response.data;
  } catch (err: any) {
    error.value = err.message || "Failed to fetch calendars";
    console.error("Error fetching calendars:", err);
  } finally {
    loading.value = false;
  }
};

const navigateToAddCalendar = () => {
  router.push("/calendar/add");
};

const viewCalendar = (calendarId: number) => {
  router.push(`/calendar/${calendarId}`);
};

const viewCalendarEvents = (calendarId: number) => {
  router.push(`/calendar/${calendarId}/events`);
};

const editCalendar = (calendarId: number) => {
  router.push(`/calendar/${calendarId}/edit`);
};

const confirmDelete = (calendar: any) => {
  calendarToDelete.value = calendar;
  showDeleteModal.value = true;
};

const deleteCalendar = async () => {
  if (!calendarToDelete.value) return;

  try {
    console.log(
      `Attempting to delete calendar: ${calendarToDelete.value.name} (ID: ${calendarToDelete.value.id})`
    );

    const response = await axios.delete(
      `${API_URL}/calendar/${calendarToDelete.value.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    console.log("Calendar deleted successfully");

    // Remove from local list
    calendars.value = calendars.value.filter(
      (c) => c.id !== calendarToDelete.value.id
    );

    // Close modal
    showDeleteModal.value = false;
    calendarToDelete.value = null;
  } catch (err: any) {
    console.error("Error deleting calendar:", {
      message: err.message,
      response: err.response?.data,
      status: err.response?.status,
    });

    // Set a more descriptive error message
    if (err.response?.status === 404) {
      error.value = "Calendar not found. It may have been already deleted.";
    } else if (err.response?.status === 403) {
      error.value = "You don't have permission to delete this calendar.";
    } else if (err.response?.data?.detail) {
      error.value = `Failed to delete calendar: ${err.response.data.detail}`;
    } else {
      error.value =
        err.message || "Failed to delete calendar. Please try again.";
    }

    // Keep the modal open if there's an error
    showDeleteModal.value = true;
  }
};

// Fetch available schools for copying
const fetchSchools = async () => {
  loadingSchools.value = true;
  try {
    const response = await axios.get(`${API_URL}/organizations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // Filter out the source school
    availableSchools.value = response.data.filter(
      (school: any) => school.id !== calendarToCopy.value?.school?.id
    );
  } catch (err: any) {
    console.error("Error fetching schools:", err);
    error.value = "Failed to load schools";
  } finally {
    loadingSchools.value = false;
  }
};

const openCopyModal = async (calendar: any) => {
  calendarToCopy.value = calendar;
  selectedSchools.value = [];
  showCopyCalendarModal.value = true;
  await fetchSchools();
};

const copyCalendar = async () => {
  if (!calendarToCopy.value || selectedSchools.value.length === 0) return;

  isCopying.value = true;
  error.value = "";

  try {
    const response = await axios.post(
      `${API_URL}/calendar/${calendarToCopy.value.id}/copy`,
      { schoolIds: selectedSchools.value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    console.log("Calendar copied successfully:", response.data);

    // Refresh calendars list
    await fetchCalendars();

    // Close modal and reset state
    showCopyCalendarModal.value = false;
    calendarToCopy.value = null;
    selectedSchools.value = [];
  } catch (err: any) {
    console.error("Error copying calendar:", err);
    error.value = err.response?.data?.message || "Failed to copy calendar";
  } finally {
    isCopying.value = false;
  }
};

// Watch for filter changes
watch(
  filters,
  () => {
    fetchCalendars();
  },
  { deep: true }
);

// Lifecycle hooks
onMounted(() => {
  fetchCalendars();
});
</script>

<style scoped>
.calendar-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.header-content {
  flex: 1;
}

h1 {
  margin: 0;
  color: #2c3e50;
  font-size: 2rem;
}

.subtitle {
  margin: 5px 0 0;
  color: #7f8c8d;
  font-size: 1rem;
}

.btn-add {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.2s;
}

.btn-add:hover {
  background-color: #2980b9;
}

.empty-state {
  text-align: center;
  padding: 60px 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 20px 0;
}

.empty-state-content {
  max-width: 400px;
  margin: 0 auto;
}

.empty-state-icon {
  font-size: 3rem;
  color: #bdc3c7;
  margin-bottom: 20px;
}

.empty-state h2 {
  margin: 0 0 10px;
  color: #2c3e50;
}

.empty-state p {
  margin: 0 0 20px;
  color: #7f8c8d;
}

.calendar-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.calendar-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.calendar-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.calendar-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #2c3e50;
}

.calendar-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}

.calendar-status.active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.calendar-status.inactive {
  background-color: #ffebee;
  color: #c62828;
}

.calendar-details {
  margin-bottom: 20px;
}

.calendar-date-range {
  color: #7f8c8d;
  margin: 0 0 10px;
}

.calendar-description {
  color: #34495e;
  margin: 0 0 10px;
  font-size: 0.9rem;
}

.calendar-academic-year {
  color: #7f8c8d;
  margin: 0;
  font-size: 0.9rem;
}

.calendar-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.btn-view,
.btn-edit,
.btn-copy,
.btn-delete {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s;
}

.btn-view {
  background-color: #f0f0f0;
  color: #333;
}

.btn-view:hover {
  background-color: #e0e0e0;
}

.btn-edit {
  background-color: #2ecc71;
  color: white;
}

.btn-edit:hover {
  background-color: #27ae60;
}

.btn-copy {
  background-color: #3498db;
  color: white;
}

.btn-copy:hover {
  background-color: #2980b9;
}

.btn-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-delete:hover {
  background-color: #c0392b;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-cancel,
.btn-confirm,
.btn-confirm-delete {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #333;
}

.btn-confirm,
.btn-confirm-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-confirm:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.school-list {
  max-height: 300px;
  overflow-y: auto;
  margin: 20px 0;
}

.school-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.school-item:last-child {
  border-bottom: none;
}

.warning {
  color: #e74c3c;
  font-weight: 500;
  margin-top: 10px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #7f8c8d;
}
</style>
