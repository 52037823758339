import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a47ef5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-footer" }
const _hoisted_2 = { class: "version-info" }
const _hoisted_3 = { class: "build-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'app-container': true, 'with-sidebar': _ctx.isAuthenticated })
  }, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_NavigationMenu, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view),
      _createElementVNode("footer", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, "Version " + _toDisplayString(_ctx.version), 1),
          _createElementVNode("span", _hoisted_3, "Build: " + _toDisplayString(_ctx.buildDate), 1),
          _createElementVNode("button", {
            class: "update-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkForUpdates && _ctx.checkForUpdates(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "sync" }),
            _createTextVNode(" Check for Updates ")
          ])
        ])
      ])
    ])
  ], 2))
}