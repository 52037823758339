import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/styles.css"; // Import the global styles
import "leaflet/dist/leaflet.css";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendarAlt,
  faCalendarDay,
  faPlus,
  faEdit,
  faTrash,
  faFilePdf,
  faFileWord,
  faImage,
  faFile,
  faDownload,
  faUpload,
  faPaperclip,
  faArrowLeft,
  faEye,
  faClock,
  faFilter,
  faUndo,
  faSync,
  faUserCheck,
  faBell,
  faList,
  faTimes,
  faCalendar,
  faSchool,
  faBuilding,
  faUserGraduate,
  faSnowflake,
  faBan,
  faHourglassHalf,
  faHourglassEnd,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

// Add icons to library
library.add(
  faCalendarAlt,
  faCalendarDay,
  faPlus,
  faEdit,
  faTrash,
  faFilePdf,
  faFileWord,
  faImage,
  faFile,
  faDownload,
  faUpload,
  faPaperclip,
  faArrowLeft,
  faEye,
  faClock,
  faFilter,
  faUndo,
  faSync,
  faUserCheck,
  faBell,
  faList,
  faTimes,
  faCalendar,
  faSchool,
  faBuilding,
  faUserGraduate,
  faSnowflake,
  faBan,
  faHourglassHalf,
  faHourglassEnd,
  faArrowDown,
  faArrowUp
);

// Version check function
const checkForUpdates = async () => {
  try {
    const response = await fetch("/version.json");
    const data = await response.json();
    const currentVersion = localStorage.getItem("app_version");

    if (currentVersion !== data.version) {
      // New version available
      localStorage.setItem("app_version", data.version);
      // Show update notification
      if (
        confirm(
          "A new version of the application is available. Would you like to refresh now?"
        )
      ) {
        // Clear all caches and reload
        if ("caches" in window) {
          const keys = await caches.keys();
          await Promise.all(keys.map((key) => caches.delete(key)));
        }
        window.location.reload();
      }
    }
  } catch (error) {
    console.error("Error checking for updates:", error);
  }
};

// Check for updates every 5 minutes
setInterval(checkForUpdates, 5 * 60 * 1000);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .mount("#app");
