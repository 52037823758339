<template>
  <div class="add-event-container">
    <div class="header-actions">
      <button class="btn-back" @click="goBack">
        <font-awesome-icon icon="arrow-left" /> Back to Events
      </button>
    </div>

    <h1>Add New Event</h1>
    <p class="calendar-name">Calendar: {{ calendarName }}</p>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <form @submit.prevent="submitForm" class="event-form">
      <!-- Basic Information -->
      <div class="form-section">
        <h2>Basic Information</h2>

        <div class="form-group">
          <label for="title">Event Title *</label>
          <input
            type="text"
            id="title"
            v-model="formData.title"
            required
            placeholder="Enter event title"
          />
        </div>

        <div class="form-group">
          <label for="type">Event Type *</label>
          <select id="type" v-model="formData.type" required>
            <option value="">Select Event Type</option>
            <option value="regular">Regular</option>
            <option value="closed">Closed</option>
            <option value="snow_day">Snow Day</option>
            <option value="early_dismissal">Early Dismissal</option>
            <option value="late_start">Late Start</option>
            <option value="sick_day">Sick Day</option>
            <option value="holiday">Holiday</option>
            <option value="bell_schedule">Bell Schedule</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div class="form-group">
          <label for="description">Description</label>
          <textarea
            id="description"
            v-model="formData.description"
            placeholder="Enter event description"
            rows="3"
          ></textarea>
        </div>
      </div>

      <!-- Date and Time -->
      <div class="form-section">
        <h2>Date and Time</h2>

        <div class="form-row">
          <div class="form-group">
            <label for="startDate">Start Date *</label>
            <input
              type="date"
              id="startDate"
              v-model="formData.startDate"
              required
              :min="calendarStartDate"
              :max="calendarEndDate"
            />
          </div>

          <div class="form-group">
            <label for="endDate">End Date</label>
            <input
              type="date"
              id="endDate"
              v-model="formData.endDate"
              :min="formData.startDate"
              :max="calendarEndDate"
            />
            <small>Leave blank for single-day events</small>
          </div>
        </div>

        <div class="form-group checkbox-group">
          <input
            type="checkbox"
            id="isAllDay"
            v-model="formData.isAllDay"
            @change="handleAllDayChange"
          />
          <label for="isAllDay">All Day Event</label>
        </div>

        <div class="form-group checkbox-group">
          <input
            type="checkbox"
            id="isAllWeek"
            v-model="formData.isAllWeek"
            @change="handleAllWeekChange"
          />
          <label for="isAllWeek">All Week Event</label>
        </div>

        <div v-if="!formData.isAllDay && !formData.isAllWeek" class="form-row">
          <div class="form-group">
            <label for="startTime">Start Time</label>
            <input type="time" id="startTime" v-model="formData.startTime" />
          </div>

          <div class="form-group">
            <label for="endTime">End Time</label>
            <input type="time" id="endTime" v-model="formData.endTime" />
          </div>
        </div>

        <div class="form-group checkbox-group">
          <input
            type="checkbox"
            id="affectsAttendance"
            v-model="formData.affectsAttendance"
          />
          <label for="affectsAttendance">This event affects attendance</label>
        </div>
      </div>

      <!-- Recurring Options -->
      <div class="form-section">
        <h2>Recurring Options</h2>

        <div class="form-group checkbox-group">
          <input
            type="checkbox"
            id="isRecurring"
            v-model="formData.isRecurring"
          />
          <label for="isRecurring">This is a recurring event</label>
        </div>

        <div v-if="formData.isRecurring" class="recurring-options">
          <div class="form-group">
            <label for="recurrencePattern">Recurrence Pattern</label>
            <select id="recurrencePattern" v-model="formData.recurrencePattern">
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="biweekly">Bi-weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Bell Schedule (shown only when type is 'bell_schedule') -->
      <div v-if="formData.type === 'bell_schedule'" class="form-section">
        <h2>Bell Schedule</h2>
        <p class="help-text">
          Define the periods for this bell schedule. Click "Add Period" to add
          more periods.
        </p>

        <div
          v-for="(period, index) in bellSchedule.periods"
          :key="index"
          class="bell-period"
        >
          <div class="period-header">
            <h3>Period {{ index + 1 }}</h3>
            <button
              type="button"
              class="btn-remove-period"
              @click="removePeriod(index)"
            >
              <font-awesome-icon icon="times" />
            </button>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label :for="'periodName' + index">Name</label>
              <input
                :id="'periodName' + index"
                type="text"
                v-model="period.name"
                placeholder="e.g., 1st Period, Lunch"
                required
              />
            </div>

            <div class="form-group">
              <label :for="'periodType' + index">Type</label>
              <select :id="'periodType' + index" v-model="period.type">
                <option value="class">Class</option>
                <option value="break">Break</option>
                <option value="lunch">Lunch</option>
                <option value="assembly">Assembly</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <label :for="'periodStartTime' + index">Start Time</label>
              <input
                :id="'periodStartTime' + index"
                type="time"
                v-model="period.startTime"
                required
              />
            </div>

            <div class="form-group">
              <label :for="'periodEndTime' + index">End Time</label>
              <input
                :id="'periodEndTime' + index"
                type="time"
                v-model="period.endTime"
                required
              />
            </div>
          </div>
        </div>

        <button type="button" class="btn-add-period" @click="addPeriod">
          <font-awesome-icon icon="plus" /> Add Period
        </button>
      </div>

      <div class="form-actions">
        <button type="button" class="btn-cancel" @click="goBack">Cancel</button>
        <button type="submit" class="btn-submit" :disabled="isSubmitting">
          <span v-if="isSubmitting">Saving...</span>
          <span v-else>Save Event</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { API_URL } from "../config";

// Router and Route
const router = useRouter();
const route = useRoute();
const calendarId = route.params.calendarId as string;

// State
const calendarName = ref("");
const calendarStartDate = ref("");
const calendarEndDate = ref("");
const error = ref("");
const isSubmitting = ref(false);

// Form data
const formData = reactive({
  title: "",
  type: "",
  description: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  isAllDay: false,
  isAllWeek: false,
  isRecurring: false,
  recurrencePattern: "daily",
  affectsAttendance: true,
});

// Bell schedule data
const bellSchedule = reactive({
  periods: [
    {
      name: "",
      startTime: "",
      endTime: "",
      type: "class",
    },
  ],
});

// Functions
const fetchCalendar = async () => {
  try {
    const response = await axios.get(`${API_URL}/calendar/${calendarId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const calendar = response.data;
    calendarName.value = calendar.name;
    calendarStartDate.value = calendar.startDate;
    calendarEndDate.value = calendar.endDate;

    // Set default start date to today if within calendar range
    const today = new Date().toISOString().split("T")[0];
    if (today >= calendar.startDate && today <= calendar.endDate) {
      formData.startDate = today;
    } else {
      formData.startDate = calendar.startDate;
    }

    // Set default times if available
    if (calendar.defaultStartTime) {
      formData.startTime = calendar.defaultStartTime;
    }
    if (calendar.defaultEndTime) {
      formData.endTime = calendar.defaultEndTime;
    }
  } catch (err: any) {
    error.value = err.message || "Failed to fetch calendar";
    console.error("Error fetching calendar:", err);
  }
};

const addPeriod = () => {
  bellSchedule.periods.push({
    name: "",
    startTime: "",
    endTime: "",
    type: "class",
  });
};

const removePeriod = (index: number) => {
  if (bellSchedule.periods.length > 1) {
    bellSchedule.periods.splice(index, 1);
  }
};

// Handle all day change
const handleAllDayChange = (event: Event) => {
  const isChecked = (event.target as HTMLInputElement).checked;
  if (isChecked) {
    formData.startTime = "";
    formData.endTime = "";
    formData.isAllWeek = false;
  }
};

// Handle all week change
const handleAllWeekChange = (event: Event) => {
  const isChecked = (event.target as HTMLInputElement).checked;
  if (isChecked) {
    formData.startTime = "";
    formData.endTime = "";
    formData.isAllDay = false;
    // Set end date to end of week
    const startDate = new Date(formData.startDate);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + (6 - startDate.getDay()));
    formData.endDate = endDate.toISOString().split("T")[0];
  } else {
    formData.endDate = "";
  }
};

// Generate events for multi-day events
const generateEvents = (eventData: any): any[] => {
  if (!eventData.endDate || eventData.endDate === eventData.startDate) {
    return [eventData];
  }

  const events: any[] = [];
  const startDate = new Date(eventData.startDate);
  const endDate = new Date(eventData.endDate);
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const event = {
      ...eventData,
      startDate: currentDate.toISOString().split("T")[0],
      endDate: currentDate.toISOString().split("T")[0],
    };
    events.push(event);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return events;
};

const submitForm = async () => {
  // Validate form
  if (!formData.title) {
    error.value = "Please enter an event title";
    return;
  }

  if (!formData.type) {
    error.value = "Please select an event type";
    return;
  }

  if (!formData.startDate) {
    error.value = "Please select a start date";
    return;
  }

  // Validate bell schedule if applicable
  if (formData.type === "bell_schedule") {
    if (bellSchedule.periods.length === 0) {
      error.value = "Please add at least one period to the bell schedule";
      return;
    }

    for (const period of bellSchedule.periods) {
      if (!period.name || !period.startTime || !period.endTime) {
        error.value = "Please complete all bell schedule period details";
        return;
      }
    }
  }

  isSubmitting.value = true;
  error.value = "";

  try {
    // Prepare data for submission
    const eventData = {
      ...formData,
      // Include bell schedule only if type is bell_schedule
      bellSchedule:
        formData.type === "bell_schedule" ? bellSchedule : undefined,
    };

    // Generate events for multi-day events
    const events = generateEvents(eventData);

    // Create all events
    for (const event of events) {
      await axios.post(`${API_URL}/calendar/${calendarId}/events`, event, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }

    // Navigate back to events list
    router.push(`/calendar/${calendarId}/events`);
  } catch (err: any) {
    isSubmitting.value = false;
    error.value =
      err.response?.data?.message || err.message || "Failed to create event";
    console.error("Error creating event:", err);
  }
};

const goBack = () => {
  router.push(`/calendar/${calendarId}/events`);
};

// Lifecycle hooks
onMounted(() => {
  fetchCalendar();
});
</script>

<style scoped>
.add-event-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-back {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  background-color: #f0f0f0;
  color: #333;
}

.btn-back:hover {
  background-color: #ddd;
}

h1 {
  margin-bottom: 5px;
  color: #2c3e50;
}

.calendar-name {
  margin-top: 0;
  margin-bottom: 20px;
  color: #666;
  font-style: italic;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.event-form {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.form-section {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.form-section h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: 1.2rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-row .form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #2c3e50;
}

input[type="text"],
input[type="date"],
input[type="time"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

small {
  display: block;
  margin-top: 5px;
  color: #666;
  font-size: 12px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-group input[type="checkbox"] {
  margin: 0;
}

.checkbox-group label {
  margin: 0;
}

.recurring-options {
  margin-top: 15px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.help-text {
  margin-top: 0;
  margin-bottom: 15px;
  color: #666;
}

.bell-period {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #eee;
}

.period-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.period-header h3 {
  margin: 0;
  font-size: 1rem;
  color: #2c3e50;
}

.btn-remove-period {
  background-color: transparent;
  border: none;
  color: #e74c3c;
  cursor: pointer;
  font-size: 16px;
}

.btn-add-period {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 15px;
}

.btn-add-period:hover {
  background-color: #2980b9;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px;
}

.btn-cancel,
.btn-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #333;
}

.btn-cancel:hover {
  background-color: #ddd;
}

.btn-submit {
  background-color: #4caf50;
  color: white;
}

.btn-submit:hover {
  background-color: #45a049;
}

.btn-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .form-actions {
    flex-direction: column;
  }

  .btn-cancel,
  .btn-submit {
    width: 100%;
  }
}
</style>
