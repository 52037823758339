<template>
  <div class="students-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <span>Students</span>
    </nav>

    <h2 class="title">Students</h2>

    <div class="controls-wrapper">
      <div class="filters">
        <div class="search-inputs">
          <div class="input-group">
            <label for="firstName">First Name</label>
            <input
              id="firstName"
              v-model="filters.firstName"
              placeholder="Filter by first name"
            />
          </div>
          <div class="input-group">
            <label for="lastName">Last Name</label>
            <input
              id="lastName"
              v-model="filters.lastName"
              placeholder="Filter by last name"
            />
          </div>
          <div class="input-group">
            <label for="school">School</label>
            <input
              id="school"
              v-model="filters.school"
              placeholder="Filter by school"
            />
          </div>
          <div class="input-group">
            <label for="group">Group</label>
            <input
              id="group"
              v-model="filters.group"
              placeholder="Filter by group"
            />
          </div>
        </div>
      </div>
      <div class="actions">
        <button @click="exportToCSV" class="secondary-button">
          Export CSV
        </button>
        <button @click="exportToExcel" class="secondary-button">
          Export Excel
        </button>
        <button @click="addStudent" class="primary-button">Add Student</button>
      </div>
    </div>

    <table class="students-table">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>School</th>
          <th>Group</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="student in paginatedStudents" :key="student.id">
          <td>{{ student.firstName }}</td>
          <td>{{ student.lastName }}</td>
          <td>{{ student.primarySchool ? student.primarySchool.name : "" }}</td>
          <td>{{ student.group ? student.group.name : "" }}</td>
          <td class="action-buttons">
            <button @click="viewStudent(student.id)" class="view-button">
              View
            </button>
            <button @click="editStudent(student.id)" class="edit-button">
              Edit
            </button>
            <button @click="deleteStudent(student.id)" class="delete-button">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination">
      <div class="pagination-controls">
        <button
          @click="previousPage"
          :disabled="currentPage === 1"
          class="pagination-button"
        >
          <span class="arrow">←</span> Previous
        </button>
        <div class="page-info">
          <select
            v-model="currentPage"
            class="page-select"
            :disabled="totalPages === 1"
          >
            <option v-for="page in totalPages" :key="page" :value="page">
              {{ page }}
            </option>
          </select>
          <span>of {{ totalPages }}</span>
        </div>
        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="pagination-button"
        >
          Next <span class="arrow">→</span>
        </button>
      </div>
      <div class="items-per-page">
        <label for="itemsPerPage">Items per page:</label>
        <select v-model="itemsPerPage" id="itemsPerPage" class="items-select">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="100">100</option>
          <option value="all">All</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config"; // Import the API URL from the config file

// Add interface for School
interface School {
  id: number;
  name: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  latitude?: number;
  longitude?: number;
}

// Update Student interface
interface Student {
  id: number;
  firstName: string;
  lastName: string;
  primarySchool?: School;
  group?: { id: number; name: string };
  addressLine1?: string;
  city?: string;
  state?: string;
  zip?: string;
  comments?: string;
  needs?: string;
}

export default defineComponent({
  name: "StudentsView",
  setup() {
    const students = ref<Student[]>([]);
    const itemsPerPage = ref<string | number>(10);
    const filters = ref({
      firstName: "",
      lastName: "",
      school: "",
      group: "",
    });
    const router = useRouter();
    const currentPage = ref(1);

    const fetchStudents = async () => {
      try {
        const response = await fetch(`${API_URL}/students`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          students.value = data.map((student: any) => ({
            ...student,
            primarySchool: student.primarySchool || null,
          }));
        } else {
          // More descriptive error handling
          const error = await response.json();
          console.error("Failed to fetch students:", error);
          alert(
            `Error loading students: ${error.message || response.statusText}`
          );
        }
      } catch (error) {
        console.error("Error fetching students:", error);
        alert(
          "Failed to load students. Please check your connection and try again."
        );
      }
    };

    onMounted(() => {
      fetchStudents();
    });

    const filteredStudents = computed(() => {
      let filtered = students.value;
      if (filters.value.firstName) {
        filtered = filtered.filter((student) =>
          student.firstName
            .toLowerCase()
            .includes(filters.value.firstName.toLowerCase())
        );
      }
      if (filters.value.lastName) {
        filtered = filtered.filter((student) =>
          student.lastName
            .toLowerCase()
            .includes(filters.value.lastName.toLowerCase())
        );
      }
      if (filters.value.school) {
        filtered = filtered.filter((student) =>
          student.primarySchool?.name
            .toLowerCase()
            .includes(filters.value.school.toLowerCase())
        );
      }
      if (filters.value.group) {
        filtered = filtered.filter((student) =>
          student.group?.name
            .toLowerCase()
            .includes(filters.value.group.toLowerCase())
        );
      }
      if (itemsPerPage.value !== "all") {
        const limit = Number(itemsPerPage.value);
        filtered = filtered.slice(0, limit);
      }
      return filtered;
    });

    const totalPages = computed(() => {
      if (itemsPerPage.value === "all") return 1;
      return Math.ceil(
        filteredStudents.value.length / Number(itemsPerPage.value)
      );
    });

    const paginatedStudents = computed(() => {
      if (itemsPerPage.value === "all") return filteredStudents.value;
      const start = (currentPage.value - 1) * Number(itemsPerPage.value);
      const end = start + Number(itemsPerPage.value);
      return filteredStudents.value.slice(start, end);
    });

    const exportToCSV = () => {
      // Implement CSV export functionality
      console.log("Exporting to CSV");
    };

    const exportToExcel = () => {
      // Implement Excel export functionality
      console.log("Exporting to Excel");
    };

    const addStudent = () => {
      router.push({ name: "AddStudent" });
    };

    const editStudent = (id: number) => {
      router.push({ name: "EditStudent", params: { id } });
    };

    const viewStudent = (id: number) => {
      router.push({ name: "ViewStudent", params: { id: id.toString() } });
    };

    const deleteStudent = async (id: number) => {
      if (confirm("Are you sure you want to delete this student?")) {
        try {
          const response = await fetch(`${API_URL}/students/${id}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          if (response.ok) {
            fetchStudents(); // Refresh the list after deletion
          } else {
            console.error("Failed to delete student:", response.statusText);
          }
        } catch (error) {
          console.error("Error deleting student:", error);
        }
      }
    };

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    return {
      students,
      itemsPerPage,
      filters,
      filteredStudents,
      paginatedStudents,
      exportToCSV,
      exportToExcel,
      addStudent,
      editStudent,
      viewStudent,
      deleteStudent,
      currentPage,
      totalPages,
      previousPage,
      nextPage,
    };
  },
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.students-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.controls-wrapper {
  margin-bottom: 2rem;
}

.filters {
  margin-bottom: 1rem;
}

.search-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.input-group input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.primary-button {
  background-color: #28a745;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.primary-button:hover {
  background-color: #218838;
}

.secondary-button {
  background-color: #6c757d;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.secondary-button:hover {
  background-color: #5a6268;
}

.students-table {
  margin-top: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.students-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 16px;
}

.students-table td {
  padding: 12px 16px;
  vertical-align: middle;
}

.students-table tr:hover {
  background-color: #f8f9fa;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.action-buttons button {
  padding: 6px 12px;
  font-size: 13px;
}

.pagination {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.arrow {
  font-size: 1.2em;
}

.page-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.page-select,
.items-select {
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.items-per-page {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.view-button {
  background-color: #17a2b8;
  color: #fff;
}

.view-button:hover {
  background-color: #138496;
}

.edit-button {
  background-color: #ffc107;
  color: #fff;
}

.edit-button:hover {
  background-color: #e0a800;
}

.delete-button {
  background-color: #dc3545;
  color: #fff;
}

.delete-button:hover {
  background-color: #c82333;
}
</style>
