<template>
  <div class="calendar-events-container">
    <div class="header-actions">
      <button class="btn-back" @click="goBack">
        <font-awesome-icon icon="arrow-left" /> Back to Calendar
      </button>
      <div class="action-buttons">
        <div class="view-toggle">
          <button
            class="btn-toggle"
            :class="{ active: viewMode === 'calendar' }"
            @click="viewMode = 'calendar'"
          >
            <font-awesome-icon icon="calendar" /> Calendar
          </button>
          <button
            class="btn-toggle"
            :class="{ active: viewMode === 'list' }"
            @click="viewMode = 'list'"
          >
            <font-awesome-icon icon="list" /> List
          </button>
        </div>
        <button class="btn-import" @click="showImportModal = true">
          <font-awesome-icon icon="file-import" /> Import Events
        </button>
        <button class="btn-add" @click="navigateToAddEvent">
          <font-awesome-icon icon="plus" /> Add Event
        </button>
      </div>
    </div>

    <div class="calendar-header">
      <h1>Events for {{ calendarName }}</h1>
    </div>

    <div class="filters">
      <div class="filter-row">
        <div class="filter-group">
          <label for="eventType">Event Type:</label>
          <select id="eventType" v-model="filters.type">
            <option value="">All Types</option>
            <option value="regular">Regular</option>
            <option value="closed">Closed</option>
            <option value="snow_day">Snow Day</option>
            <option value="early_dismissal">Early Dismissal</option>
            <option value="late_start">Late Start</option>
            <option value="sick_day">Sick Day</option>
            <option value="holiday">Holiday</option>
            <option value="bell_schedule">Bell Schedule</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div class="filter-group">
          <label for="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            v-model="filters.startDate"
            :max="filters.endDate"
          />
        </div>

        <div class="filter-group">
          <label for="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            v-model="filters.endDate"
            :min="filters.startDate"
          />
        </div>

        <div class="filter-actions">
          <button class="btn-filter" @click="applyFilters">
            <font-awesome-icon icon="filter" /> Apply Filters
          </button>
          <button class="btn-reset" @click="resetFilters">
            <font-awesome-icon icon="undo" /> Reset
          </button>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <p>Loading events...</p>
    </div>

    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <!-- Calendar View -->
    <div v-else-if="viewMode === 'calendar'" class="calendar-grid">
      <div class="calendar-nav">
        <div class="nav-controls">
          <button class="btn-nav" @click="previousMonth">
            <font-awesome-icon icon="chevron-left" />
          </button>
          <div class="month-year-selector">
            <select
              v-model="selectedMonth"
              @change="handleMonthChange"
              class="month-select"
            >
              <option
                v-for="(month, index) in months"
                :key="index"
                :value="index"
              >
                {{ month }}
              </option>
            </select>
            <select
              v-model="selectedYear"
              @change="handleYearChange"
              class="year-select"
            >
              <option v-for="year in yearRange" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
          <button class="btn-nav" @click="nextMonth">
            <font-awesome-icon icon="chevron-right" />
          </button>
        </div>
        <button class="btn-today" @click="goToToday">
          <font-awesome-icon icon="calendar-day" /> Today
        </button>
      </div>

      <div class="calendar-weekdays">
        <div v-for="day in weekDays" :key="day" class="weekday">{{ day }}</div>
      </div>

      <div class="calendar-days">
        <div
          v-for="day in calendarDays"
          :key="day.date.toISOString()"
          class="calendar-day"
          :class="{
            'other-month': !day.isCurrentMonth,
            'has-events': day.events.length > 0,
            today: isToday(day.date),
          }"
          @click="handleDayClick(day)"
        >
          <div class="day-number">{{ day.dayNumber }}</div>
          <div v-if="day.events.length > 0" class="day-events">
            <div
              v-for="event in day.events.slice(0, 2)"
              :key="event.id"
              class="event-dot"
              :class="event.type"
            ></div>
            <div v-if="day.events.length > 2" class="more-events">
              +{{ day.events.length - 2 }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- List View -->
    <div v-else class="events-list">
      <div class="list-header">
        <div class="list-column title">Title</div>
        <div class="list-column type">Type</div>
        <div class="list-column date">Date</div>
        <div class="list-column actions">Actions</div>
      </div>
      <div class="list-content">
        <div v-for="event in filteredEvents" :key="event.id" class="list-item">
          <div class="list-column title">{{ event.title }}</div>
          <div class="list-column type">
            <span class="event-type-badge" :class="event.type">
              {{ formatEventType(event.type) }}
            </span>
          </div>
          <div class="list-column date">
            {{ formatDate(event.startDate) }}
            <span v-if="event.endDate && event.endDate !== event.startDate">
              - {{ formatDate(event.endDate) }}
            </span>
          </div>
          <div class="list-column actions">
            <button class="btn-icon" @click="editEvent(event)">
              <font-awesome-icon icon="edit" />
            </button>
            <button class="btn-icon" @click="confirmDelete(event)">
              <font-awesome-icon icon="trash" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Event Modal -->
    <div v-if="showEventModal" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h3>{{ selectedDate ? "Add Event" : "Edit Event" }}</h3>
          <button class="btn-close" @click="closeEventModal">
            <font-awesome-icon icon="times" />
          </button>
        </div>

        <div v-if="selectedEvent" class="event-details">
          <h4>{{ selectedEvent.title }}</h4>
          <p class="event-type" :class="selectedEvent.type">
            {{ formatEventType(selectedEvent.type) }}
          </p>
          <p class="event-date">
            {{ formatDate(selectedEvent.startDate) }}
            <span
              v-if="
                selectedEvent.endDate &&
                selectedEvent.endDate !== selectedEvent.startDate
              "
            >
              - {{ formatDate(selectedEvent.endDate) }}
            </span>
          </p>
          <p v-if="selectedEvent.description" class="event-description">
            {{ selectedEvent.description }}
          </p>
        </div>

        <div v-else class="event-form">
          <form @submit.prevent="submitNewEvent">
            <div class="form-group">
              <label for="title">Event Title *</label>
              <input
                type="text"
                id="title"
                v-model="newEvent.title"
                required
                placeholder="Enter event title"
              />
            </div>

            <div class="form-group">
              <label for="type">Event Type *</label>
              <select id="type" v-model="newEvent.type" required>
                <option value="">Select Event Type</option>
                <option value="regular">Regular</option>
                <option value="closed">Closed</option>
                <option value="snow_day">Snow Day</option>
                <option value="early_dismissal">Early Dismissal</option>
                <option value="late_start">Late Start</option>
                <option value="sick_day">Sick Day</option>
                <option value="holiday">Holiday</option>
                <option value="bell_schedule">Bell Schedule</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div class="form-group">
              <label for="description">Description</label>
              <textarea
                id="description"
                v-model="newEvent.description"
                placeholder="Enter event description"
                rows="3"
              ></textarea>
            </div>

            <div class="form-row">
              <div class="form-group">
                <label for="startDate">Start Date *</label>
                <input
                  type="date"
                  id="startDate"
                  v-model="newEvent.startDate"
                  required
                  :min="calendarStartDate"
                  :max="calendarEndDate"
                />
              </div>

              <div class="form-group">
                <label for="endDate">End Date</label>
                <input
                  type="date"
                  id="endDate"
                  v-model="newEvent.endDate"
                  :min="newEvent.startDate || calendarStartDate"
                  :max="calendarEndDate"
                />
                <small>Leave blank for single-day events</small>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group">
                <label for="startTime">Start Time (Optional)</label>
                <input
                  type="time"
                  id="startTime"
                  v-model="newEvent.startTime"
                />
                <small>Leave empty to use calendar's default start time</small>
              </div>

              <div class="form-group">
                <label for="endTime">End Time (Optional)</label>
                <input type="time" id="endTime" v-model="newEvent.endTime" />
                <small>Leave empty to use calendar's default end time</small>
              </div>
            </div>

            <div class="modal-actions">
              <button type="button" class="btn-cancel" @click="closeEventModal">
                Cancel
              </button>
              <button type="submit" class="btn-submit" :disabled="isSubmitting">
                <span v-if="isSubmitting">Saving...</span>
                <span v-else>Save Event</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Confirm Delete</h3>
        <p>
          Are you sure you want to delete the event "{{
            eventToDelete?.title
          }}"?
        </p>
        <p class="warning">This action cannot be undone.</p>

        <div class="modal-actions">
          <button class="btn-cancel" @click="showDeleteModal = false">
            Cancel
          </button>
          <button class="btn-confirm-delete" @click="deleteEvent">
            Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Import Modal -->
    <div v-if="showImportModal" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Import Events</h3>
          <button class="btn-close" @click="showImportModal = false">
            <font-awesome-icon icon="times" />
          </button>
        </div>
        <ImportICSEvents
          :calendar-id="Number(calendarId)"
          @close="showImportModal = false"
          @import-complete="fetchEvents"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faPlus,
  faFilter,
  faUndo,
  faChevronLeft,
  faChevronRight,
  faCalendarDay,
  faTimes,
  faEdit,
  faTrash,
  faFileImport,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL } from "../config";
import ImportICSEvents from "../components/ImportICSEvents.vue";

interface CalendarEvent {
  id: number;
  title: string;
  description?: string;
  startDate: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  type: string;
  affectsAttendance: boolean;
  uid?: string;
  icsData?: any;
  calendar?: {
    id: number;
    name: string;
  };
}

// Register Font Awesome icons
library.add(
  faArrowLeft,
  faPlus,
  faFilter,
  faUndo,
  faChevronLeft,
  faChevronRight,
  faCalendarDay,
  faTimes,
  faEdit,
  faTrash,
  faFileImport,
  faList
);

// Router and Route
const router = useRouter();
const route = useRoute();
const calendarId = route.params.calendarId as string;

// State
const events = ref<CalendarEvent[]>([]);
const calendarName = ref("");
const loading = ref(false);
const error = ref("");
const showDeleteModal = ref(false);
const eventToDelete = ref<CalendarEvent | null>(null);
const showEventModal = ref(false);
const selectedDate = ref<Date | null>(null);
const selectedEvent = ref<CalendarEvent | null>(null);
const currentDate = ref(new Date());

// Filters
const filters = reactive({
  type: "",
  startDate: "",
  endDate: "",
});

// Add these new refs and computed properties after the existing state declarations
const selectedMonth = ref(new Date().getMonth());
const selectedYear = ref(new Date().getFullYear());

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const yearRange = computed(() => {
  const currentYear = new Date().getFullYear();
  const years = [];
  // Show 5 years before and after the current year
  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    years.push(i);
  }
  return years;
});

// Computed
const currentMonthYear = computed(() => {
  return currentDate.value.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
});

const weekDays = computed(() => {
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
});

const calendarDays = computed(() => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const days = [];

  // Add days from previous month
  const firstDayWeekday = firstDay.getDay();
  for (let i = firstDayWeekday - 1; i >= 0; i--) {
    const date = new Date(year, month, -i);
    days.push({
      date,
      dayNumber: date.getDate(),
      isCurrentMonth: false,
      events: getEventsForDate(date),
    });
  }

  // Add days from current month
  for (let i = 1; i <= lastDay.getDate(); i++) {
    const date = new Date(year, month, i);
    days.push({
      date,
      dayNumber: i,
      isCurrentMonth: true,
      events: getEventsForDate(date),
    });
  }

  // Add days from next month to complete the grid
  const remainingDays = 42 - days.length; // 6 weeks * 7 days = 42
  for (let i = 1; i <= remainingDays; i++) {
    const date = new Date(year, month + 1, i);
    days.push({
      date,
      dayNumber: date.getDate(),
      isCurrentMonth: false,
      events: getEventsForDate(date),
    });
  }

  return days;
});

// Methods
const getEventsForDate = (date: Date) => {
  if (!events.value || !Array.isArray(events.value)) {
    return [];
  }

  return events.value.filter((event) => {
    const eventStartDate = new Date(event.startDate);
    const eventEndDate = event.endDate
      ? new Date(event.endDate)
      : eventStartDate;
    return date >= eventStartDate && date <= eventEndDate;
  });
};

const isToday = (date: Date) => {
  const today = new Date();
  return date.toDateString() === today.toDateString();
};

const previousMonth = () => {
  if (selectedMonth.value === 0) {
    selectedMonth.value = 11;
    selectedYear.value--;
  } else {
    selectedMonth.value--;
  }
  currentDate.value = new Date(selectedYear.value, selectedMonth.value);
};

const nextMonth = () => {
  if (selectedMonth.value === 11) {
    selectedMonth.value = 0;
    selectedYear.value++;
  } else {
    selectedMonth.value++;
  }
  currentDate.value = new Date(selectedYear.value, selectedMonth.value);
};

const handleDayClick = (day: any) => {
  selectedDate.value = day.date;
  selectedEvent.value = day.events[0] || null;

  // Set the start date and end date to the selected date
  if (!selectedEvent.value) {
    newEvent.startDate = day.date.toISOString().split("T")[0];
    newEvent.endDate = day.date.toISOString().split("T")[0];
  }

  showEventModal.value = true;
};

const closeEventModal = () => {
  showEventModal.value = false;
  selectedDate.value = null;
  selectedEvent.value = null;
};

// Add new state for the event form
const newEvent = reactive({
  title: "",
  type: "",
  description: "",
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
});

// Add type for event data
interface EventData {
  title: string;
  type: string;
  description?: string;
  startDate: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}

const calendarStartDate = ref("");
const calendarEndDate = ref("");
const isSubmitting = ref(false);

// Update the submitNewEvent method to handle default times
const submitNewEvent = async () => {
  // Validate form
  if (!newEvent.title) {
    error.value = "Please enter an event title";
    return;
  }

  if (!newEvent.type) {
    error.value = "Please select an event type";
    return;
  }

  if (!newEvent.startDate) {
    error.value = "Please select a start date";
    return;
  }

  // Validate dates are within calendar range
  const startDate = new Date(newEvent.startDate);
  const endDate = newEvent.endDate ? new Date(newEvent.endDate) : startDate;
  const calendarStart = new Date(calendarStartDate.value);
  const calendarEnd = new Date(calendarEndDate.value);

  if (startDate < calendarStart || startDate > calendarEnd) {
    error.value = "Start date must be within the calendar's date range";
    return;
  }

  if (endDate < startDate) {
    error.value = "End date cannot be before start date";
    return;
  }

  if (endDate > calendarEnd) {
    error.value = "End date must be within the calendar's date range";
    return;
  }

  // Prepare event data, only including times if they are different from defaults
  const eventData: EventData = {
    title: newEvent.title,
    type: newEvent.type,
    startDate: newEvent.startDate,
  };

  // Add optional fields only if they have values
  if (newEvent.description) eventData.description = newEvent.description;
  if (newEvent.endDate) eventData.endDate = newEvent.endDate;

  // Only include times if they are different from defaults
  if (
    newEvent.startTime &&
    newEvent.startTime !== calendarDefaultStartTime.value
  ) {
    eventData.startTime = newEvent.startTime;
  }
  if (newEvent.endTime && newEvent.endTime !== calendarDefaultEndTime.value) {
    eventData.endTime = newEvent.endTime;
  }

  isSubmitting.value = true;
  try {
    await axios.post(`${API_URL}/calendar/${calendarId}/events`, eventData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // Refresh events
    await fetchEvents();

    // Close modal
    closeEventModal();
  } catch (err: any) {
    error.value =
      err.response?.data?.message || err.message || "Failed to create event";
    console.error("Error creating event:", err);
  } finally {
    isSubmitting.value = false;
  }
};

const editEvent = (event: CalendarEvent) => {
  router.push(`/calendar/${calendarId}/events/${event.id}/edit`);
};

const confirmDelete = (event: CalendarEvent) => {
  eventToDelete.value = event;
  showDeleteModal.value = true;
};

// Initialize date filters with calendar start/end dates
const initializeDateFilters = (startDate: string, endDate: string) => {
  // Default to current month if no dates provided
  if (!startDate || !endDate) {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    filters.startDate = firstDay.toISOString().split("T")[0];
    filters.endDate = lastDay.toISOString().split("T")[0];
  } else {
    filters.startDate = startDate;
    filters.endDate = endDate;
  }
};

// Format functions
const formatDate = (dateString: string): string => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Invalid Date";

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const formatEventType = (type: string): string => {
  if (!type) return "";

  return type
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Functions
const fetchCalendar = async () => {
  try {
    const response = await axios.get(`${API_URL}/calendar/${calendarId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    calendarName.value = response.data.name;
    calendarStartDate.value = response.data.startDate;
    calendarEndDate.value = response.data.endDate;
    calendarDefaultStartTime.value = response.data.defaultStartTime || "";
    calendarDefaultEndTime.value = response.data.defaultEndTime || "";

    // Initialize date filters with calendar start/end dates
    initializeDateFilters(response.data.startDate, response.data.endDate);

    // Fetch events after getting calendar info
    fetchEvents();
  } catch (err: any) {
    error.value = err.message || "Failed to fetch calendar";
    console.error("Error fetching calendar:", err);
    loading.value = false;
  }
};

const fetchEvents = async () => {
  loading.value = true;
  error.value = "";
  try {
    console.log("Fetching events for calendar:", calendarId);
    const response = await axios.get(
      `${API_URL}/calendar/${calendarId}/events`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("API Response:", response.data);
    // Ensure we're setting an array
    events.value = Array.isArray(response.data) ? response.data : [];
    console.log("Updated events.value:", events.value);
  } catch (err: any) {
    console.error("Error fetching events:", err);
    error.value = err.response?.data?.message || "Error fetching events";
    events.value = []; // Reset to empty array on error
  } finally {
    loading.value = false;
  }
};

const navigateToAddEvent = () => {
  router.push(`/calendar/${calendarId}/events/add`);
};

const goBack = () => {
  router.push(`/calendar/${calendarId}`);
};

const applyFilters = () => {
  fetchEvents();
};

const resetFilters = () => {
  filters.type = "";
  // Reset date filters to calendar start/end dates or current month
  fetchCalendar();
};

const deleteEvent = async () => {
  if (!eventToDelete.value) return;

  try {
    await axios.delete(
      `${API_URL}/calendar/${calendarId}/events/${eventToDelete.value.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    await fetchEvents();
    showDeleteModal.value = false;
    eventToDelete.value = null;
  } catch (err: any) {
    error.value = err.response?.data?.message || "Error deleting event";
  }
};

// Add these new methods after the existing methods
const handleMonthChange = () => {
  currentDate.value = new Date(selectedYear.value, selectedMonth.value);
};

const handleYearChange = () => {
  currentDate.value = new Date(selectedYear.value, selectedMonth.value);
};

const goToToday = () => {
  const today = new Date();
  selectedMonth.value = today.getMonth();
  selectedYear.value = today.getFullYear();
  currentDate.value = today;
};

// Add a watch to sync the selectedMonth and selectedYear with currentDate
watch(currentDate, (newDate) => {
  selectedMonth.value = newDate.getMonth();
  selectedYear.value = newDate.getFullYear();
});

// Add new refs for default times
const calendarDefaultStartTime = ref("");
const calendarDefaultEndTime = ref("");

// Add new state for import modal
const showImportModal = ref(false);

// Add new ref for view mode
const viewMode = ref<"calendar" | "list">("calendar");

// Add computed property for filtered events
const filteredEvents = computed(() => {
  let filtered = [...events.value];

  // Apply type filter
  if (filters.type) {
    filtered = filtered.filter((event) => event.type === filters.type);
  }

  // Apply date range filter
  if (filters.startDate && filters.endDate) {
    const startDate = new Date(filters.startDate);
    const endDate = new Date(filters.endDate);
    filtered = filtered.filter((event) => {
      const eventDate = new Date(event.startDate);
      return eventDate >= startDate && eventDate <= endDate;
    });
  }

  // Sort by date
  return filtered.sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
  );
});

// Lifecycle hooks
onMounted(() => {
  fetchCalendar();
});
</script>

<style scoped>
.calendar-events-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-back,
.btn-add {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}

.btn-back {
  background-color: #f0f0f0;
  color: #333;
}

.btn-back:hover {
  background-color: #ddd;
}

.btn-add {
  background-color: #4caf50;
  color: white;
}

.btn-add:hover {
  background-color: #45a049;
}

.calendar-header {
  margin-bottom: 20px;
}

.calendar-header h1 {
  margin: 0;
  color: #2c3e50;
}

.filters {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
}

.filter-group {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.filter-group label {
  margin-bottom: 5px;
  font-weight: 600;
}

.filter-group select,
.filter-group input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.filter-actions {
  display: flex;
  gap: 10px;
}

.btn-filter,
.btn-reset {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-filter {
  background-color: #3498db;
  color: white;
}

.btn-filter:hover {
  background-color: #2980b9;
}

.btn-reset {
  background-color: #f0f0f0;
  color: #333;
}

.btn-reset:hover {
  background-color: #ddd;
}

.loading {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.calendar-grid {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.calendar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.nav-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.month-year-selector {
  display: flex;
  gap: 5px;
}

.month-select,
.year-select {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
}

.month-select {
  min-width: 120px;
}

.year-select {
  width: 80px;
}

.btn-today {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #666;
}

.btn-today:hover {
  background-color: #f0f0f0;
  color: #333;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.weekday {
  padding: 10px;
  text-align: center;
  font-weight: 600;
  color: #666;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  min-height: 100px;
  padding: 5px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}

.calendar-day:hover {
  background-color: #f8f9fa;
}

.calendar-day.other-month {
  background-color: #f8f9fa;
  color: #999;
}

.calendar-day.today {
  background-color: #e3f2fd;
}

.day-number {
  font-size: 14px;
  margin-bottom: 5px;
}

.day-events {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.event-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3498db;
}

.event-dot.regular {
  background-color: #3498db;
}
.event-dot.closed {
  background-color: #e74c3c;
}
.event-dot.snow_day {
  background-color: #3498db;
}
.event-dot.early_dismissal {
  background-color: #f39c12;
}
.event-dot.late_start {
  background-color: #9b59b6;
}
.event-dot.sick_day {
  background-color: #e74c3c;
}
.event-dot.holiday {
  background-color: #2ecc71;
}
.event-dot.bell_schedule {
  background-color: #1abc9c;
}
.event-dot.other {
  background-color: #95a5a6;
}

.more-events {
  font-size: 12px;
  color: #666;
  margin-left: 2px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  color: #2c3e50;
}

.btn-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  font-size: 18px;
}

.event-details {
  margin-bottom: 20px;
}

.event-details h4 {
  margin: 0 0 10px 0;
  color: #2c3e50;
}

.event-type {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
}

.event-date {
  color: #666;
  margin-bottom: 10px;
}

.event-description {
  color: #666;
  font-size: 14px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.btn-edit,
.btn-delete,
.btn-add {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.btn-edit {
  background-color: #f39c12;
  color: white;
}

.btn-edit:hover {
  background-color: #e67e22;
}

.btn-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-delete:hover {
  background-color: #c0392b;
}

.btn-add {
  background-color: #4caf50;
  color: white;
}

.btn-add:hover {
  background-color: #45a049;
}

.warning {
  color: #e74c3c;
  font-weight: 600;
}

@media (max-width: 768px) {
  .filter-row {
    flex-direction: column;
    gap: 10px;
  }

  .filter-group {
    width: 100%;
  }

  .filter-actions {
    width: 100%;
    justify-content: space-between;
  }

  .calendar-day {
    min-height: 80px;
  }
}

/* Add new styles for the event form */
.event-form {
  padding: 20px 0;
}

.event-form .form-group {
  margin-bottom: 15px;
}

.event-form .form-row {
  display: flex;
  gap: 15px;
}

.event-form .form-row .form-group {
  flex: 1;
}

.event-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #2c3e50;
}

.event-form input[type="text"],
.event-form input[type="date"],
.event-form input[type="time"],
.event-form select,
.event-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.event-form textarea {
  resize: vertical;
  min-height: 80px;
}

.event-form small {
  display: block;
  margin-top: 5px;
  color: #666;
  font-size: 12px;
}

.event-form .checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-form .checkbox-group input[type="checkbox"] {
  margin: 0;
}

.event-form .checkbox-group label {
  margin: 0;
}

.btn-submit {
  background-color: #4caf50;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.btn-submit:hover {
  background-color: #45a049;
}

.btn-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .event-form .form-row {
    flex-direction: column;
    gap: 10px;
  }
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.btn-import {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}

.btn-import:hover {
  background-color: #1976d2;
}

.view-toggle {
  display: flex;
  gap: 5px;
  margin-right: 10px;
}

.btn-toggle {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s;
}

.btn-toggle:hover {
  background-color: #f0f0f0;
  color: #333;
}

.btn-toggle.active {
  background-color: #3498db;
  color: white;
  border-color: #3498db;
}

.events-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.list-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr 1fr;
  padding: 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
  color: #2c3e50;
}

.list-content {
  max-height: 600px;
  overflow-y: auto;
}

.list-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr 1fr;
  padding: 15px;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.list-item:hover {
  background-color: #f8f9fa;
}

.list-column {
  padding: 0 10px;
}

.event-type-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.event-type-badge.regular {
  background-color: #3498db;
}
.event-type-badge.closed {
  background-color: #e74c3c;
}
.event-type-badge.snow_day {
  background-color: #3498db;
}
.event-type-badge.early_dismissal {
  background-color: #f39c12;
}
.event-type-badge.late_start {
  background-color: #9b59b6;
}
.event-type-badge.sick_day {
  background-color: #e74c3c;
}
.event-type-badge.holiday {
  background-color: #2ecc71;
}
.event-type-badge.bell_schedule {
  background-color: #1abc9c;
}
.event-type-badge.other {
  background-color: #95a5a6;
}

.btn-icon {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.btn-icon:hover {
  color: #333;
}

.btn-icon:first-child {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .list-header,
  .list-item {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .list-column {
    padding: 5px 0;
  }

  .list-column.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>
