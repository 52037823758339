<template>
  <div class="view-calendar-container">
    <div class="header-actions">
      <button class="btn-back" @click="goBack">
        <font-awesome-icon icon="arrow-left" /> Back to Calendars
      </button>
      <div class="calendar-actions">
        <router-link :to="`/calendar/${calendarId}/events/add`" class="btn-add">
          <font-awesome-icon icon="plus" /> Add Event
        </router-link>
        <button class="btn-edit" @click="editCalendar">
          <font-awesome-icon icon="edit" /> Edit Calendar
        </button>
        <button class="btn-delete" @click="confirmDelete">
          <font-awesome-icon icon="trash" /> Delete Calendar
        </button>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <p>Loading calendar data...</p>
    </div>

    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-else class="calendar-content">
      <div class="calendar-header">
        <h1>{{ calendar.name }}</h1>
        <p class="calendar-dates">
          {{ formatDate(calendar.startDate) }} -
          {{ formatDate(calendar.endDate) }}
        </p>
      </div>

      <div class="calendar-details">
        <div class="detail-section">
          <h2>Calendar Information</h2>
          <div class="detail-row">
            <div class="detail-item">
              <h3>Type</h3>
              <p>{{ formatCalendarType(calendar.type) }}</p>
            </div>
            <div class="detail-item">
              <h3>Status</h3>
              <p>{{ formatStatus(calendar.status) }}</p>
            </div>
          </div>
          <div class="detail-item full-width" v-if="calendar.description">
            <h3>Description</h3>
            <p>{{ calendar.description }}</p>
          </div>
          <div
            class="detail-item full-width"
            v-if="calendar.type === 'district' && calendar.district"
          >
            <h3>Associated Schools</h3>
            <div class="schools-list">
              <div v-if="loadingSchools" class="loading-indicator">
                Loading schools...
              </div>
              <div v-else-if="districtSchools.length === 0" class="no-schools">
                No schools associated with this district.
              </div>
              <div v-else class="schools-grid">
                <router-link
                  v-for="school in districtSchools"
                  :key="school.id"
                  :to="`/organizations/${school.id}`"
                  class="school-card"
                >
                  <h4>{{ school.name }}</h4>
                  <div class="school-card-hover">
                    <div class="school-card-actions">
                      <router-link
                        :to="`/organizations/${school.id}/edit`"
                        class="action-button"
                        @click.stop
                      >
                        <font-awesome-icon icon="edit" />
                        <span>Edit</span>
                      </router-link>
                      <div class="action-button">
                        <font-awesome-icon icon="eye" />
                        <span>View</span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="detail-section">
          <h2>Default Schedule</h2>
          <div class="detail-row">
            <div class="detail-item">
              <h3>Start Time</h3>
              <p>{{ calendar.defaultStartTime || "Not set" }}</p>
            </div>
            <div class="detail-item">
              <h3>End Time</h3>
              <p>{{ calendar.defaultEndTime || "Not set" }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Calendar Events View -->
      <div class="events-section">
        <CalendarEventsView :calendarId="calendarId" />
      </div>

      <!-- Calendar Attachments -->
      <div class="attachments-section">
        <CalendarAttachments :calendarId="calendarId" />
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Confirm Delete</h3>
        <p>
          Are you sure you want to delete the calendar "{{ calendar.name }}"?
        </p>
        <p class="warning">This action cannot be undone.</p>

        <div class="modal-actions">
          <button class="btn-cancel" @click="showDeleteModal = false">
            Cancel
          </button>
          <button class="btn-confirm-delete" @click="deleteCalendar">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { API_URL } from "../config";
import CalendarAttachments from "../components/CalendarAttachments.vue";
import CalendarEventsView from "../components/CalendarEventsView.vue";

// Router and Route
const router = useRouter();
const route = useRoute();
const calendarId = Number(route.params.calendarId);

// State
const calendar = ref<any>({});
const loading = ref(true);
const loadingEvents = ref(false);
const error = ref("");
const showDeleteModal = ref(false);
const events = ref<any[]>([]);
const districtSchools = ref<any[]>([]);
const loadingSchools = ref(false);

// Format date
const formatDate = (dateString: string): string => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Invalid Date";

  // Adjust for timezone offset
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() + userTimezoneOffset);

  return adjustedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const formatCalendarType = (type: string): string => {
  if (!type) return "N/A";
  return type.charAt(0).toUpperCase() + type.slice(1);
};

const formatStatus = (status: string): string => {
  if (!status) return "N/A";
  return status.charAt(0).toUpperCase() + status.slice(1);
};

// Fetch district schools
const fetchDistrictSchools = async () => {
  if (calendar.value?.type !== "district" || !calendar.value?.district?.id) {
    console.log("Not a district calendar or no district ID:", calendar.value);
    return;
  }

  loadingSchools.value = true;
  try {
    console.log(
      "Fetching district schools for district ID:",
      calendar.value.district.id
    );
    // Get the district with its organizations
    const response = await axios.get(
      `${API_URL}/districts/${calendar.value.district.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("District API Response:", response.data);

    // Extract schools from the organizations relation
    districtSchools.value = response.data.organizations || [];
    console.log("District schools:", districtSchools.value);
  } catch (err: any) {
    console.error("Error fetching district schools:", err);
  } finally {
    loadingSchools.value = false;
  }
};

// Fetch calendar data
const fetchCalendar = async () => {
  console.log("fetchCalendar called");
  loading.value = true;
  try {
    console.log("Making API request to fetch calendar:", calendarId);
    const response = await axios.get(`${API_URL}/calendar/${calendarId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log("Calendar API Response:", response.data);

    calendar.value = response.data;
    console.log("Updated calendar.value:", calendar.value);

    // Fetch district schools if this is a district calendar
    if (calendar.value.type === "district") {
      console.log("Calendar is district type, fetching schools");
      await fetchDistrictSchools();
    }

    // Fetch events after getting calendar info
    console.log("Calling fetchEvents after getting calendar info");
    fetchEvents();
  } catch (err: any) {
    console.error("Error in fetchCalendar:", err);
    error.value = err.message || "Failed to fetch calendar";
    loading.value = false;
  }
};

// Fetch calendar events
const fetchEvents = async () => {
  console.log("fetchEvents called");
  loadingEvents.value = true;
  try {
    console.log("Making API request to fetch events for calendar:", calendarId);
    const response = await axios.get(
      `${API_URL}/calendar/${calendarId}/events`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("Events API Response:", response.data);

    events.value = response.data;
    loading.value = false;
  } catch (err: any) {
    console.error("Error in fetchEvents:", err);
    error.value = err.message || "Failed to fetch events";
  } finally {
    loadingEvents.value = false;
  }
};

// Navigation
const goBack = () => {
  // Check if we should return to a specific page
  const returnTo = route.query.returnTo as string;
  if (returnTo) {
    router.push(returnTo);
  } else {
    // Default: go back to calendar list
    router.push("/calendar");
  }
};

const editCalendar = () => {
  // Pass the returnTo parameter if it exists
  const returnTo = route.query.returnTo as string;
  if (returnTo) {
    router.push({
      path: `/calendar/${calendarId}/edit`,
      query: { returnTo },
    });
  } else {
    router.push(`/calendar/${calendarId}/edit`);
  }
};

const confirmDelete = () => {
  showDeleteModal.value = true;
};

const deleteCalendar = async () => {
  try {
    await axios.delete(`${API_URL}/calendar/${calendarId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    // Success message
    console.log("Calendar deleted successfully");

    // Redirect to calendar list
    router.push("/calendar");
  } catch (err: any) {
    console.error("Error deleting calendar:", err);
    error.value = err.message || "Failed to delete calendar";
  }
};

// Lifecycle hooks
onMounted(() => {
  console.log("ViewCalendarView component mounted");
  console.log("calendarId:", calendarId);
  fetchCalendar();
});
</script>

<style scoped>
.view-calendar-container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.loading {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-back,
.btn-edit,
.btn-delete {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}

.btn-back {
  background-color: #f0f0f0;
  color: #333;
}

.btn-back:hover {
  background-color: #ddd;
}

.btn-edit {
  background-color: #2ecc71;
  color: white;
}

.btn-edit:hover {
  background-color: #27ae60;
}

.btn-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-delete:hover {
  background-color: #c0392b;
}

.calendar-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.calendar-header h1 {
  margin: 0;
  color: #2c3e50;
}

.calendar-dates {
  margin: 0;
  font-size: 14px;
  color: #7f8c8d;
}

.calendar-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.detail-section {
  margin-bottom: 20px;
}

.detail-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.detail-item {
  flex: 1;
}

.detail-item.full-width {
  width: 100%;
}

.detail-item h3 {
  margin: 0 0 5px 0;
  font-size: 16px;
  color: #7f8c8d;
}

.detail-item p {
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
}

.status-active {
  color: #2ecc71;
  font-weight: 600;
}

.status-archived {
  color: #e74c3c;
  font-weight: 600;
}

.events-section {
  margin-top: 30px;
}

.attachments-section {
  margin-top: 30px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #2c3e50;
}

.modal-content p {
  margin-bottom: 20px;
  color: #7f8c8d;
}

.warning {
  color: #e74c3c;
  font-weight: 600;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn-cancel,
.btn-confirm-delete {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.btn-cancel {
  background-color: #f0f0f0;
  color: #333;
}

.btn-cancel:hover {
  background-color: #ddd;
}

.btn-confirm-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-confirm-delete:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .header-actions {
    flex-direction: column;
    gap: 10px;
  }

  .btn-back,
  .btn-edit,
  .btn-delete {
    width: 100%;
    justify-content: center;
  }
}

/* Add styles for schools list */
.schools-list {
  margin-top: 10px;
}

.schools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 10px;
}

.school-card {
  position: relative;
  background: #fff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
}

.school-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #007bff;
}

.school-card h4 {
  margin: 0;
  color: #2c3e50;
  font-size: 1rem;
  font-weight: 500;
}

.school-card-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 123, 255, 0.95);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.school-card:hover .school-card-hover {
  opacity: 1;
}

.school-card-actions {
  display: flex;
  gap: 1rem;
}

.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  text-decoration: none;
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.action-button svg {
  font-size: 1.5rem;
}

.action-button span {
  font-size: 0.9rem;
  font-weight: 500;
}

.loading-indicator {
  color: #666;
  font-style: italic;
}

.no-schools {
  color: #666;
  font-style: italic;
}
</style>
