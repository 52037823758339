export const formatTimeTo12Hour = (time: string | null | undefined): string => {
  if (!time) return "";

  try {
    // Handle both "HH:mm:ss" and "HH:mm" formats
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours);

    if (isNaN(hour)) return "";

    const period = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;

    return `${hour12}:${minutes} ${period}`;
  } catch (error) {
    console.error("Error formatting time:", error);
    return "";
  }
};

export const formatTimeTo24Hour = (time: string | null | undefined): string => {
  if (!time) return "";

  try {
    // Handle "HH:mm AM/PM" format
    const [timeStr, period] = time.split(" ");
    const [hours, minutes] = timeStr.split(":");
    let hour = parseInt(hours);

    if (isNaN(hour)) return "";

    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }

    return `${hour.toString().padStart(2, "0")}:${minutes}`;
  } catch (error) {
    console.error("Error formatting time:", error);
    return "";
  }
};
