<template>
  <div class="add-student-container">
    <nav class="breadcrumb">
      <router-link to="/">Home</router-link> /
      <router-link to="/students">Students</router-link> /
      <span>Add Student</span>
    </nav>

    <h2 class="title">Add New Student</h2>

    <!-- Progress Indicator -->
    <div class="steps-indicator">
      <div
        v-for="step in steps"
        :key="step.number"
        class="step"
        :class="{
          active: currentStep === step.number,
          completed: currentStep > step.number,
        }"
        @click="goToStep(step.number)"
      >
        <div class="step-number">{{ step.number }}</div>
        <div class="step-label">{{ step.label }}</div>
      </div>
    </div>

    <form @submit.prevent="submitForm" class="add-form">
      <!-- Step 1: Personal Information -->
      <div v-show="currentStep === 1" class="form-step">
        <div class="form-section">
          <h3>Personal Information</h3>
          <div class="form-grid">
            <div class="form-group">
              <label for="firstName">First Name</label>
              <input
                v-model="student.firstName"
                id="firstName"
                :class="{ error: errors.firstName }"
                @input="validateField('firstName')"
              />
              <span class="error-message" v-if="errors.firstName">{{
                errors.firstName
              }}</span>
            </div>
            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input
                v-model="student.lastName"
                id="lastName"
                :class="{ error: errors.lastName }"
                @input="validateField('lastName')"
              />
              <span class="error-message" v-if="errors.lastName">{{
                errors.lastName
              }}</span>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>Address Information</h3>
          <div class="form-grid">
            <div class="form-group full-width">
              <label for="addressLine1">Street Address</label>
              <input v-model="student.addressLine1" id="addressLine1" />
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input v-model="student.city" id="city" />
            </div>
            <div class="form-group">
              <label for="state">State</label>
              <select v-model="student.state" id="state">
                <option value="">Select State</option>
                <option v-for="state in states" :key="state" :value="state">
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="zip">ZIP Code</label>
              <input
                v-model="student.zip"
                id="zip"
                pattern="[0-9]*"
                maxlength="5"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Step 2: School Information -->
      <div v-show="currentStep === 2" class="form-step">
        <div class="form-section">
          <h3>School Selection</h3>
          <div class="school-search">
            <input
              v-model="schoolSearch"
              placeholder="Search schools by name or address..."
              class="search-input"
            />
            <div class="schools-list" v-if="filteredSchools.length">
              <div
                v-for="school in filteredSchools"
                :key="school.id"
                class="school-item"
                :class="{ selected: school.id === student.schoolId }"
                @click="selectSchool(school)"
              >
                <div class="school-info">
                  <h4>{{ school.name }}</h4>
                  <p>{{ school.address }}</p>
                </div>
                <div
                  class="school-select"
                  v-if="school.id === student.schoolId"
                >
                  <span class="checkmark">✓</span>
                </div>
              </div>
            </div>
            <p v-else class="no-results">
              No schools found matching your search
            </p>
          </div>
        </div>
      </div>

      <!-- Step 3: Needs & Comments -->
      <div v-show="currentStep === 3" class="form-step">
        <div class="form-section">
          <h3>Student Needs</h3>
          <div class="needs-container">
            <div class="needs-input">
              <input
                v-model="newNeed"
                @keydown.enter.prevent="addNeed"
                placeholder="Type a need and press Enter"
                class="need-input"
              />
              <button
                type="button"
                @click="addNeed"
                class="add-need-btn"
                :disabled="!newNeed.trim()"
              >
                Add
              </button>
            </div>
            <div class="common-needs">
              <h4>Common Needs:</h4>
              <div class="common-needs-list">
                <button
                  v-for="need in commonNeeds"
                  :key="need"
                  type="button"
                  class="common-need-btn"
                  :class="{ selected: needsArray.includes(need) }"
                  @click="toggleCommonNeed(need)"
                >
                  {{ need }}
                </button>
              </div>
            </div>
            <div class="selected-needs" v-if="needsArray.length">
              <h4>Selected Needs:</h4>
              <div class="needs-tags">
                <span
                  v-for="(need, index) in needsArray"
                  :key="index"
                  class="need-tag"
                >
                  {{ need }}
                  <button
                    type="button"
                    class="remove-need"
                    @click="removeNeed(index)"
                  >
                    ×
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3>Additional Comments</h3>
          <div class="form-group">
            <textarea
              v-model="student.comments"
              rows="4"
              placeholder="Add any additional notes or comments about the student..."
            ></textarea>
          </div>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="form-navigation">
        <button
          type="button"
          @click="previousStep"
          class="secondary-button"
          v-if="currentStep > 1"
        >
          <span class="button-icon">←</span> Previous
        </button>
        <button
          type="button"
          @click="nextStep"
          class="primary-button"
          v-if="currentStep < totalSteps"
        >
          Next <span class="button-icon">→</span>
        </button>
        <button
          type="submit"
          class="submit-button"
          v-if="currentStep === totalSteps"
        >
          Add Student
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config";

// Form state
const student = ref({
  firstName: "",
  lastName: "",
  addressLine1: "",
  city: "",
  state: "",
  zip: "",
  comments: "",
  needs: "",
  schoolId: "",
});

// Form validation
const errors = ref({
  firstName: "",
  lastName: "",
});

// Steps configuration
const steps = [
  { number: 1, label: "Personal Info" },
  { number: 2, label: "School" },
  { number: 3, label: "Needs & Comments" },
];

const currentStep = ref(1);
const totalSteps = steps.length;

// Schools handling
const schools = ref<any[]>([]);
const schoolSearch = ref("");

// Needs handling
const needsArray = ref<string[]>([]);
const newNeed = ref("");
const commonNeeds = [
  "Special Education",
  "ESL Support",
  "Speech Therapy",
  "Physical Therapy",
  "Occupational Therapy",
  "Behavioral Support",
  "Academic Support",
  "Transportation",
  "Assistive Technology",
  "Medical Support",
];

// US States
const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const router = useRouter();

// Computed properties
const filteredSchools = computed(() => {
  const searchTerm = schoolSearch.value.toLowerCase();
  return schools.value.filter(
    (school) =>
      school.name.toLowerCase().includes(searchTerm) ||
      school.address.toLowerCase().includes(searchTerm)
  );
});

// Methods
const validateField = (field: keyof typeof errors.value) => {
  errors.value[field] = "";
  if (field === "firstName" && !student.value.firstName.trim()) {
    errors.value.firstName = "First name is required";
  }
  if (field === "lastName" && !student.value.lastName.trim()) {
    errors.value.lastName = "Last name is required";
  }
};

const validateStep = () => {
  if (currentStep.value === 1) {
    validateField("firstName");
    validateField("lastName");
    return !errors.value.firstName && !errors.value.lastName;
  }
  return true;
};

const goToStep = (step: number) => {
  if (step < currentStep.value || validateStep()) {
    currentStep.value = step;
  }
};

const nextStep = () => {
  if (validateStep() && currentStep.value < totalSteps) {
    currentStep.value++;
  }
};

const previousStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};

const selectSchool = (school: any) => {
  student.value.schoolId = school.id;
};

const addNeed = () => {
  const trimmedNeed = newNeed.value.trim();
  if (trimmedNeed && !needsArray.value.includes(trimmedNeed)) {
    needsArray.value.push(trimmedNeed);
  }
  newNeed.value = "";
};

const toggleCommonNeed = (need: string) => {
  const index = needsArray.value.indexOf(need);
  if (index === -1) {
    needsArray.value.push(need);
  } else {
    needsArray.value.splice(index, 1);
  }
};

const removeNeed = (index: number) => {
  needsArray.value.splice(index, 1);
};

const fetchSchools = async () => {
  try {
    const response = await fetch(`${API_URL}/organizations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.ok) {
      schools.value = await response.json();
    }
  } catch (error) {
    console.error("Error fetching schools:", error);
  }
};

const submitForm = async () => {
  if (!validateStep()) return;

  try {
    student.value.needs = needsArray.value.join(", ");
    const response = await fetch(`${API_URL}/students`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(student.value),
    });

    if (response.ok) {
      router.push("/students");
    } else {
      const error = await response.json();
      alert(`Error: ${error.message}`);
    }
  } catch (error) {
    console.error("Error adding student:", error);
    alert("An error occurred while adding the student.");
  }
};

onMounted(() => {
  fetchSchools();
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb span {
  color: #6c757d;
}

.add-student-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.steps-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  width: 30%;
  text-align: center;
  cursor: pointer;
}

.step-number {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.step-label {
  display: block;
  font-size: 12px;
  color: #6c757d;
}

.step.active .step-number {
  color: #007bff;
}

.step.active .step-label {
  font-weight: bold;
}

.step.completed .step-number {
  color: #6c757d;
}

.step.completed .step-label {
  font-weight: normal;
}

.add-form {
  /* Add your styles here */
}

.form-step {
  /* Add your styles here */
}

.form-section {
  /* Add your styles here */
}

.form-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.form-group {
  width: calc(50% - 7.5px);
}

.form-group.full-width {
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.school-search {
  /* Add your styles here */
}

.search-input {
  /* Add your styles here */
}

.schools-list {
  /* Add your styles here */
}

.school-item {
  /* Add your styles here */
}

.school-info {
  /* Add your styles here */
}

.school-select {
  /* Add your styles here */
}

.checkmark {
  /* Add your styles here */
}

.no-results {
  /* Add your styles here */
}

.needs-container {
  /* Add your styles here */
}

.needs-input {
  /* Add your styles here */
}

.add-need-btn {
  /* Add your styles here */
}

.common-needs {
  /* Add your styles here */
}

.common-needs-list {
  /* Add your styles here */
}

.common-need-btn {
  /* Add your styles here */
}

.selected-needs {
  /* Add your styles here */
}

.needs-tags {
  /* Add your styles here */
}

.need-tag {
  /* Add your styles here */
}

.remove-need {
  /* Add your styles here */
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.secondary-button,
.primary-button,
.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.secondary-button:hover,
.primary-button:hover,
.submit-button:hover {
  opacity: 0.9;
}

.button-icon {
  margin-left: 5px;
}
</style>
