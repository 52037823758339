<template>
  <div class="calendar-events-container">
    <div class="calendar-header">
      <h2>Calendar Events</h2>
      <div class="calendar-actions">
        <button class="btn-add-event" @click="navigateToAddEvent">
          <font-awesome-icon icon="plus" /> Add Event
        </button>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <p>Loading events...</p>
    </div>

    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-else class="calendar-grid">
      <div class="calendar-nav">
        <button class="btn-nav" @click="previousMonth">
          <font-awesome-icon icon="chevron-left" />
        </button>
        <h3>{{ currentMonthYear }}</h3>
        <button class="btn-nav" @click="nextMonth">
          <font-awesome-icon icon="chevron-right" />
        </button>
      </div>

      <div class="calendar-weekdays">
        <div v-for="day in weekDays" :key="day" class="weekday">{{ day }}</div>
      </div>

      <div class="calendar-days">
        <div
          v-for="day in calendarDays"
          :key="day.date.toISOString()"
          class="calendar-day"
          :class="{
            'other-month': !day.isCurrentMonth,
            'has-events': day.events.length > 0,
            today: isToday(day.date),
          }"
          @click="handleDayClick(day)"
        >
          <div class="day-number">{{ day.dayNumber }}</div>
          <div v-if="day.events.length > 0" class="day-events">
            <div
              v-for="event in day.events.slice(0, 2)"
              :key="event.id"
              class="event-dot"
              :class="event.type"
            ></div>
            <div v-if="day.events.length > 2" class="more-events">
              +{{ day.events.length - 2 }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Event Modal -->
    <div v-if="showEventModal" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h3>{{ selectedDate ? "Add Event" : "Edit Event" }}</h3>
          <button class="btn-close" @click="closeEventModal">
            <font-awesome-icon icon="times" />
          </button>
        </div>

        <div v-if="selectedEvent" class="event-details">
          <h4>{{ selectedEvent.title }}</h4>
          <p class="event-type" :class="selectedEvent.type">
            {{ formatEventType(selectedEvent.type) }}
          </p>
          <p class="event-date">
            {{ formatDate(selectedEvent.startDate) }}
            <span
              v-if="
                selectedEvent.endDate &&
                selectedEvent.endDate !== selectedEvent.startDate
              "
            >
              - {{ formatDate(selectedEvent.endDate) }}
            </span>
          </p>
          <p v-if="selectedEvent.description" class="event-description">
            {{ selectedEvent.description }}
          </p>
        </div>

        <div class="modal-actions">
          <button v-if="selectedEvent" class="btn-edit" @click="editEvent">
            <font-awesome-icon icon="edit" /> Edit Event
          </button>
          <button
            v-if="selectedEvent"
            class="btn-delete"
            @click="confirmDelete"
          >
            <font-awesome-icon icon="trash" /> Delete Event
          </button>
          <button v-if="!selectedEvent" class="btn-add" @click="addEvent">
            <font-awesome-icon icon="plus" /> Add Event
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, defineProps } from "vue";
import { useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import { API_URL } from "../config";

const props = defineProps<{
  calendarId: number;
}>();

const router = useRouter();

// State
const currentDate = ref(new Date());
const events = ref<any[]>([]);
const loading = ref(true);
const error = ref("");
const showEventModal = ref(false);
const selectedDate = ref<Date | null>(null);
const selectedEvent = ref<any>(null);

// Computed
const currentMonthYear = computed(() => {
  return currentDate.value.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
});

const weekDays = computed(() => {
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
});

const calendarDays = computed(() => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const days = [];

  // Add days from previous month
  const firstDayWeekday = firstDay.getDay();
  for (let i = firstDayWeekday - 1; i >= 0; i--) {
    const date = new Date(year, month, -i);
    days.push({
      date,
      dayNumber: date.getDate(),
      isCurrentMonth: false,
      events: getEventsForDate(date),
    });
  }

  // Add days from current month
  for (let i = 1; i <= lastDay.getDate(); i++) {
    const date = new Date(year, month, i);
    days.push({
      date,
      dayNumber: i,
      isCurrentMonth: true,
      events: getEventsForDate(date),
    });
  }

  // Add days from next month
  const remainingDays = 42 - days.length; // 6 weeks * 7 days = 42
  for (let i = 1; i <= remainingDays; i++) {
    const date = new Date(year, month + 1, i);
    days.push({
      date,
      dayNumber: date.getDate(),
      isCurrentMonth: false,
      events: getEventsForDate(date),
    });
  }

  return days;
});

// Methods
const getEventsForDate = (date: Date) => {
  if (!events.value || !Array.isArray(events.value)) {
    console.warn("events.value is not an array, returning empty array");
    return [];
  }

  return events.value.filter((event) => {
    const eventStartDate = new Date(event.startDate);
    const eventEndDate = event.endDate
      ? new Date(event.endDate)
      : eventStartDate;
    return date >= eventStartDate && date <= eventEndDate;
  });
};

const isToday = (date: Date) => {
  const today = new Date();
  return date.toDateString() === today.toDateString();
};

const previousMonth = () => {
  currentDate.value = new Date(
    currentDate.value.getFullYear(),
    currentDate.value.getMonth() - 1
  );
};

const nextMonth = () => {
  currentDate.value = new Date(
    currentDate.value.getFullYear(),
    currentDate.value.getMonth() + 1
  );
};

const handleDayClick = (day: any) => {
  selectedDate.value = day.date;
  selectedEvent.value = day.events[0] || null;
  showEventModal.value = true;
};

const closeEventModal = () => {
  showEventModal.value = false;
  selectedDate.value = null;
  selectedEvent.value = null;
};

const navigateToAddEvent = () => {
  if (selectedDate.value) {
    router.push({
      path: `/calendar/${props.calendarId}/events/add`,
      query: { date: selectedDate.value.toISOString() },
    });
  }
};

const editEvent = () => {
  if (selectedEvent.value) {
    router.push(
      `/calendar/${props.calendarId}/events/${selectedEvent.value.id}/edit`
    );
  }
};

const confirmDelete = () => {
  if (selectedEvent.value) {
    deleteEvent(selectedEvent.value.id);
  }
};

const deleteEvent = async (eventId: number) => {
  try {
    await axios.delete(
      `${API_URL}/calendar/${props.calendarId}/events/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    // Refresh events
    fetchEvents();
    closeEventModal();
  } catch (err: any) {
    error.value = err.message || "Failed to delete event";
    console.error("Error deleting event:", err);
  }
};

const fetchEvents = async () => {
  loading.value = true;
  error.value = ""; // Reset error state
  try {
    console.log("Fetching events for calendar:", props.calendarId);
    const response = await axios.get(
      `${API_URL}/calendar/${props.calendarId}/events`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("Events API Response:", response.data);

    // Ensure we're setting an array
    events.value = Array.isArray(response.data) ? response.data : [];
    console.log("Updated events.value:", events.value);
  } catch (err: any) {
    console.error("Error fetching events:", err);
    error.value = err.message || "Failed to fetch events";
    events.value = []; // Reset to empty array on error
  } finally {
    loading.value = false;
  }
};

// Format functions
const formatDate = (dateString: string): string => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Invalid Date";

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const formatEventType = (type: string): string => {
  if (!type) return "";

  return type
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const addEvent = () => {
  if (selectedDate.value) {
    router.push({
      path: `/calendar/${props.calendarId}/events/add`,
      query: { date: selectedDate.value.toISOString() },
    });
  }
};

// Lifecycle hooks
onMounted(() => {
  fetchEvents();
});
</script>

<style scoped>
.calendar-events-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-header h2 {
  margin: 0;
  color: #2c3e50;
}

.calendar-actions {
  display: flex;
  gap: 10px;
}

.btn-add-event {
  padding: 8px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.btn-add-event:hover {
  background-color: #45a049;
}

.loading {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.calendar-grid {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.calendar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.calendar-nav h3 {
  margin: 0;
  color: #2c3e50;
}

.btn-nav {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  color: #666;
}

.btn-nav:hover {
  color: #333;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.weekday {
  padding: 10px;
  text-align: center;
  font-weight: 600;
  color: #666;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  min-height: 100px;
  padding: 5px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}

.calendar-day:hover {
  background-color: #f8f9fa;
}

.calendar-day.other-month {
  background-color: #f8f9fa;
  color: #999;
}

.calendar-day.today {
  background-color: #e3f2fd;
}

.day-number {
  font-size: 14px;
  margin-bottom: 5px;
}

.day-events {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.event-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3498db;
}

.event-dot.regular {
  background-color: #3498db;
}
.event-dot.closed {
  background-color: #e74c3c;
  border-radius: 0;
  transform: rotate(45deg);
}
.event-dot.snow_day {
  background-color: #3498db;
}
.event-dot.early_dismissal {
  background-color: #f39c12;
}
.event-dot.late_start {
  background-color: #9b59b6;
}
.event-dot.sick_day {
  background-color: #e74c3c;
}
.event-dot.holiday {
  background-color: #2ecc71;
}
.event-dot.bell_schedule {
  background-color: #1abc9c;
}
.event-dot.other {
  background-color: #95a5a6;
}

.more-events {
  font-size: 12px;
  color: #666;
  margin-left: 2px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  color: #2c3e50;
}

.btn-close {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  font-size: 18px;
}

.event-details {
  margin-bottom: 20px;
}

.event-details h4 {
  margin: 0 0 10px 0;
  color: #2c3e50;
}

.event-type {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
}

.event-date {
  color: #666;
  margin-bottom: 10px;
}

.event-description {
  color: #666;
  font-size: 14px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.btn-edit,
.btn-delete,
.btn-add {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.btn-edit {
  background-color: #f39c12;
  color: white;
}

.btn-edit:hover {
  background-color: #e67e22;
}

.btn-delete {
  background-color: #e74c3c;
  color: white;
}

.btn-delete:hover {
  background-color: #c0392b;
}

.btn-add {
  background-color: #4caf50;
  color: white;
}

.btn-add:hover {
  background-color: #45a049;
}
</style>
