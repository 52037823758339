<template>
  <div :class="{ 'app-container': true, 'with-sidebar': isAuthenticated }">
    <NavigationMenu v-if="isAuthenticated" />
    <main>
      <router-view></router-view>
      <footer class="app-footer">
        <div class="version-info">
          <span>Version {{ version }}</span>
          <span class="build-info">Build: {{ buildDate }}</span>
          <button class="update-button" @click="checkForUpdates">
            <font-awesome-icon icon="sync" /> Check for Updates
          </button>
        </div>
      </footer>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import NavigationMenu from "./components/NavigationMenu.vue";
import packageJson from "../package.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSync } from "@fortawesome/free-solid-svg-icons";

library.add(faSync);

export default defineComponent({
  components: {
    NavigationMenu,
  },
  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const version = computed(() => packageJson.version);
    const buildDate = computed(() => {
      const date = new Date();
      return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    });

    const checkForUpdates = async () => {
      try {
        const response = await fetch("/version.json");
        const data = await response.json();
        const currentVersion = localStorage.getItem("app_version");

        if (currentVersion !== data.version) {
          // New version available
          localStorage.setItem("app_version", data.version);
          // Show update notification
          if (
            confirm(
              "A new version of the application is available. Would you like to refresh now?"
            )
          ) {
            // Clear all caches and reload
            if ("caches" in window) {
              const keys = await caches.keys();
              await Promise.all(keys.map((key) => caches.delete(key)));
            }
            window.location.reload();
          }
        } else {
          alert("You are running the latest version of the application.");
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
        alert("Failed to check for updates. Please try again later.");
      }
    };

    return { isAuthenticated, version, buildDate, checkForUpdates };
  },
});
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.with-sidebar main {
  margin-left: 250px;
  width: calc(100% - 250px);
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

main {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
}

.app-footer {
  margin-top: auto;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: #6c757d;
}

.version-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.build-info {
  font-size: 0.75rem;
  color: #adb5bd;
}

.update-button {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.75rem;
  color: #6c757d;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
}

.update-button:hover {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}

.update-button svg {
  font-size: 0.7rem;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .with-sidebar main {
    margin-left: 0;
    width: 100%;
  }

  .side-nav {
    width: 100%;
    position: relative;
    box-shadow: none;
  }

  .app-footer {
    flex-direction: column;
    gap: 5px;
    text-align: center;
  }

  .version-info {
    width: 100%;
  }
}

/* Add this to fix Leaflet marker icons */
.leaflet-marker-icon {
  background: none;
  border: none;
}

/* Optional: Add this if you want to use default Leaflet styles */
@import "leaflet/dist/leaflet.css";
</style>
